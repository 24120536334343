import Badge from './Badge';

export default {
  id: 'radial_4',
  title: 'Radial_4',
  colors: 3,
  animated: true,
  premium: true,
  BadgeComponentStateless: Badge
};

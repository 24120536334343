import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_1 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_1__TopCircle"
          d="M 47 200 A 50 50 0 0 1 353 200"
        />

        <path
          id="RadialBadge_1__BottomCircle"
          d="M 47 200 A 50 50 0 0 0 353 200"
        />
      </defs>

      {/* ------------------------------------------ */}

      <g className="CIRCLE">
        <circle cx="200" cy="200" r="200" className="Badge__ThirdFillColor" />
      </g>

      {/* ------------------------------------------ */}

      <g className="SHAPE">
        <path
          d="M200,388.42C96.11,388.42,11.58,303.89,11.58,200S96.11,11.58,200,11.58,388.42,96.11,388.42,200,303.89,388.42,200,388.42Zm0-371.88C98.84,16.54,16.54,98.84,16.54,200S98.84,383.46,200,383.46,383.46,301.16,383.46,200,301.16,16.54,200,16.54Z"
          className="Badge__FirstFillColor"
        />
        <path
          d="M200,325.2A125.2,125.2,0,1,1,325.2,200,125.35,125.35,0,0,1,200,325.2Zm0-245.44A120.24,120.24,0,1,0,320.24,200,120.38,120.38,0,0,0,200,79.76Z"
          className="Badge__FirstFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="STARS">
        <polygon
          points="48.81 181.35 45.38 175.04 42.06 181.4 35.01 182.72 40.03 187.85 39.11 194.96 45.53 191.78 52.01 194.85 50.97 187.75 55.89 182.53 48.81 181.35"
          className="Badge__SecondFillColor"
        />
        <polygon
          points="361.74 182.53 354.67 181.35 351.24 175.04 347.91 181.4 340.86 182.72 345.88 187.85 344.96 194.96 351.38 191.78 357.87 194.85 356.81 187.75 361.74 182.53"
          className="Badge__SecondFillColor"
        />
        <polygon
          points="216.94 116 205.46 114.07 199.89 103.83 194.5 114.16 183.06 116.31 191.21 124.63 189.71 136.16 200.13 131 210.66 135.98 208.94 124.46 216.94 116"
          className="Badge__FirstFillColor"
        />
        <polygon
          points="250.59 127.5 243.41 126.29 239.93 119.89 236.56 126.35 229.41 127.69 234.5 132.89 233.57 140.11 240.08 136.87 246.66 139.99 245.59 132.79 250.59 127.5"
          className="Badge__FirstFillColor"
        />
        <polygon
          points="170.59 127.5 163.41 126.29 159.93 119.89 156.56 126.35 149.41 127.69 154.5 132.89 153.57 140.11 160.08 136.87 166.66 139.99 165.59 132.79 170.59 127.5"
          className="Badge__FirstFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="TREES">
        <path
          d="M176.3,310.08a102.33,102.33,0,0,0,11.49-16h-5.48a83.23,83.23,0,0,0,9.75-13.95h-5.93c5.79-6.36,11.1-17.07,13.89-23.21v0c2.78,6.14,8.1,16.85,13.89,23.21H208a82.08,82.08,0,0,0,9.75,13.95h-5.49a103.64,103.64,0,0,0,11.49,16h-6.46c2.32,3.8,4.76,7.51,7.28,11q-11.87-.46-23.73-.47-12.69,0-25.39.53c2.53-3.5,5-7.23,7.32-11.05Z"
          className="Badge__FirstFillColor"
        />
        <path
          d="M149.82,309.88A52.87,52.87,0,0,0,156,301h-3.78c3.69-4.06,7.08-10.88,8.85-14.79v0h0v0c1.77,3.91,5.16,10.73,8.85,14.79h-3.78a52.33,52.33,0,0,0,6.22,8.89h-3.5a66.64,66.64,0,0,0,7.32,10.21Z"
          className="Badge__FirstFillColor"
        />
        <path
          d="M225.82,320.09a66,66,0,0,0,7.32-10.21h-3.5a52.33,52.33,0,0,0,6.22-8.89h-3.78c3.69-4.06,7.08-10.88,8.85-14.79v0h0v0c1.77,3.91,5.16,10.73,8.85,14.79H246a52.87,52.87,0,0,0,6.21,8.89Z"
          className="Badge__FirstFillColor"
        />

      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x="50%"
          y="45%"
          textAnchor="middle"
          dy=".7ex"
          fontFamily="Lobster"
          fontSize="6em"
          letterSpacing="0em"
          transform="rotate(-10) translate(-40,45)"
          className="Badge__SecondFillColor"
        >
          {line2}
        </text>

        <text
          x=""
          y=""
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Abel"
          fontSize="2.3em"
          letterSpacing="0.07em"
          className="Badge__FirstFillColor"
        >
          <textPath xlinkHref="#RadialBadge_1__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".79ex"
          textAnchor="middle"
          fontFamily="Abel"
          fontSize="2.3em"
          letterSpacing="0.07em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_1__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

      {/* ------------------------------------------ */}

    </svg>
  );
}

export default Badge;

import React, { Component } from 'react';
import circulate from 'array-loop';
import badges from './shared/badges/index.js';

const {
  simple,
  radial_1,
  radial_2,
  radial_3,
  radial_4,
  radial_5,
  radial_6,
  burger,
  radial_7,
  radial_8,
  blank_2,
  radial_9,
  radial_10,
  radial_11,
  blank_1,
  radial_12,
  radial_13,
  radial_14
} = badges;

const badgesList = [
  simple,
  radial_1,
  radial_2,
  radial_3,
  radial_4,
  radial_5,
  radial_6,
  burger,
  radial_7,
  radial_8,
  radial_9,
  radial_10,
  radial_11,
  radial_12,
  radial_13,
  radial_14,
  blank_1,
  blank_2
];

const SIGN_STATES = [
  {
    line1: 'Sorry, we\'re',
    line2: 'Closed',
    line3: 'Opens',
    line4: '8am'
  },
  {
    line1: 'Yes, we\'re',
    line2: 'Open',
    line3: 'Closes',
    line4: '18pm'
  },
  {
    line1: 'Sorry, we\'re',
    line2: 'Closed',
    line3: 'Opens tomorrow',
    line4: '2am'
  },
  {
    line1: 'Yes, we\'re',
    line2: 'Open',
    line3: 'Closes',
    line4: '3am'
  },
  {
    line1: 'Sorry, we\'re',
    line2: 'Closed',
    line3: 'Opens',
    line4: '12:35am'
  },
  {
    line1: 'Yes, we\'re',
    line2: 'Open',
    line3: 'Closes tomorrow',
    line4: '2am'
  },
  {
    line1: 'Yes, we\'re',
    line2: 'Open',
    line3: '24/7',
    line4: ''
  }
];

function BadgesGrid() {
  const nextSignState = circulate(SIGN_STATES);

  const badgesHTML = badgesList.map(({ BadgeComponentStateless, id }, idx) => {
    return (
      <div key={id} className="column">
        <div className="inner">
          <BadgeComponentStateless className="Badge--animated" {...nextSignState()} />
        </div>
      </div>
    );
  });

  return (
    <div className="columns w25">
      {badgesHTML}
    </div>
  )
}

class App extends Component {
  render() {
    return (
        <div className="badges">
          <BadgesGrid />
        </div>
    );
  }
}

export default App;

import Badge from './Badge';

export default {
  id: 'radial_9',
  title: 'Radial_9',
  colors: 3,
  animated: true,
  premium: true,
  BadgeComponentStateless: Badge
};

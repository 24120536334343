import simple from './simple/index';
import radial_1 from './radial_1/index';
import radial_2 from './radial_2/index';
import radial_3 from './radial_3/index';
import radial_4 from './radial_4/index';
import radial_5 from './radial_5/index';
import radial_6 from './radial_6/index';
import radial_7 from './radial_7/index';
import radial_8 from './radial_8/index';
import radial_9 from './radial_9/index';
import radial_10 from './radial_10/index';
import radial_11 from './radial_11/index';
import radial_12 from './radial_12/index';
import radial_13 from './radial_13/index';
import radial_14 from './radial_14/index';
import burger from './burger/index';
import blank_1 from './blank_1/index';
import blank_2 from './blank_2/index';

export const badgesList = [
  simple,
  radial_1,
  radial_2,
  radial_3,
  radial_4,
  radial_5,
  radial_6,
  burger,
  radial_7,
  radial_8,
  blank_2,
  radial_9,
  radial_10,
  radial_11,
  blank_1,
  radial_12,
  radial_13,
  radial_14
];

export default {
  simple,
  radial_1,
  radial_2,
  radial_3,
  radial_4,
  radial_5,
  radial_6,
  burger,
  radial_7,
  radial_8,
  blank_2,
  radial_9,
  radial_10,
  radial_11,
  blank_1,
  radial_12,
  radial_13,
  radial_14
}

import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_6 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_6__TopCircle"
          d="M 60 200 A 50 50 0 0 1 340 200"
        />

        <path
          id="RadialBadge_6__BottomCircle"
          d="M 60 200 A 50 50 0 0 0 340 200"
        />
      </defs>

      {/* ------------------------------------------ */}

      <g>
        <rect width="400" height="400" opacity="0" />
        <g>
          <path
            d="M359.59,77.52l-15-14.75V31.52H328.07L199.39.88,70.71,31.52H55.63V62.77l-15,14.75h-6v245h6.31l14.69,14.57v31.43H72.2l128.69,30.81,128.67-30.81h15.07V337.09l14.7-14.57h6.3v-245Zm-1.4,238.62h-4.11l-16.37,16.23V361H324.43L200,391.06l-.35-.08L75.59,361h-13V332.37L46.19,316.14H42.07V83.91h3.87L62.56,67.52V39H74.41L199.52,9.25l.35.08L324.62,39h13.09V67.52l16.62,16.39h3.86Z"
            className="Badge__FirstFillColor"
            fillRule="evenodd"
          />
          <polygon
            points="334.71 68.77 334.71 42.04 324.09 42 199.52 12.33 74.76 42.04 65.56 42.04 65.56 68.77 47.17 86.91 45.07 86.91 45.07 313.14 47.42 313.14 65.56 331.12 65.56 358.01 76.12 358.05 200.01 387.98 324.08 358.01 334.71 358.01 334.71 331.12 352.85 313.14 355.19 313.14 355.19 86.91 353.1 86.91 334.71 68.77"
            className="Badge__FirstFillColor"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            d="M197.72,270.44l-16.64,9.79a5.31,5.31,0,0,0-4.37-2.11,7.82,7.82,0,0,0-3.07.61,7.19,7.19,0,0,0-3.54,3.09,4.64,4.64,0,0,0-.34,4.11,5.72,5.72,0,0,1-4.32.87,3.86,3.86,0,0,1-2.23-1.76l-.49.25-.5.25a5,5,0,0,0,2.9,2.32,5.1,5.1,0,0,0,1.3.19,7.82,7.82,0,0,0,3.91-1.16,5.44,5.44,0,0,0,4.23,1.92,7.89,7.89,0,0,0,3.07-.61,7.27,7.27,0,0,0,3.54-3.08,4.75,4.75,0,0,0,.47-3.75L194.51,276l4.44-.21,2.54-2.07,3.13,2.72,4.43.35,12.9,5.87a4.72,4.72,0,0,0,.3,3.9,7.19,7.19,0,0,0,3.44,3.19,7.67,7.67,0,0,0,3,.71,5.12,5.12,0,0,0,4.9-2.71,4.67,4.67,0,0,0-.21-4.14,7.19,7.19,0,0,0-3.44-3.19,7.81,7.81,0,0,0-3-.71,5.4,5.4,0,0,0-4.38,1.89v0l-17.31-10.93L225.63,254l-24.18,14.21-22.81-14.4,19.08,16.59Zm25.52,15.62a3.58,3.58,0,0,1-.19-3.17,4,4,0,0,1,3.87-2.07,6.72,6.72,0,0,1,2.6.61,6.15,6.15,0,0,1,2.92,2.69,3.57,3.57,0,0,1,.18,3.17,4,4,0,0,1-3.87,2.06,6.58,6.58,0,0,1-2.6-.61,6.1,6.1,0,0,1-2.91-2.68ZM201.43,272a1.09,1.09,0,0,1-1.06-1.1,1.07,1.07,0,0,1,1.09-1.06,1.08,1.08,0,1,1,0,2.16ZM180.2,284.58a6.1,6.1,0,0,1-3,2.59,6.6,6.6,0,0,1-2.62.53A4.2,4.2,0,0,1,171,286a2.23,2.23,0,0,1-.26-.48,3.58,3.58,0,0,1,.29-3.16,6,6,0,0,1,3-2.59,6.53,6.53,0,0,1,2.62-.53,4,4,0,0,1,3.79,2.19.42.42,0,0,1,0,.1l-.1.36.2-.09A3.71,3.71,0,0,1,180.2,284.58Z"
            className="Badge__SecondFillColor"
            fillRule="evenodd"
          />
          <path
            d="M212.2,126.75c-1.53-1.16-4.31-3.09-6.22-2.56,0,0-3.89.2-6,2.94-2.09-2.74-6-2.94-6-2.94-1.91-.53-4.69,1.4-6.22,2.56a53.77,53.77,0,0,1-7,4.7c-3.61,2.12-8.84,1.6-12.72-.06-2.52-1.08-4.43-2.87-4.34-6.26a5.36,5.36,0,0,1,5.31-5.47c2.71,0,7.29,3,5.67,6.69-1.42,3.26-4.89.9-4.89.9.1,1,1.3,1.6,2,1.76,1.89.43,4.37-1.77,4-4.58a6.78,6.78,0,0,0-3.76-5.14,7.2,7.2,0,0,0-9.75,1.83c-.86,1.19-4.38,7.88,2.43,15.43s17.67,7.9,20.78,7.9c1.88,0,10.81.58,14.45-7.55,3.64,8.13,12.57,7.55,14.45,7.55,3.11,0,14-.34,20.78-7.9s3.29-14.24,2.43-15.43a7.2,7.2,0,0,0-9.75-1.83,6.81,6.81,0,0,0-3.77,5.14c-.35,2.81,2.12,5,4,4.58.72-.16,1.92-.75,2-1.76,0,0-3.47,2.36-4.89-.9-1.62-3.69,3-6.69,5.67-6.69a5.36,5.36,0,0,1,5.31,5.47c.09,3.39-1.82,5.18-4.35,6.26-3.87,1.66-9.1,2.18-12.72.06A54.29,54.29,0,0,1,212.2,126.75Z"
            className="Badge__SecondFillColor"
            fillRule="evenodd"
          />
        </g>
      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x="50%"
          y="45%"
          textAnchor="middle"
          dy=".7ex"
          fontFamily="Lobster"
          fontSize="6em"
          letterSpacing="0em"
          transform="rotate(-10) translate(-40,45)"
          className="Badge__SecondFillColor"
        >
          {line2}
        </text>

        <text
          x=""
          y=""
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Merriweather"
          fontSize="1.2em"
          letterSpacing="0.1em"
          className="Badge__ThirdFillColor"
        >
          <textPath xlinkHref="#RadialBadge_6__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".79ex"
          textAnchor="middle"
          fontFamily="Abel"
          fontSize="1.4em"
          letterSpacing="0.1em"
          className="Badge__ThirdFillColor"
        >
          <textPath xlinkHref="#RadialBadge_6__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

    </svg>
  );
}

export default Badge;

import Badge from './Badge';

export default {
  id: 'radial_13',
  title: 'Radial_13',
  colors: 3,
  animated: true,
  premium: true,
  BadgeComponentStateless: Badge
};

import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_7 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_7__TopCircle"
          d="M 70 200 A 50 50 0 0 1 330 200"
        />

        <path
          id="RadialBadge_7__BottomCircle"
          d="M 80 205 A 50 50 0 0 0 320 205"
        />
      </defs>

      {/* ------------------------------------------ */}

      <g>
        <rect width="400" height="400" opacity="0" />
        <g>
          <path
            d="M176,10.2,60.43,56.88C50.22,61,40.56,75.31,40.56,86.33V313.67c0,11,9.66,25.32,19.87,29.45L176,389.79c7.19,2.9,16.32,3.83,24,3.83s16.82-.93,24-3.83l115.58-46.67c10.2-4.12,19.87-18.44,19.87-29.45V86.33c0-11-9.67-25.33-19.87-29.45L224,10.2c-7.17-2.89-16.32-3.82-24-3.82S183.17,7.3,176,10.2Z"
            className="Badge__SecondFillColor"
            fillRule="evenodd"
          />
          <path
            d="M169.54,5.15,63.67,47.91c-17.85,7.2-32.36,28.71-32.36,47.94v208.3c0,19.23,14.51,40.74,32.36,47.94l105.87,42.76c8.23,3.32,19,5.15,30.46,5.15s22.25-1.83,30.46-5.15l105.89-42.76c17.82-7.21,32.34-28.71,32.34-47.94V95.85c0-19.23-14.52-40.74-32.34-47.94L230.46,5.15C222.25,1.83,211.41,0,200,0s-22.23,1.83-30.46,5.15ZM46.37,245.86v-150c0-13.18,10.7-29.06,22.93-34L175.17,19.1C181.66,16.48,190.47,15,200,15s18.36,1.45,24.83,4.06L330.71,61.86c12.21,4.93,22.93,20.81,22.93,34v208.3c0,13.17-10.72,29.06-22.93,34L224.83,380.9c-6.47,2.61-15.3,4.06-24.83,4.06s-18.35-1.45-24.83-4.06L69.3,338.13c-12.23-4.93-22.93-20.81-22.93-34Z"
            className="Badge__FirstFillColor"
            fillRule="evenodd"
          />
          <path
            d="M178,26.11,72.12,68.87c-9.35,3.78-18.2,16.89-18.2,27v208.3c0,10.09,8.85,23.2,18.2,27L178,373.89c6.59,2.65,15,3.51,22,3.51s15.41-.86,22-3.51l105.89-42.77c9.35-3.77,18.21-16.89,18.21-27V95.85c0-10.08-8.86-23.2-18.21-27L222,26.11c-6.57-2.65-14.95-3.51-22-3.51S184.58,23.45,178,26.11Z"
            className="Badge__FirstFillColor"
            fillRule="evenodd"
          />
        </g>

        <g id="_Gruppe_" data-name="<Gruppe>" opacity="0.2" className="STRIPES">
          <path
            id="_Pfad_"
            data-name="<Pfad>"
            d="M200,120.93a1,1,0,0,0-1,1V181.1a1,1,0,0,0,1.92,0V121.89A1,1,0,0,0,200,120.93Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_2"
            data-name="<Pfad>"
            d="M183.15,133.39a1,1,0,0,0-1.86.5l14.12,52.7a1,1,0,0,0,.93.71l.25,0a1,1,0,0,0,.68-1.18Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_3"
            data-name="<Pfad>"
            d="M186.92,179.27a1,1,0,0,0,.83.48,1,1,0,0,0,.48-.13,1,1,0,0,0,.35-1.31l-26.81-46.44a1,1,0,0,0-1.66,1Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_4"
            data-name="<Pfad>"
            d="M189.32,190.68a1,1,0,0,0,.68.28.94.94,0,0,0,.68-.28,1,1,0,0,0,0-1.36l-38.51-38.5a1,1,0,0,0-1.35,1.35Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_5"
            data-name="<Pfad>"
            d="M131.87,161.77l48.25,27.86a1,1,0,0,0,.47.13,1,1,0,0,0,.84-.48,1,1,0,0,0-.36-1.31l-48.24-27.86a1,1,0,0,0-1,1.66Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_6"
            data-name="<Pfad>"
            d="M132.11,182.8l54,14.47.25,0a1,1,0,0,0,.25-1.89L132.6,181a1,1,0,1,0-.49,1.85Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_7"
            data-name="<Pfad>"
            d="M182.06,200a1,1,0,0,0-1-1H121.89a1,1,0,1,0,0,1.92H181.1A1,1,0,0,0,182.06,200Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_8"
            data-name="<Pfad>"
            d="M186.09,202.73l-52.7,14.12a1,1,0,0,0-.67,1.18,1,1,0,0,0,.92.71,1.07,1.07,0,0,0,.25,0l52.7-14.12a1,1,0,0,0-.5-1.86Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_9"
            data-name="<Pfad>"
            d="M179.62,211.77a1,1,0,0,0-1.31-.35l-46.44,26.81a1,1,0,0,0,.48,1.78,1,1,0,0,0,.48-.12l46.44-26.81A1,1,0,0,0,179.62,211.77Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_10"
            data-name="<Pfad>"
            d="M190.68,209.32a1,1,0,0,0-1.36,0l-38.5,38.51a1,1,0,0,0,.68,1.63.92.92,0,0,0,.67-.28l38.51-38.5A1,1,0,0,0,190.68,209.32Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_11"
            data-name="<Pfad>"
            d="M189.28,218.57a1,1,0,0,0-1.31.36l-27.86,48.24a1,1,0,0,0,.36,1.31,1,1,0,0,0,.47.12,1,1,0,0,0,.83-.47l27.86-48.25A1,1,0,0,0,189.28,218.57Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_12"
            data-name="<Pfad>"
            d="M196.59,212.73a1,1,0,0,0-1.18.68L181,267.4a.94.94,0,0,0,.68,1.17,1,1,0,0,0,.24,0,1,1,0,0,0,.93-.72l14.47-54A1,1,0,0,0,196.59,212.73Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_13"
            data-name="<Pfad>"
            d="M200,217.94a1,1,0,0,0-1,1v59.21a1,1,0,0,0,1.92,0V218.9A1,1,0,0,0,200,217.94Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_14"
            data-name="<Pfad>"
            d="M204.59,213.41a1,1,0,1,0-1.86.5l14.12,52.7a1,1,0,0,0,.93.71.73.73,0,0,0,.25,0,1,1,0,0,0,.68-1.17Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_15"
            data-name="<Pfad>"
            d="M213.08,220.73a1,1,0,0,0-1.31-.35,1,1,0,0,0-.35,1.31l26.81,46.44a1,1,0,0,0,.83.47,1.07,1.07,0,0,0,.48-.12,1,1,0,0,0,.35-1.31Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_16"
            data-name="<Pfad>"
            d="M210.68,209.32a1,1,0,0,0-1.36,1.36l38.51,38.5a.92.92,0,0,0,.67.28,1,1,0,0,0,.68-1.63Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_17"
            data-name="<Pfad>"
            d="M268.13,238.23l-48.25-27.86a1,1,0,1,0-.95,1.66l48.24,27.86a1,1,0,0,0,.48.12,1,1,0,0,0,.48-1.78Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_18"
            data-name="<Pfad>"
            d="M267.89,217.2l-54-14.47a1,1,0,1,0-.5,1.86l54,14.46a1.07,1.07,0,0,0,.25,0,1,1,0,0,0,.24-1.88Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_19"
            data-name="<Pfad>"
            d="M278.11,199H218.9a1,1,0,0,0,0,1.92h59.21a1,1,0,1,0,0-1.92Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_20"
            data-name="<Pfad>"
            d="M266.11,181.29l-52.7,14.12a1,1,0,0,0,.25,1.89,1.07,1.07,0,0,0,.25,0l52.7-14.12a1,1,0,0,0-.5-1.86Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_21"
            data-name="<Pfad>"
            d="M220.38,188.23a1,1,0,0,0,.83.48.91.91,0,0,0,.48-.13l46.44-26.81a1,1,0,0,0-1-1.66l-46.44,26.81A1,1,0,0,0,220.38,188.23Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_22"
            data-name="<Pfad>"
            d="M209.32,190.68a1,1,0,0,0,1.36,0l38.5-38.51a1,1,0,0,0-1.35-1.35l-38.51,38.5A1,1,0,0,0,209.32,190.68Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_23"
            data-name="<Pfad>"
            d="M210.72,181.43a1.07,1.07,0,0,0,.48.12.94.94,0,0,0,.83-.48l27.86-48.24a1,1,0,0,0-1.66-1l-27.86,48.25A1,1,0,0,0,210.72,181.43Z"
            className="Badge__SecondFillColor"
          />
          <path
            id="_Pfad_24"
            data-name="<Pfad>"
            d="M203.41,187.27l.25,0a1,1,0,0,0,.93-.71l14.46-54a1,1,0,1,0-1.85-.49l-14.47,54A1,1,0,0,0,203.41,187.27Z"
            className="Badge__SecondFillColor"
          />
        </g>

        <g>
          <path
            d="M229.84,196.19s17.8-16-1.8-37.83c0,0-1.63.58,0-2.94s6.65-17.7-5.06-23.9c0,0,6.9-23.75-18.29-26.7,0,0-6.33.67-4.24,4.15s7.18,3.43.29,10.29S178.21,127,180.13,148c0,0,.06,2-2.62,3.43s-12.27,11.89-4.94,29.44c0,0-6.8,7.71-2.16,17.34,0,0,2.8-.6,32.61-.6S229.84,196.19,229.84,196.19Z"
            fill="#f5f5f5"
          />
          <g>
            <path
              d="M229.45,198.51l-1.83-1.71c5.94-6.36,8.73-13,8.3-19.78-.7-11.07-9.83-18.78-9.92-18.86l1.59-1.92c.41.34,10.06,8.49,10.83,20.62C238.89,184.34,235.87,191.62,229.45,198.51Z"
              fill="#462712"
            />
            <path
              d="M169.61,199.41c-.06-.1-5.5-9.46,1.48-18.15-1.74-3.8-8.18-20.54,7.06-31.89,8.76-6.53,17.11-8.8,25.2-11,6.41-1.74,12.47-3.38,18.51-7l.13-.07c2.14-1,4.47-.12,6.38,2.35,3.81,4.91,5.79,16.41-.6,24.35-4.73,5.88-16.73,9.38-28.33,12.77-9.41,2.75-19.14,5.59-23.8,9.47-9.59,8-4.12,17.51-3.88,17.91Zm53.47-65.88c-6.31,3.81-12.8,5.57-19.08,7.27-7.86,2.13-16,4.32-24.36,10.57-12.95,9.66-8.68,23.29-6.71,27.91q.52-.5,1.11-1c5.06-4.2,14.6-7,24.7-9.94,11.23-3.28,22.84-6.67,27.08-11.94,5.39-6.68,4-16.88.58-21.25C225.73,134.3,224.43,133,223.08,133.53Z"
              fill="#462712"
            />
            <path
              d="M179.32,149.66c-.18-.48-4.34-11.89,4.2-20.66,3.65-3.75,8.22-6.17,11.89-8.13,5-2.63,7.73-4.24,7.25-6.7-.34-1.73-1-2.85-1.94-3.34a3.48,3.48,0,0,1-1.88-2.9,3.16,3.16,0,0,1,1.47-2.83c2.43-1.55,6.74-2.69,13.5.61a20.24,20.24,0,0,1,10.61,12.94c1.51,5.26,1.16,11.19-.85,14.43l-2.12-1.32c1.4-2.26,2.05-7.26.56-12.42A17.74,17.74,0,0,0,212.72,108c-4.53-2.22-8.36-2.48-11.06-.75a.65.65,0,0,0-.32.61.92.92,0,0,0,.53.78c1.66.86,2.75,2.57,3.25,5.09.86,4.4-3.49,6.71-8.53,9.39-3.71,2-7.9,4.2-11.28,7.67-7.45,7.64-3.69,17.93-3.65,18Z"
              fill="#462712"
            />
          </g>
          <g>
            <g>
              <path
                d="M226.07,207l-21.45,84.82a4.27,4.27,0,0,1-8.33,0L174.84,207Z"
                fill="#b79780"
              />
              <path
                d="M200.45,296.42a5.52,5.52,0,0,1-5.37-4.27l-21.85-86.37h54.45l-21.85,86.37A5.53,5.53,0,0,1,200.45,296.42Zm-24-88.14,21.06,83.26a3,3,0,0,0,5.91,0l21.06-83.26Z"
                fill="#462712"
              />
            </g>
            <g>
              <rect
                x="168.99"
                y="197.65"
                width="63.5"
                height="10.79"
                rx="2.09"
                ry="2.09"
                fill="#eecbb5"
              />
              <path
                d="M230.39,209.7H171.08a3.35,3.35,0,0,1-3.34-3.35v-6.6a3.35,3.35,0,0,1,3.34-3.35h59.31a3.35,3.35,0,0,1,3.34,3.35v6.6A3.35,3.35,0,0,1,230.39,209.7Zm-59.31-10.8a.85.85,0,0,0-.84.85v6.6a.85.85,0,0,0,.84.85h59.31a.84.84,0,0,0,.84-.85v-6.6a.85.85,0,0,0-.84-.85Z"
                fill="#462712"
              />
            </g>
          </g>
        </g>
      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x=""
          y=""
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Fjalla One"
          fontSize="1.5em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_7__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".79ex"
          textAnchor="middle"
          fontFamily="Fjalla One"
          fontSize="1.5em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_7__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

    </svg>
  );
}

export default Badge;

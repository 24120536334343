import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_8 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_8__TopCircle"
          d="M 70 190 A 50 50 0 0 1 330 190"
        />

        <path
          id="RadialBadge_8__BottomCircle"
          d="M 80 205 A 50 50 0 0 0 320 205"
        />
      </defs>

      {/* ------------------------------------------ */}

      <g>
        <rect width="400" height="400" opacity="0" />
        <g className="SHAPE">
          <path
            d="M43.69,105v53.73h.2c0,1.83,0,3.65,0,5.43,0,10,0,20.09,0,30.15,0,15.36-.14,30.73-.14,46.1,0,41.56,21.7,79.7,55.82,103.06a23.23,23.23,0,0,0,2.3,1.37l88.25,45.34a21.89,21.89,0,0,0,19.89,0L298.2,344.8a22.13,22.13,0,0,0,2.3-1.37c34.11-23.36,55.81-61.5,55.81-103.06q0-49.76,0-99.53h0V73a20.37,20.37,0,0,0-9.88-17.36L324.1,42A240.21,240.21,0,0,0,75.9,42L53.57,55.66A20.37,20.37,0,0,0,43.69,73Z"
            className="Badge__SecondFillColor"
            fillRule="evenodd"
          />
          <path
            d="M49.87,108.72v51.6h.19c0,1.76,0,3.5,0,5.21,0,9.65,0,19.31,0,29,0,14.75-.13,29.52-.13,44.27,0,39.92,20.84,76.56,53.61,99a19.83,19.83,0,0,0,2.21,1.32l84.76,43.54a21,21,0,0,0,19.11,0l84.76-43.54a19.83,19.83,0,0,0,2.21-1.32c32.76-22.43,53.6-59.07,53.6-99V143.18h0V78a19.55,19.55,0,0,0-9.48-16.67L319.2,48.25a230.7,230.7,0,0,0-238.4,0L59.36,61.36A19.58,19.58,0,0,0,49.87,78Z"
            className="Badge__FirstFillColor"
            fillRule="evenodd"
          />
          <path
            d="M35,175.39H34.8V78A34.64,34.64,0,0,1,51.5,48.5L72.94,35.4a245.76,245.76,0,0,1,254.12,0L348.5,48.5A34.61,34.61,0,0,1,365.2,78V238.77c0,44.93-23.31,86.2-60.16,111.43a36.2,36.2,0,0,1-3.83,2.29L216.44,396a36.06,36.06,0,0,1-32.88,0L98.8,352.49A36.2,36.2,0,0,1,95,350.2C58.12,325,34.8,283.7,34.8,238.77c0-14.77.09-29.54.12-44.31q0-9.53,0-19.07Zm11.61-11.77h.16v2.82q0,14,0,28.05c0,14.76-.13,29.52-.13,44.28,0,41,21.41,78.69,55.05,101.71a23.79,23.79,0,0,0,2.56,1.54l84.76,43.54a24.32,24.32,0,0,0,22.12,0L295.83,342a23.79,23.79,0,0,0,2.56-1.54c33.63-23,55-60.67,55-101.71V78a22.89,22.89,0,0,0-11.06-19.49L320.92,45.45a234,234,0,0,0-241.84,0L57.64,58.55A22.88,22.88,0,0,0,46.57,78Z"
            className="Badge__FirstFillColor"
            fillRule="evenodd"
          />
        </g>

        <g className="ILLUSTRATION">
          <g>
            <g>
              <polygon
                points="264.77 263.42 204.1 255.98 133.99 263.99 187.64 277.78 264.77 263.42"
                fill="#fff"
              />
              <path
                d="M187.64,278.76a.67.67,0,0,1-.24,0l-53.66-13.79a1,1,0,0,1,.14-1.91L204,255h.23l60.67,7.45a1,1,0,0,1,.85.93,1,1,0,0,1-.79,1l-77.13,14.36Zm-48.31-14.4,48.35,12.43,70.72-13.17L204.1,257Z"
                fill="#333052"
              />
            </g>
            <g>
              <path
                d="M264.77,263.42c4-12.78-2.93-19-2.93-19l-74.28,12.68s3.63,12.77.08,20.67Z"
                fill="#fff"
              />
              <path
                d="M187.64,278.76a1,1,0,0,1-.76-.38,1,1,0,0,1-.12-1c3.35-7.5-.1-19.89-.13-20a1,1,0,0,1,.77-1.22l74.27-12.68a1,1,0,0,1,.82.23c.3.27,7.34,6.79,3.21,20a1,1,0,0,1-.75.67l-77.13,14.36Zm1.13-20.87c.73,3,2.53,11.86.39,18.62L264,262.58c3-10.2-1.26-15.75-2.51-17.11Zm76,5.53h0Z"
                fill="#333052"
              />
            </g>
            <g>
              <polygon
                points="261.84 244.43 204.02 235.3 134.95 243.35 187.56 257.11 261.84 244.43"
                fill="#fff"
              />
              <path
                d="M187.56,258.08l-.24,0-52.61-13.76a1,1,0,0,1-.73-1,1,1,0,0,1,.86-.9l69.07-8a.76.76,0,0,1,.26,0L262,243.47a1,1,0,0,1,.82.95,1,1,0,0,1-.81,1l-74.27,12.67ZM140.2,243.72l47.41,12.39,68.23-11.65L204,236.28Z"
                fill="#333052"
              />
            </g>
            <polygon
              points="264.77 263.42 204.1 255.98 133.99 263.99 187.64 277.78 264.77 263.42"
              fill="#d23068"
            />
            <g>
              <path
                d="M138.55,244.29s1.93,12,.25,19.09l50.86,12.75s2.48-1.67,2.42-2.21-.54-13.63-.54-13.63L190.25,258,188,255.79l-47.65-12.1Z"
                fill="#fbfaf6"
              />
              <path
                d="M189.66,277.1l-.24,0-50.86-12.75a1,1,0,0,1-.71-1.17c1.62-6.8-.24-18.59-.26-18.7a1,1,0,0,1,.66-1.08l1.84-.6a.91.91,0,0,1,.54,0l47.65,12.1a1,1,0,0,1,.45.25l2.21,2.23a1.16,1.16,0,0,1,.16.21l1.29,2.27a.93.93,0,0,1,.12.44c.19,5.2.5,13.11.54,13.58s.11,1.11-2.85,3.11A1,1,0,0,1,189.66,277.1Zm-49.72-14.44,49.53,12.42a12.7,12.7,0,0,0,1.63-1.33c-.1-1.75-.43-10.74-.52-13.19l-1.11-1.95-1.93-1.94-47.11-12-.79.26C140,247.69,141.1,256.49,139.94,262.66ZM191.11,274h0Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="191.64"
                y1="273.79"
                x2="140.79"
                y2="261.49"
                fill="#fff"
              />
              <path
                d="M191.64,274.28h-.11L140.68,262a.49.49,0,0,1,.22-1l50.86,12.3a.49.49,0,0,1-.12,1Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="191.75"
                y1="271.16"
                x2="140.89"
                y2="258.86"
                fill="#fff"
              />
              <path
                d="M191.75,271.65l-.12,0-50.85-12.3a.49.49,0,0,1-.36-.59.47.47,0,0,1,.59-.35l50.85,12.3a.49.49,0,0,1-.11,1Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="191.85"
                y1="268.52"
                x2="140.99"
                y2="256.22"
                fill="#fff"
              />
              <path
                d="M191.85,269h-.11l-50.86-12.3a.5.5,0,0,1-.36-.59.49.49,0,0,1,.59-.36L192,268.05a.49.49,0,0,1,.36.59A.48.48,0,0,1,191.85,269Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="191.95"
                y1="265.89"
                x2="141.1"
                y2="253.59"
                fill="#fff"
              />
              <path
                d="M192,266.38l-.11,0L141,254.06a.49.49,0,0,1-.36-.59.48.48,0,0,1,.59-.35l50.86,12.3a.47.47,0,0,1,.35.58A.48.48,0,0,1,192,266.38Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="192.05"
                y1="263.25"
                x2="141.2"
                y2="250.95"
                fill="#fff"
              />
              <path
                d="M192.05,263.74h-.11l-50.86-12.3a.49.49,0,0,1,.23-1l50.86,12.3a.49.49,0,0,1,.36.59A.5.5,0,0,1,192.05,263.74Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="192.16"
                y1="260.62"
                x2="141.3"
                y2="248.32"
                fill="#fff"
              />
              <path
                d="M192.16,261.1H192l-50.85-12.3a.49.49,0,0,1,.23-.95l50.85,12.31a.48.48,0,0,1,.36.58A.47.47,0,0,1,192.16,261.1Z"
                fill="#333052"
              />
            </g>
            <g>
              <path
                d="M264.77,263.42c4-12.78-2.93-19-2.93-19l-74.28,12.68s3.63,12.77.08,20.67Z"
                fill="#ea5c87"
              />
              <path
                d="M245.17,261.11c5.47-.53,11-1.49,15.56-4.64a16.11,16.11,0,0,0,4.78-5.05,14.34,14.34,0,0,0-3.67-7l-74.28,12.68a50.8,50.8,0,0,1,1.47,8.35c1.13.3,2.26.6,3.39,1a49.94,49.94,0,0,0,11.48,2l3.48-.3c5.7-1,11.41-1.8,17.12-2.65,3-.45,6-.93,9.05-1.41l7.6-1.63,2.06-.42A3.13,3.13,0,0,1,245.17,261.11Z"
                fill="#d23068"
              />
              <path
                d="M187.64,278.76a1,1,0,0,1-.76-.38,1,1,0,0,1-.12-1c3.35-7.5-.1-19.89-.13-20a1,1,0,0,1,.77-1.22l74.27-12.68a1,1,0,0,1,.82.23c.3.27,7.34,6.79,3.21,20a1,1,0,0,1-.75.67l-77.13,14.36Zm1.13-20.87c.73,3,2.53,11.86.39,18.62L264,262.58c3-10.2-1.26-15.75-2.51-17.11Zm76,5.53h0Z"
                fill="#333052"
              />
            </g>
            <g>
              <polygon
                points="261.84 244.43 204.02 235.3 134.95 243.35 187.56 257.11 261.84 244.43"
                fill="#ea5c87"
              />
              <path
                d="M146.33,244.64c2,.59,4,1,6,1.41s4.11,1,6.18,1.52l1.64.37c1.67.31,3.34.65,5,1.07h0c1.82.4,3.64.81,5.44,1.29,2.08.54,4.12,1.17,6.14,1.91l1.06.37.21,0c2.17.53,4.4.8,6.57,1.35,1.41.36,2.78.77,4.21,1.06a28.17,28.17,0,0,1,4.39,1.1l68.67-11.72L204,235.3l-63.18,7.36C142.7,243.2,144.47,244.09,146.33,244.64Z"
                fill="#d23068"
              />
              <path
                d="M187.56,258.08l-.24,0-52.61-13.76a1,1,0,0,1-.73-1,1,1,0,0,1,.86-.9l69.07-8a.76.76,0,0,1,.26,0L262,243.47a1,1,0,0,1,.82.95,1,1,0,0,1-.81,1l-74.27,12.67ZM140.2,243.72l47.41,12.39,68.23-11.65L204,236.28Z"
                fill="#333052"
              />
            </g>
          </g>
          <g>
            <g>
              <polygon
                points="136.22 238.87 193.84 235.83 259.34 247.95 207.91 257.44 136.22 238.87"
                fill="#fff"
              />
              <path
                d="M207.91,258.41a1.07,1.07,0,0,1-.25,0L136,239.81a1,1,0,0,1,.2-1.91l57.61-3a.87.87,0,0,1,.23,0L259.51,247a1,1,0,0,1,.8.95,1,1,0,0,1-.8,1l-51.42,9.48Zm-65.35-18.9,65.38,16.93L254,248l-60.21-11.14Z"
                fill="#333052"
              />
            </g>
            <g>
              <path
                d="M136.22,238.87c-2.93-12.3,4-17.7,4-17.7L209.34,238s-4.25,11.79-1.43,19.47Z"
                fill="#fff"
              />
              <path
                d="M207.91,258.41l-.25,0L136,239.81a1,1,0,0,1-.7-.71c-3-12.76,4-18.46,4.36-18.69a.93.93,0,0,1,.82-.18L209.57,237a1,1,0,0,1,.62.49,1,1,0,0,1,.06.79c0,.11-4.09,11.55-1.43,18.8a1,1,0,0,1-.19,1A1,1,0,0,1,207.91,258.41ZM137,238.08l69.45,18c-1.53-6.46.69-14.56,1.59-17.4l-67.59-16.42C139.21,223.46,134.94,228.38,137,238.08Z"
                fill="#333052"
              />
            </g>
            <g>
              <polygon
                points="140.22 221.17 195.26 216.36 259.78 228.46 209.34 237.97 140.22 221.17"
                fill="#fff"
              />
              <path
                d="M209.34,238.94a.85.85,0,0,1-.23,0L140,222.12a1,1,0,0,1,.15-1.91l55-4.82a.74.74,0,0,1,.26,0L260,227.5a1,1,0,0,1,0,1.91l-50.44,9.51A.55.55,0,0,1,209.34,238.94Zm-63.13-17.31L209.36,237l45.15-8.51-59.29-11.12Z"
                fill="#333052"
              />
            </g>
            <g>
              <polygon
                points="136.22 238.87 193.84 235.83 259.34 247.95 207.91 257.44 136.22 238.87"
                fill="#6aa7b7"
              />
              <path
                d="M207.91,258.41a1.07,1.07,0,0,1-.25,0L136,239.81a1,1,0,0,1,.2-1.91l57.61-3a.87.87,0,0,1,.23,0L259.51,247a1,1,0,0,1,.8.95,1,1,0,0,1-.8,1l-51.42,9.48Zm-65.35-18.9,65.38,16.93L254,248l-60.21-11.14Z"
                fill="#333052"
              />
            </g>
            <g>
              <path
                d="M256.32,229.11s-2.59,11.18-1.47,17.95l-48.73,8.68s-2.23-1.73-2.14-2.23,1.4-12.81,1.4-12.81l1.37-2,2.22-2,45.66-8.28Z"
                fill="#fbfaf6"
              />
              <path
                d="M206.12,256.72a1,1,0,0,1-.6-.21c-2.68-2.09-2.57-2.76-2.5-3.17s.88-7.86,1.4-12.74a1,1,0,0,1,.15-.43l1.37-2.06a1,1,0,0,1,.16-.19l2.23-1.95a.91.91,0,0,1,.47-.23l45.66-8.28a1,1,0,0,1,.54.06l1.69.69a1,1,0,0,1,.58,1.12c0,.11-2.54,11.08-1.46,17.58A1,1,0,0,1,255,248l-48.73,8.68ZM205,253.39a11.17,11.17,0,0,0,1.4,1.32l47.37-8.44c-.66-5.85.9-14,1.45-16.57l-.67-.27-45.12,8.18-1.93,1.69L206.33,241C206.08,243.35,205.18,251.7,205,253.39Zm0,.26v0Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="204.4"
                y1="253.42"
                x2="253.09"
                y2="245.16"
                fill="#fff"
              />
              <path
                d="M204.4,253.9a.48.48,0,0,1-.48-.4.49.49,0,0,1,.4-.56L253,244.68a.48.48,0,0,1,.56.4.48.48,0,0,1-.4.56l-48.69,8.26Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="204.48"
                y1="250.93"
                x2="253.17"
                y2="242.67"
                fill="#fff"
              />
              <path
                d="M204.48,251.42a.49.49,0,0,1-.08-1l48.69-8.26a.49.49,0,1,1,.16,1l-48.69,8.26Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="204.55"
                y1="248.44"
                x2="253.25"
                y2="240.18"
                fill="#fff"
              />
              <path
                d="M204.55,248.93a.49.49,0,0,1-.08-1l48.69-8.26a.51.51,0,0,1,.57.4.49.49,0,0,1-.4.56l-48.69,8.26Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="204.63"
                y1="245.95"
                x2="253.32"
                y2="237.69"
                fill="#fff"
              />
              <path
                d="M204.63,246.44a.49.49,0,0,1-.08-1l48.69-8.25a.48.48,0,0,1,.56.39.47.47,0,0,1-.4.56l-48.69,8.26Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="204.71"
                y1="243.47"
                x2="253.4"
                y2="235.21"
                fill="#fff"
              />
              <path
                d="M204.7,244a.48.48,0,0,1-.47-.4.47.47,0,0,1,.39-.56l48.7-8.26a.49.49,0,0,1,.56.4.5.5,0,0,1-.4.56l-48.69,8.25Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="204.78"
                y1="240.98"
                x2="253.47"
                y2="232.72"
                fill="#fff"
              />
              <path
                d="M204.78,241.46a.48.48,0,0,1-.08-1l48.69-8.26a.48.48,0,0,1,.56.4.48.48,0,0,1-.4.56l-48.69,8.26Z"
                fill="#333052"
              />
            </g>
            <g>
              <path
                d="M136.22,238.87c-2.93-12.3,4-17.7,4-17.7L209.34,238s-4.25,11.79-1.43,19.47Z"
                fill="#8ddff4"
              />
              <path
                d="M208.1,242.28c-2.32-.75-4.65-1.37-7.06-1.93-5.58-1.28-11.22-2.34-16.8-3.64-2.82-.66-5.59-1.5-8.43-2.06s-5.67-1-8.5-1.5-5.65-1.31-8.46-2l-.26-.06a10.74,10.74,0,0,1-1.78-.43l-.9-.21a3.2,3.2,0,0,1-2-.25c-.29-.13-.59-.26-.89-.37l-2.84-.55a38.6,38.6,0,0,1-4.87-1.24l-.72-.16c-2.54-.6-4.85-1.91-7.32-2.63-1.42,2.88-2.49,7.36-1,13.64l71.69,18.57C206.17,252.71,207.12,246.42,208.1,242.28Z"
                fill="#6aa7b7"
              />
              <path
                d="M207.91,258.41l-.25,0L136,239.81a1,1,0,0,1-.7-.71c-3-12.76,4-18.46,4.36-18.69a.93.93,0,0,1,.82-.18L209.57,237a1,1,0,0,1,.62.49,1,1,0,0,1,.06.79c0,.11-4.09,11.55-1.43,18.8a1,1,0,0,1-.19,1A1,1,0,0,1,207.91,258.41ZM137,238.08l69.45,18c-1.53-6.46.69-14.56,1.59-17.4l-67.59-16.42C139.21,223.46,134.94,228.38,137,238.08Z"
                fill="#333052"
              />
            </g>
            <g>
              <polygon
                points="140.22 221.17 195.26 216.36 259.78 228.46 209.34 237.97 140.22 221.17"
                fill="#8ddff4"
              />
              <path
                d="M209.34,238.94a.85.85,0,0,1-.23,0L140,222.12a1,1,0,0,1,.15-1.91l55-4.82a.74.74,0,0,1,.26,0L260,227.5a1,1,0,0,1,0,1.91l-50.44,9.51A.55.55,0,0,1,209.34,238.94Zm-63.13-17.31L209.36,237l45.15-8.51-59.29-11.12Z"
                fill="#333052"
              />
            </g>
          </g>
          <g>
            <ellipse
              cx="200.88"
              cy="224.98"
              rx="19.05"
              ry="2.89"
              fill="#6aa7b7"
            />
            <path
              d="M245.85,152.8l-.65,0a12.42,12.42,0,0,1-10.45-7.29,1,1,0,0,1,1.78-.78,10.22,10.22,0,0,0,18.21,1.06,1,1,0,0,1,1.68,1A12.45,12.45,0,0,1,245.85,152.8Z"
              fill="#333052"
            />
            <g>
              <path
                d="M254.68,143.41a2.85,2.85,0,0,0,5.69.32C260.69,139.93,254.78,139.58,254.68,143.41Z"
                fill="#f8e780"
              />
              <path
                d="M257.51,147.31h-.19a3.94,3.94,0,0,1-3.61-3.92h0a3.61,3.61,0,0,1,1.93-3.22,4.2,4.2,0,0,1,4.13.23,3.6,3.6,0,0,1,1.57,3.41A4,4,0,0,1,257.51,147.31Zm0-5.63a2.13,2.13,0,0,0-1,.22,1.65,1.65,0,0,0-.88,1.54h0a1.88,1.88,0,0,0,3.75.21,1.65,1.65,0,0,0-.71-1.63A2.15,2.15,0,0,0,257.5,141.68Zm-2.82,1.73h0Z"
                fill="#333052"
              />
            </g>
            <g>
              <polygon
                points="162.31 202.64 240.34 205.69 228.55 110.13 168.1 112.02 162.31 202.64"
                fill="#ddca6c"
              />
              <path
                d="M240.34,206.66h0l-78-3a1,1,0,0,1-.94-1l5.8-90.62a1,1,0,0,1,.94-.91l60.45-1.89h0a1,1,0,0,1,1,.85l11.8,95.56a1,1,0,0,1-.25.78A1,1,0,0,1,240.34,206.66Zm-77-4.95,75.9,3-11.55-93.54L169,113Z"
                fill="#333052"
              />
            </g>
            <g>
              <polygon
                points="163.86 199.85 237.95 202.16 241.95 189.18 176.23 185.99 164.77 193.7 163.86 199.85"
                fill="#fbfaf6"
              />
              <path
                d="M238,203.13h0l-74.09-2.31a1,1,0,0,1-.72-.34,1,1,0,0,1-.22-.77l.92-6.15a1,1,0,0,1,.42-.67l11.45-7.71a1,1,0,0,1,.59-.16L242,188.21a1,1,0,0,1,.75.42,1,1,0,0,1,.13.84l-4,13A1,1,0,0,1,238,203.13Zm-73-4.22,72.26,2.25,3.41-11.07L176.5,187l-10.83,7.29Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="163.74"
                y1="197.38"
                x2="236.46"
                y2="199.93"
                fill="#fff"
              />
              <path
                d="M236.46,200.42h0l-72.71-2.55a.49.49,0,0,1-.47-.51.53.53,0,0,1,.5-.47l72.71,2.56a.49.49,0,0,1,0,1Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="164.52"
                y1="195.38"
                x2="237.23"
                y2="197.93"
                fill="#fff"
              />
              <path
                d="M237.24,198.42h0l-72.72-2.56a.48.48,0,0,1-.46-.5.53.53,0,0,1,.5-.47l72.71,2.55a.49.49,0,0,1,.47.51A.48.48,0,0,1,237.24,198.42Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="165.3"
                y1="193.37"
                x2="238.01"
                y2="195.92"
                fill="#fff"
              />
              <path
                d="M238,196.41h0l-72.72-2.55a.51.51,0,0,1-.47-.51.49.49,0,0,1,.49-.47h0L238,195.44a.49.49,0,0,1,0,1Z"
                fill="#333052"
              />
            </g>
            <g>
              <line
                x1="166.08"
                y1="191.37"
                x2="238.79"
                y2="193.92"
                fill="#fff"
              />
              <path
                d="M238.79,194.4h0l-72.72-2.55a.48.48,0,0,1-.47-.5.51.51,0,0,1,.51-.47l72.71,2.55a.49.49,0,0,1,0,1Z"
                fill="#333052"
              />
            </g>
            <g>
              <path
                d="M162.31,202.64l-7.9-83.9s4.26-12.49,13.85-12.42l8,83.47S168.4,191.07,162.31,202.64Z"
                fill="#e8d470"
              />
              <path
                d="M167,188.63a35.94,35.94,0,0,0,.84-9.2c.12-13.11.37-26.34-1.47-39.36a76.12,76.12,0,0,0-1.92-9.71,57.27,57.27,0,0,1-2-9.54,39.37,39.37,0,0,0-1.38-7.85,7,7,0,0,0-1.27-2.58c.11.14,0,0-.2-.12a25.54,25.54,0,0,0-5.2,8.47l7.9,83.9a34.09,34.09,0,0,1,4-6C165.47,194,166.25,191.26,167,188.63Z"
                fill="#d1bb5d"
              />
              <path
                d="M162.31,203.61a.58.58,0,0,1-.19,0,1,1,0,0,1-.78-.86l-7.9-83.89a1,1,0,0,1,.05-.41c.18-.53,4.58-13.08,14.68-13.08h.1a1,1,0,0,1,1,.88l8,83.46a1,1,0,0,1-.81,1.06c-.07,0-7.45,1.4-13.21,12.34A1,1,0,0,1,162.31,203.61Zm-6.91-84.74L163,199.51c4.77-7.55,10.1-9.83,12.18-10.46l-7.79-81.72C159.79,108,156,117.41,155.4,118.87Z"
                fill="#333052"
              />
            </g>
            <g>
              <polygon
                points="168.24 106.14 231.81 110.33 243.12 191.89 176.2 189.6 168.24 106.14"
                fill="#f8e780"
              />
              <path
                d="M229.88,184.56l-.93-.17c-2,.11-3.93.25-5.82.27-7.75.12-15.48-.71-23.23-.86-4.94-.09-11.59.81-14.46-4.33-1.34-2.39-1.36-5.14-1.57-7.8-.29-3.63-.78-7.23-1.28-10.83-.76-5.46-1.54-10.93-1.77-16.44-.53-1.75-1-3.51-1.38-5.33a106.5,106.5,0,0,1-1.75-11.93,53.08,53.08,0,0,1,.6-15.42,29.63,29.63,0,0,1,.79-3.41c.13-.48.26-1,.42-1.43l-11.26-.74,8,83.46,66.92,2.29-.39-2.85C238.82,186.61,234.36,185.42,229.88,184.56Z"
                fill="#e8d470"
              />
              <path
                d="M203.15,109.47c1.84,1,3.71,1.91,5.58,2.83,6.19,3.06,12.27,6.26,16.6,11.8,3.8,4.86,6.07,10.58,7.91,16.42,1,3.07,1.8,6.18,2.85,9.23.54,1.59,1.16,3.13,1.84,4.66l-6.11-44.08-30.78-2C201.74,108.69,202.44,109.09,203.15,109.47Z"
                fill="#fbf4c2"
              />
              <path
                d="M243.12,192.86h0l-66.92-2.28a1,1,0,0,1-.93-.88l-8-83.47a1,1,0,0,1,1-1.06l63.57,4.19a1,1,0,0,1,.9.84l11.3,81.55a1,1,0,0,1-.24.79A1,1,0,0,1,243.12,192.86Zm-66-4.2L242,190.88l-11-79.63-61.64-4.07Z"
                fill="#333052"
              />
            </g>
            <path
              d="M186.7,136.09a2.09,2.09,0,1,1-1.76-2.38A2.09,2.09,0,0,1,186.7,136.09Z"
              fill="#333052"
            />
            <path
              d="M224.24,136.77a2.09,2.09,0,1,1-1.76-2.37A2.08,2.08,0,0,1,224.24,136.77Z"
              fill="#333052"
            />
            <path
              d="M204,146.61H204a13.4,13.4,0,0,1-7.86-2.42,7.56,7.56,0,0,1-3.39-6.11,1,1,0,0,1,1-1h0a1,1,0,0,1,1,1,5.68,5.68,0,0,0,2.6,4.53,11.46,11.46,0,0,0,6.72,2H204a11.46,11.46,0,0,0,6.67-2,5.66,5.66,0,0,0,2.64-4.5,1,1,0,0,1,1-1h0a1,1,0,0,1,1,1,7.54,7.54,0,0,1-3.46,6.07A13.36,13.36,0,0,1,204,146.61Z"
              fill="#333052"
            />
            <path
              d="M152.44,160a15.79,15.79,0,0,1-6.05-1.18,16.26,16.26,0,0,1-9.78-12.89,1,1,0,1,1,1.93-.24,14.08,14.08,0,0,0,22.69,9.38,1,1,0,0,1,1.36.16,1,1,0,0,1-.16,1.37A16.18,16.18,0,0,1,152.44,160Z"
              fill="#333052"
            />
            <g>
              <path
                d="M134.91,144.11a3.45,3.45,0,0,0,5.36-4.34C137.49,136.22,132,140.65,134.91,144.11Z"
                fill="#f8e780"
              />
              <path
                d="M137.6,146.27a4.47,4.47,0,0,1-3.43-1.54,4.11,4.11,0,0,1-.78-4.18,4.88,4.88,0,0,1,3.72-3,4.12,4.12,0,0,1,3.93,1.63,4.54,4.54,0,0,1-.7,6.16A4.36,4.36,0,0,1,137.6,146.27Zm.15-6.83-.34,0a2.93,2.93,0,0,0-2.2,1.78,2.15,2.15,0,0,0,.45,2.24h0a2.47,2.47,0,0,0,3.85-3.11A2.18,2.18,0,0,0,137.75,139.44Z"
                fill="#333052"
              />
            </g>
            <path
              d="M189.83,222.51l-.19,0a1,1,0,0,1-.76-1.14,89.13,89.13,0,0,0,1.48-22.59,1,1,0,1,1,1.94-.12,90.67,90.67,0,0,1-1.52,23.08A1,1,0,0,1,189.83,222.51Z"
              fill="#333052"
            />
            <g>
              <path
                d="M195.36,223.07a1.93,1.93,0,0,1,.94,1.68c-.13.84-1.11,1.23-2,1.31a7.73,7.73,0,0,1-4-.66c-1.83-.85-2.89-2.66-.53-3.41A6.46,6.46,0,0,1,195.36,223.07Z"
                fill="#f8e780"
              />
              <path
                d="M193.55,227.08a8.69,8.69,0,0,1-3.64-.8c-1.56-.72-2.52-1.94-2.45-3.09,0-.57.35-1.59,2-2.13a7.44,7.44,0,0,1,6.45,1.23h0a2.77,2.77,0,0,1,1.32,2.61c-.17,1.15-1.25,2-2.81,2.13C194.15,227.06,193.85,227.08,193.55,227.08Zm-2.15-4.36a4.28,4.28,0,0,0-1.31.2c-.55.17-.69.36-.69.39s.37.76,1.33,1.2a6.74,6.74,0,0,0,3.51.59c.63-.07,1.07-.31,1.1-.5s-.17-.45-.57-.76h0A5.75,5.75,0,0,0,191.4,222.72Z"
                fill="#333052"
              />
            </g>
            <path
              d="M207.6,223h-.13a1,1,0,0,1-.83-1.09c1.14-8.49.79-14.44.12-22.53a1,1,0,0,1,1.94-.16c.67,8.22,1,14.26-.13,22.95A1,1,0,0,1,207.6,223Z"
              fill="#333052"
            />
            <g>
              <path
                d="M212.61,222.49a1.94,1.94,0,0,1,.95,1.68c-.13.84-1.12,1.23-2,1.32a7.76,7.76,0,0,1-4-.67c-1.83-.84-2.89-2.65-.53-3.41A6.49,6.49,0,0,1,212.61,222.49Z"
                fill="#f8e780"
              />
              <path
                d="M210.8,226.5a8.61,8.61,0,0,1-3.63-.8c-1.56-.72-2.53-1.93-2.46-3.09,0-.56.35-1.58,2-2.12a7.42,7.42,0,0,1,6.45,1.23,2.79,2.79,0,0,1,1.32,2.6c-.18,1.16-1.26,2-2.82,2.14C211.4,226.49,211.1,226.5,210.8,226.5Zm-2.15-4.35a4.28,4.28,0,0,0-1.31.19c-.55.17-.68.36-.68.39,0,.2.43.8,1.32,1.21a6.86,6.86,0,0,0,3.52.58c.61-.06,1.07-.31,1.09-.5s-.16-.45-.57-.75h0A5.75,5.75,0,0,0,208.65,222.15Z"
                fill="#333052"
              />
            </g>
            <path
              d="M226,142.31c0,.49-1.5.9-3.35.9s-3.36-.41-3.36-.9,1.5-.91,3.36-.91S226,141.81,226,142.31Z"
              fill="#ea5c87"
            />
            <path
              d="M188.92,141.4c0,.5-1.5.91-3.35.91s-3.36-.41-3.36-.91,1.51-.9,3.36-.9S188.92,140.91,188.92,141.4Z"
              fill="#ea5c87"
            />
          </g>
        </g>

      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x=""
          y=""
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Ropa Sans"
          fontSize="1.5em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_8__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".79ex"
          textAnchor="middle"
          fontFamily="Ropa Sans"
          fontSize="1.5em"
          letterSpacing="0.1em"
          className="Badge__ThirdFillColor"
        >
          <textPath xlinkHref="#RadialBadge_8__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

    </svg>
  );
}

export default Badge;

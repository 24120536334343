import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 200"
      width="100%"
      height="100%"
      className={`BlankBadge_1 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <g className="RECTANGLE">
        <rect
          width="400"
          height="200"
          opacity="0"
          className="Badge__FirstFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x="49%"
          y="35%"
          dy=".9ex"
          textAnchor="middle"
          fontFamily="Norican"
          fontSize="10.5em"
          letterSpacing="0em"
          className="Badge__FirstFillColor"
        >
          {line2}
        </text>

        <text
          x="50%"
          y="90%"
          textAnchor="middle"
          fontFamily="Rajdhani"
          fontSize="1.6em"
          fontWeight="700"
          letterSpacing="0.1em"
          className="Badge__FirstFillColor"
        >
          {line3.toUpperCase()} {line4.toUpperCase()}
        </text>

      </g>

      {/* ------------------------------------------ */}

    </svg>
  );
}

export default Badge;

import Badge from './Badge';

export default {
  id: 'burger',
  title: 'Burger',
  colors: 2,
  animated: true,
  premium: true,
  BadgeComponentStateless: Badge
};

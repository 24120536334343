import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_13 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_13__TopCircle"
          d="M 90 200 A 50 50 0 0 1 310 200"
        />

        <path
          id="RadialBadge_13__BottomCircle"
          d="M 90 200 A 50 50 0 0 0 310 200"
        />
      </defs>

      {/* ------------------------------------------ */}

      <g>
        <path
          d="M399.92,205.43c.05-1.8.08-3.61.08-5.43s0-3.63-.08-5.43a17.28,17.28,0,0,1-3.21-31q-1-5.34-2.24-10.6a17.27,17.27,0,0,1-9.6-29.63q-2.06-5-4.39-9.89a17.26,17.26,0,0,1-15.55-27q-3.07-4.47-6.37-8.77A17.24,17.24,0,0,1,337.8,54.62c-2.62-2.5-5.31-4.91-8.05-7.27A17.22,17.22,0,0,1,304.65,29q-4.59-2.84-9.34-5.44a17.2,17.2,0,0,1-28.38-12.67q-5.07-1.81-10.27-3.35A17.16,17.16,0,0,1,239.42,14,17.2,17.2,0,0,1,226.28,1.13Q220.94.43,215.52,0A17.2,17.2,0,0,1,200,9.81,17.2,17.2,0,0,1,184.48,0c-3.62.28-7.2.66-10.76,1.13A17.23,17.23,0,0,1,160.58,14a17.19,17.19,0,0,1-17.24-6.39q-5.19,1.55-10.27,3.35A17.2,17.2,0,0,1,104.69,23.6Q99.94,26.19,95.35,29a17.22,17.22,0,0,1-25.1,18.32c-2.74,2.35-5.43,4.76-8,7.26A17.24,17.24,0,0,1,41.44,77.75q-3.3,4.29-6.37,8.77a17.26,17.26,0,0,1-15.55,27q-2.34,4.88-4.39,9.89a17.29,17.29,0,0,1,4.52,17.86A17.2,17.2,0,0,1,5.53,153q-1.25,5.25-2.24,10.61a17.25,17.25,0,0,1,8.12,16.51A17.23,17.23,0,0,1,.07,194.57C0,196.37,0,198.18,0,200s0,3.63.07,5.43a17.23,17.23,0,0,1,11.34,14.45A17.27,17.27,0,0,1,3.29,236.4q1,5.34,2.24,10.6a17.2,17.2,0,0,1,14.12,11.77,17.28,17.28,0,0,1-4.52,17.86c1.37,3.34,2.83,6.65,4.39,9.89a17.26,17.26,0,0,1,15.55,27q3.07,4.47,6.37,8.78A17.23,17.23,0,0,1,62.2,345.38q3.93,3.75,8,7.27A17.22,17.22,0,0,1,95.35,371q4.59,2.84,9.34,5.44a17.2,17.2,0,0,1,28.38,12.68q5.07,1.8,10.27,3.34a17.19,17.19,0,0,1,30.38,6.45c3.56.47,7.14.85,10.76,1.13a17.18,17.18,0,0,1,31,0q5.41-.42,10.76-1.13a17.19,17.19,0,0,1,30.38-6.45q5.19-1.53,10.27-3.34a17.2,17.2,0,0,1,28.38-12.68q4.76-2.59,9.34-5.44a17.22,17.22,0,0,1,25.1-18.31q4.11-3.52,8.05-7.27a17.23,17.23,0,0,1,20.76-23.12q3.3-4.31,6.37-8.78a17.26,17.26,0,0,1,15.55-27c1.56-3.24,3-6.55,4.39-9.89a17.27,17.27,0,0,1,9.6-29.63q1.25-5.25,2.24-10.6a17.28,17.28,0,0,1,3.21-31Z"
          className="Badge__FirstFillColor DOTS"
        />
        <g className="DOTS2">
          <g>
            <path
              d="M212.23,27.71h0c-6.09,0-6.1,4.72-12.21,4.72s-6.12-4.71-12.21-4.72h0a8.4,8.4,0,0,0,0,16.8h0c6.09,0,6.1-4.72,12.21-4.72s6.12,4.7,12.21,4.72h0a8.4,8.4,0,0,0,0-16.8ZM187.77,38.34A2.23,2.23,0,1,1,190,36.11,2.22,2.22,0,0,1,187.77,38.34Zm24.45,0a2.23,2.23,0,1,1,2.23-2.23A2.22,2.22,0,0,1,212.22,38.34Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M212.23,355.49h0c-6.09,0-6.1,4.72-12.21,4.72s-6.12-4.7-12.21-4.72h0a8.4,8.4,0,0,0,0,16.8h0c6.09,0,6.1-4.72,12.21-4.72s6.12,4.71,12.21,4.72h0a8.4,8.4,0,0,0,0-16.8Zm-24.46,10.63a2.23,2.23,0,1,1,2.23-2.23A2.22,2.22,0,0,1,187.77,366.12Zm24.45,0a2.23,2.23,0,1,1,2.23-2.23A2.22,2.22,0,0,1,212.22,366.12Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M167.35,30.41h0c-5.87,1.6-4.67,6.14-10.57,7.73s-7.13-3-13-1.39h0A8.39,8.39,0,0,0,148.06,53h0c5.88-1.6,4.67-6.14,10.58-7.73s7.12,3,13,1.39h0a8.4,8.4,0,0,0-4.34-16.22ZM146.47,47a2.23,2.23,0,1,1,1.58-2.73A2.22,2.22,0,0,1,146.47,47Zm23.62-6.34a2.24,2.24,0,1,1,1.58-2.74A2.23,2.23,0,0,1,170.09,40.68Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M251.94,347h0c-5.88,1.6-4.68,6.14-10.58,7.73s-7.12-2.95-13-1.39h0a8.4,8.4,0,0,0,4.34,16.22h0c5.88-1.6,4.68-6.14,10.58-7.73s7.13,3,13,1.39h0A8.39,8.39,0,0,0,251.94,347Zm-20.88,16.62a2.24,2.24,0,1,1,1.57-2.74A2.23,2.23,0,0,1,231.06,363.64Zm23.62-6.35a2.23,2.23,0,1,1,1.57-2.74A2.23,2.23,0,0,1,254.68,357.29Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M124.7,44.66h0c-5.27,3.07-2.94,7.14-8.23,10.21s-7.64-1-12.92,2h0a8.39,8.39,0,0,0,8.37,14.55h0c5.27-3.06,2.93-7.14,8.22-10.21s7.65,1,12.93-2h0a8.4,8.4,0,0,0-8.38-14.55ZM108.82,66.13a2.23,2.23,0,1,1,.82-3A2.22,2.22,0,0,1,108.82,66.13ZM130,53.87a2.23,2.23,0,1,1,.81-3A2.22,2.22,0,0,1,130,53.87Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M288.11,328.52h0c-5.27,3.06-2.93,7.14-8.22,10.21s-7.65-1-12.93,2h0a8.4,8.4,0,0,0,8.38,14.55h0c5.27-3.07,2.94-7.14,8.23-10.21s7.64,1,12.92-2h0a8.39,8.39,0,0,0-8.37-14.55ZM272.23,350A2.23,2.23,0,1,1,273,347,2.22,2.22,0,0,1,272.23,350Zm21.17-12.26a2.23,2.23,0,1,1,.82-3.05A2.23,2.23,0,0,1,293.4,337.74Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M87.18,69.5h0c-4.3,4.33-1,7.66-5.31,12s-7.64,1-12,5.32h0A8.39,8.39,0,0,0,81.73,98.73h0c4.3-4.33,1-7.66,5.31-12s7.64-1,12-5.32h0A8.39,8.39,0,0,0,87.18,69.5ZM77.39,94.36a2.23,2.23,0,1,1,0-3.16A2.23,2.23,0,0,1,77.39,94.36ZM94.68,77a2.23,2.23,0,1,1,0-3.16A2.23,2.23,0,0,1,94.68,77Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M318.27,301.27h0s0,0,0,0c-4.3,4.33-1,7.66-5.31,12s-7.65,1-12,5.33h0a8.39,8.39,0,0,0,11.85,11.88h0c4.3-4.33,1-7.66,5.31-12s7.64-1,12-5.32h0a8.39,8.39,0,0,0-11.84-11.88Zm-9.8,24.87a2.23,2.23,0,0,1-3.15,0,2.26,2.26,0,0,1,0-3.16,2.23,2.23,0,0,1,3.15,3.16Zm17.29-17.34a2.23,2.23,0,1,1,0-3.16A2.23,2.23,0,0,1,325.76,308.8Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M57.35,103.23h0c-3,5.3,1,7.66-2,13s-7.12,3-10.18,8.25h0a8.38,8.38,0,1,0,14.51,8.4h0c3-5.3-1-7.66,2-13s7.12-3,10.18-8.25v0h0a8.38,8.38,0,1,0-14.51-8.4ZM54.3,129.79a2.22,2.22,0,1,1-.81-3A2.22,2.22,0,0,1,54.3,129.79Zm12.23-21.24a2.23,2.23,0,1,1-.81-3A2.22,2.22,0,0,1,66.53,108.55Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M340.37,267.12h0c-3,5.3,1,7.66-2,13s-7.12,3-10.18,8.25h0a8.38,8.38,0,1,0,14.51,8.4h0v0c3-5.3-1-7.66,2-13s7.12-3,10.18-8.25h0a8.38,8.38,0,1,0-14.51-8.4Zm-3,26.56a2.23,2.23,0,1,1-.82-3.05A2.23,2.23,0,0,1,337.33,293.68Zm12.22-21.24a2.22,2.22,0,1,1-.81-3.05A2.22,2.22,0,0,1,349.55,272.44Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M37.24,143.56h0v0c-1.56,5.9,3,7.13,1.39,13s-6.11,4.71-7.71,10.61h0a8.38,8.38,0,1,0,16.18,4.34h0c1.56-5.91-3-7.14-1.38-13.06s6.11-4.71,7.7-10.6v0h0a8.38,8.38,0,1,0-16.18-4.35ZM41.15,170a2.23,2.23,0,1,1-1.57-2.73A2.22,2.22,0,0,1,41.15,170Zm6.33-23.68a2.23,2.23,0,1,1-1.58-2.74A2.24,2.24,0,0,1,47.48,146.32Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M352.91,228.4h0c-1.56,5.91,3,7.13,1.38,13.06s-6.11,4.71-7.7,10.6v0h0a8.38,8.38,0,1,0,16.18,4.35h0v0c1.57-5.9-3-7.13-1.38-13.05s6.11-4.71,7.71-10.61h0a8.38,8.38,0,1,0-16.18-4.34Zm3.91,26.44a2.23,2.23,0,1,1-1.57-2.74A2.22,2.22,0,0,1,356.82,254.84Zm6.33-23.69a2.23,2.23,0,1,1-1.57-2.73A2.23,2.23,0,0,1,363.15,231.15Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M28.22,187.73h0c0,6.11,4.71,6.12,4.71,12.25s-4.69,6.14-4.71,12.25h0a8.38,8.38,0,1,0,16.75,0h0c0-6.11-4.7-6.12-4.7-12.25S45,193.86,45,187.75h0a8.38,8.38,0,1,0-16.75,0Zm10.6,24.53A2.23,2.23,0,1,1,36.6,210,2.22,2.22,0,0,1,38.82,212.26Zm0-24.52a2.23,2.23,0,1,1-2.22-2.24A2.22,2.22,0,0,1,38.82,187.74Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M355,187.73h0c0,6.11,4.7,6.12,4.7,12.25s-4.69,6.14-4.7,12.25h0a8.38,8.38,0,1,0,16.75,0h0c0-6.11-4.7-6.12-4.7-12.25s4.68-6.14,4.7-12.25h0a8.38,8.38,0,1,0-16.75,0Zm10.6,24.53A2.23,2.23,0,1,1,363.4,210,2.23,2.23,0,0,1,365.63,212.26Zm0-24.52a2.23,2.23,0,1,1-2.23-2.23A2.23,2.23,0,0,1,365.63,187.74Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M30.91,232.74h0c1.6,5.9,6.13,4.69,7.71,10.61s-2.94,7.15-1.39,13.05v0h0a8.38,8.38,0,1,0,16.18-4.35h0v0c-1.59-5.89-6.12-4.69-7.7-10.61s2.95-7.14,1.39-13.05h0a8.38,8.38,0,1,0-16.18,4.35Zm16.57,20.94a2.23,2.23,0,1,1-2.73-1.58A2.24,2.24,0,0,1,47.48,253.68ZM41.15,230a2.23,2.23,0,1,1-2.73-1.58A2.23,2.23,0,0,1,41.15,230Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M346.58,147.91h0c1.59,5.9,6.12,4.69,7.7,10.61s-2.94,7.15-1.38,13.06h0a8.38,8.38,0,1,0,16.18-4.35h0c-1.6-5.9-6.13-4.69-7.71-10.61s2.95-7.15,1.38-13v0h0a8.38,8.38,0,1,0-16.18,4.35Zm16.57,20.94a2.23,2.23,0,1,1-2.73-1.58A2.23,2.23,0,0,1,363.15,168.85Zm-6.33-23.69a2.23,2.23,0,1,1-2.72-1.58A2.22,2.22,0,0,1,356.82,145.16Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M45.12,275.52h0s0,0,0,0c3.06,5.28,7.12,2.94,10.18,8.24s-1,7.67,2,13v0h0a8.38,8.38,0,1,0,14.51-8.4h0c-3.06-5.28-7.13-2.94-10.18-8.25s1-7.66-2-13h0a8.38,8.38,0,1,0-14.51,8.4Zm21.41,15.93a2.23,2.23,0,1,1-3-.82A2.23,2.23,0,0,1,66.53,291.45ZM54.31,270.21a2.24,2.24,0,0,1-.82,3.05,2.23,2.23,0,1,1,.82-3.05Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M328.14,111.63h0v0c3,5.28,7.12,2.94,10.17,8.25s-1,7.66,2,13h0a8.38,8.38,0,1,0,14.51-8.4h0c-3.06-5.28-7.12-2.94-10.18-8.25s1-7.66-2-13v0h0a8.38,8.38,0,1,0-14.51,8.4Zm21.41,15.93a2.22,2.22,0,1,1-3-.82A2.24,2.24,0,0,1,349.55,127.56Zm-12.22-21.24a2.23,2.23,0,1,1-3-.82A2.24,2.24,0,0,1,337.33,106.32Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M69.89,313.15h0c4.32,4.31,7.64,1,12,5.32s1,7.67,5.31,12h0A8.39,8.39,0,0,0,99,318.62h0c-4.31-4.31-7.64-1-12-5.33s-1-7.66-5.31-12c0,0,0,0,0,0h0a8.39,8.39,0,0,0-11.84,11.88ZM94.68,323a2.26,2.26,0,0,1,0,3.16,2.23,2.23,0,1,1,0-3.16ZM77.39,305.64a2.23,2.23,0,1,1-3.15,0A2.24,2.24,0,0,1,77.39,305.64Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M301,81.38h0c4.31,4.31,7.64,1,12,5.32s1,7.67,5.31,12h0a8.39,8.39,0,1,0,11.84-11.88h0c-4.32-4.31-7.64-1-12-5.32s-1-7.67-5.31-12h0A8.39,8.39,0,0,0,301,81.38Zm24.79,9.82a2.23,2.23,0,1,1-3.15,0A2.24,2.24,0,0,1,325.76,91.2ZM308.47,73.86a2.23,2.23,0,1,1-3.14,0A2.23,2.23,0,0,1,308.47,73.86Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M103.52,343.07h0c5.28,3,7.63-1,12.92,2s3,7.14,8.23,10.21h0a8.4,8.4,0,0,0,8.38-14.55h0a0,0,0,0,0,0,0c-5.28-3-7.63,1-12.93-2s-2.95-7.15-8.22-10.22h0a8.39,8.39,0,0,0-8.37,14.55ZM130,346.13a2.23,2.23,0,1,1-3,.82A2.22,2.22,0,0,1,130,346.13Zm-21.17-12.26a2.23,2.23,0,1,1-3,.81A2.24,2.24,0,0,1,108.83,333.87Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M266.92,59.21h0c5.28,3,7.63-1,12.93,2s2.95,7.15,8.22,10.21h0a8.39,8.39,0,0,0,8.37-14.55h0c-5.28-3-7.63,1-12.92-2s-3-7.14-8.23-10.21h0a8.4,8.4,0,0,0-8.38,14.55Zm26.48,3a2.23,2.23,0,1,1-3,.82A2.24,2.24,0,0,1,293.4,62.26ZM272.23,50a2.23,2.23,0,1,1-3,.82A2.23,2.23,0,0,1,272.23,50Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <g>
            <path
              d="M143.73,363.24h0c5.88,1.56,7.11-3,13-1.39s4.7,6.13,10.58,7.73h0a8.4,8.4,0,0,0,4.34-16.22h0c-5.89-1.56-7.11,3-13,1.39s-4.7-6.13-10.58-7.73h0a8.39,8.39,0,0,0-4.33,16.22Zm26.37-3.92a2.24,2.24,0,1,1-2.73,1.58A2.24,2.24,0,0,1,170.1,359.32ZM146.48,353a2.24,2.24,0,1,1-2.73,1.58A2.24,2.24,0,0,1,146.48,353Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M228.31,46.63h0c5.89,1.57,7.11-3,13-1.38S246,51.38,251.92,53h0a8.4,8.4,0,0,0,4.33-16.23h0c-5.89-1.57-7.12,3-13,1.39s-4.7-6.13-10.57-7.73h0a8.4,8.4,0,0,0-4.34,16.23Zm26.37-3.92A2.23,2.23,0,1,1,252,44.29,2.23,2.23,0,0,1,254.68,42.71Zm-23.62-6.35a2.23,2.23,0,1,1-2.73,1.58A2.23,2.23,0,0,1,231.06,36.36Z"
              className="Badge__ThirdFillColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M150.74,244.15a30.37,30.37,0,1,1,30.37-30.37A30.41,30.41,0,0,1,150.74,244.15Zm0-58.19a27.83,27.83,0,1,0,27.83,27.82A27.85,27.85,0,0,0,150.74,186Z"
              className="Badge__SecondFillColor"
            />
            <path
              d="M150.74,246.69a32.91,32.91,0,1,1,32.91-32.91A32.95,32.95,0,0,1,150.74,246.69Zm0-62.59a29.69,29.69,0,1,0,29.69,29.68A29.71,29.71,0,0,0,150.74,184.1Z"
              className="Badge__ThirdFillColor"
            />
          </g>
          <path
            d="M191.93,210.18h0l-41.16-1.64h0a3.7,3.7,0,0,0-1.1,7.23h0l39.46,12.1h0a8.93,8.93,0,0,0,2.8.45,9.07,9.07,0,0,0,0-18.13Zm-38.08.07,33.22,1.32a9,9,0,0,0-2.25,13.32L153,215.14a3.66,3.66,0,0,0,.83-4.89Zm-5.22,2a2.12,2.12,0,0,1,2.11-2.11h0a2.11,2.11,0,1,1-2.12,2.11Zm43.27,14.48a7.48,7.48,0,1,1,7.47-7.48A7.48,7.48,0,0,1,191.9,226.72Z"
            className="Badge__SecondFillColor"
          />
          <g>
            <path
              d="M249.26,244.15a30.37,30.37,0,1,1,30.37-30.37A30.4,30.4,0,0,1,249.26,244.15Zm0-58.19a27.83,27.83,0,1,0,27.82,27.82A27.86,27.86,0,0,0,249.26,186Z"
              className="Badge__SecondFillColor"
            />
            <path
              d="M249.26,246.69a32.91,32.91,0,1,1,32.91-32.91A32.94,32.94,0,0,1,249.26,246.69Zm0-62.59a29.69,29.69,0,1,0,29.68,29.68A29.72,29.72,0,0,0,249.26,184.1Z"
              className="Badge__ThirdFillColor"
            />
            <path
              d="M252.16,212.24a2.91,2.91,0,1,1-2.9-2.91A2.9,2.9,0,0,1,252.16,212.24Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <path
            d="M240.21,172.59a1.27,1.27,0,0,1-.35-2.49,29.75,29.75,0,0,0,6.49-2.78c2.43-1.57,2.33-4.24,1.44-6.21-2.1-4.6-9.52-2.48-9.6-2.45a1.27,1.27,0,0,1-.72-2.44,17.53,17.53,0,0,1,5-.61c3.72.12,6.36,1.65,7.64,4.45,1.65,3.63.7,7.4-2.37,9.39a31.86,31.86,0,0,1-7.16,3.09A1.49,1.49,0,0,1,240.21,172.59Z"
            className="Badge__ThirdFillColor"
          />
          <path
            d="M250.4,211.69c-.33-.69-8.12-16.94-9.4-19.22-.82-1.48-6.49-18.68-10.6-31.43l7.81-2.39a1.26,1.26,0,0,0,.84-1.58,1.29,1.29,0,0,0-1.59-.85l-7.84,2.41a2.81,2.81,0,0,0-.1-.31,1.27,1.27,0,1,0-2.41.78c.33,1,1.48,4.61,2.94,9.1l-49.58,4.09-4.4-16a1.27,1.27,0,0,0-2.45.67l4.64,16.85a1.46,1.46,0,0,0,0,.21l-27.78,37a1.24,1.24,0,0,0-1,1,1.27,1.27,0,0,0,1,1.45l41.23,6.6.21,0,.2,0h0l0,0h.1l.09,0,.07,0,.07,0,.08,0,.07,0s0,0,0,0l.09-.08s0,0,0,0l39.89-43.11c2.65,8,5.34,15.95,6,17.12,1.23,2.21,9.24,18.91,9.32,19.08a1.29,1.29,0,0,0,1.15.72,1.23,1.23,0,0,0,.55-.13A1.26,1.26,0,0,0,250.4,211.69Zm-60.17,5.59-37.56-6,26.27-35Zm2.37-1-11.27-40.89,49.73-4.11.83,2.54Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M164.61,153.47c-.8.21-2.54,1.59-1.9,2.38s7.14,3.89,8.25,4,6.27-1.91,8.49-1.91,6,.87,7.69.48c1.35-.32,2.7-.71,2.38-1.74s-4-2.23-5-2.38-11.34-.24-12.93-.4S166.44,153,164.61,153.47Z"
            className="Badge__ThirdFillColor"
          />
          <path
            d="M207.43,221.54h-.12l-15.45-1.43a1.27,1.27,0,0,1,.24-2.52L207.54,219a1.27,1.27,0,0,1-.11,2.54Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M206.84,225.5a1.1,1.1,0,0,1-.31,0,1.27,1.27,0,0,1-.92-1.54l2.07-8.06a1.26,1.26,0,1,1,2.45.63l-2.06,8.06A1.26,1.26,0,0,1,206.84,225.5Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M153.64,212.24a2.9,2.9,0,1,1-2.9-2.91A2.9,2.9,0,0,1,153.64,212.24Z"
            className="Badge__SecondFillColor"
          />
        </g>
      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x=""
          y=""
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Anton"
          fontSize="1.8em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_13__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".79ex"
          textAnchor="middle"
          fontFamily="Anton"
          fontSize="1.8em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_13__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

    </svg>
  );
}

export default Badge;

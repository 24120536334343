import './styles.css';
import React from 'react';

function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`SimpleBadge Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <g className="MOUNTING">
        <ellipse
          cx="199.75"
          cy="9.47"
          rx="9.5"
          ry="9.47"
          className="Badge__FirstFillColor"
        />
        <polygon
          points="298.32 121.33 195.27 9.89 199.68 5.83 302.73 117.27 298.32 121.33"
          className="Badge__FirstFillColor"
        />
        <polygon
          points="101.68 121.33 204.73 9.89 200.32 5.83 97.27 117.27 101.68 121.33"
          className="Badge__FirstFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="RECTANGLE">
        <rect
          y="110.59"
          width="400"
          height="199.47"
          rx="20"
          ry="20"
          className="Badge__FirstFillColor"
        />
        <path
          d="M375,125.55a10,10,0,0,1,10,10v149.6a10,10,0,0,1-10,10H25a10,10,0,0,1-10-10V135.53a10,10,0,0,1,10-10H375m0-3H25a13,13,0,0,0-13,13v149.6a13,13,0,0,0,13,13H375a13,13,0,0,0,13-13V135.53a13,13,0,0,0-13-13Z"
          className="Badge__SecondFillColor"
        />
      </g>

      {/* ------------------------------------------ */}
      {/*
      <g className="STAR">
        <polygon
          points="210.44 158 203.37 156.82 199.93 150.53 196.61 156.87 189.56 158.19 194.58 163.31 193.66 170.4 200.08 167.22 206.57 170.28 205.51 163.2 210.44 158"
          className="Badge__SecondFillColor"
        />
      </g>
*/}
      {/* ------------------------------------------ */}

      <g className="SHINY">
        <g className="SHINY_RIGHT">
          <path
            d="M372.86,136s-.38,9.29-.65,23.24c-.15,7-.27,15.11-.35,23.82,0,4.36-.09,8.86-.09,13.44s0,9.22,0,13.87,0,9.29,0,13.87.07,9.08.1,13.43c.08,8.72.2,16.85.35,23.82.27,13.95.65,23.24.65,23.24s.38-9.29.65-23.24c.15-7,.27-15.1.35-23.82,0-4.35.09-8.86.1-13.43s0-9.23,0-13.87,0-9.3,0-13.87-.06-9.08-.09-13.44c-.08-8.71-.2-16.85-.35-23.82C373.24,145.25,372.86,136,372.86,136Z"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="326.01 212.27 367.75 211.19 367.75 213.36 326.01 212.27"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="335.09 225.95 367.75 219.67 367.75 221.86 335.09 225.95"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="339.27 236.88 367.75 227.58 367.75 229.81 339.27 236.88"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="344.77 247.73 367.75 235.59 367.75 237.99 344.77 247.73"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="348.07 259.63 367.75 244.79 367.75 247.23 348.07 259.63"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="355.2 268.45 367.75 256.11 367.75 258.46 355.2 268.45"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="361.88 277.17 367.75 269.37 367.75 271.49 361.88 277.17"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="335.09 198.53 367.75 204.68 367.75 202.53 335.09 198.53"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="339.27 187.86 367.75 196.94 367.75 194.76 339.27 187.86"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="344.77 177.24 367.75 189.11 367.75 186.76 344.77 177.24"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="348.07 165.6 367.75 180.13 367.75 177.73 348.07 165.6"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="355.2 156.98 367.75 169.04 367.75 166.75 355.2 156.98"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="361.88 148.46 367.75 156.08 367.75 154.01 361.88 148.46"
            className="Badge__ThirdFillColor"
          />
        </g>
      </g>

      <g className="SHINY">
        <g className="SHINY_LEFT">
          <path
            d="M27.14,136s.38,9.29.65,23.24c.15,7,.27,15.11.35,23.82,0,4.36.09,8.86.09,13.44s0,9.22.05,13.87,0,9.29,0,13.87-.07,9.08-.1,13.43c-.08,8.72-.2,16.85-.35,23.82-.27,13.95-.65,23.24-.65,23.24s-.38-9.29-.65-23.24c-.15-7-.27-15.1-.35-23.82,0-4.35-.09-8.86-.1-13.43s0-9.23,0-13.87,0-9.3,0-13.87.06-9.08.09-13.44c.08-8.71.2-16.85.35-23.82C26.76,145.25,27.14,136,27.14,136Z"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="73.99 212.27 32.25 211.19 32.25 213.36 73.99 212.27"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="64.91 225.95 32.25 219.67 32.25 221.86 64.91 225.95"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="60.73 236.88 32.25 227.58 32.25 229.81 60.73 236.88"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="55.23 247.73 32.25 235.59 32.25 237.99 55.23 247.73"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="51.93 259.63 32.25 244.79 32.25 247.23 51.93 259.63"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="44.8 268.45 32.25 256.11 32.25 258.46 44.8 268.45"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="38.12 277.17 32.25 269.37 32.25 271.49 38.12 277.17"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="64.91 198.53 32.25 204.68 32.25 202.53 64.91 198.53"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="60.73 187.86 32.25 196.94 32.25 194.76 60.73 187.86"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="55.23 177.24 32.25 189.11 32.25 186.76 55.23 177.24"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="51.93 165.6 32.25 180.13 32.25 177.73 51.93 165.6"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="44.8 156.98 32.25 169.04 32.25 166.75 44.8 156.98"
            className="Badge__ThirdFillColor"
          />
          <polygon
            points="38.12 148.46 32.25 156.08 32.25 154.01 38.12 148.46"
            className="Badge__ThirdFillColor"
          />
        </g>
      </g>

      {/* ------------------------------------------ */}

      {/*
        textAnchor="middle"
        dominantBaseline="central"
        */}

      <g className="TEXT">

        <text
          x="49%"
          y="48%"
          dy=".9ex"
          textAnchor="middle"
          fontFamily="Norican"
          fontSize="6em"
          letterSpacing="0em"
          className="Badge__SecondFillColor"
        >
          {line2}
        </text>

        <text
          x="50%"
          y="66%"
          textAnchor="middle"
          fontFamily="Rajdhani"
          fontSize="1.4em"
          fontWeight="700"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          {line3.toUpperCase()} {line4.toUpperCase()}
        </text>

      </g>

      {/* ------------------------------------------ */}

    </svg>
  );
}

export default Badge;

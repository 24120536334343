import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_2 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_2__TopCircle"
          d="M 73 200 A 50 50 0 0 1 323 200"
        />

        <path
          id="RadialBadge_2__BottomCircle"
          d="M 73 200 A 50 50 0 0 0 323 200"
        />
      </defs>

      {/* ------------------------------------------ */}

      <g className="CIRCLE">
        <circle cx="200" cy="200" r="200" className="Badge__FirstFillColor" />
      </g>

      {/* ------------------------------------------ */}

      <g className="STARS">
        <polygon
          points="323.02 196.83 320 190.72 316.98 196.83 310.23 197.81 315.12 202.56 313.97 209.28 320 206.12 326.04 209.28 324.88 202.56 329.77 197.81 323.02 196.83"
          className="Badge__ThirdFillColor"
        />
        <polygon
          points="83.02 196.83 80 190.72 76.98 196.83 70.23 197.81 75.12 202.56 73.97 209.28 80 206.12 86.04 209.28 84.88 202.56 89.77 197.81 83.02 196.83"
          className="Badge__ThirdFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="RADIAL_LINE_DOTS">
        <path
          d="M383.55,204.2l-9.07-.25a1,1,0,0,0-1.05,1,1,1,0,0,0,1,1.05l9.06.25h0a1,1,0,0,0,0-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M383.32,210.47l-9.05-.56a1,1,0,1,0-.13,2l9.05.56h.07a1,1,0,0,0,.06-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M382.88,216.72l-9-.87a1,1,0,0,0-.19,2l9,.86h.1a1,1,0,0,0,.1-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M382.18,223l-9-1.17a1,1,0,0,0-.26,2l9,1.17H382a1,1,0,0,0,.14-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M381.31,229.16l-8.95-1.48a1,1,0,0,0-.33,2l8.94,1.48h.17a1,1,0,0,0,.17-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M380.24,235.34l-8.89-1.78a1,1,0,1,0-.4,2l8.89,1.78a.68.68,0,0,0,.2,0,1,1,0,0,0,.2-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M378.9,241.47l-8.83-2.09a1,1,0,1,0-.47,2l8.83,2.09a.85.85,0,0,0,.23,0,1,1,0,0,0,.24-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M377.39,247.56l-8.75-2.4a1,1,0,0,0-.54,2l8.75,2.39a.84.84,0,0,0,.27,0,1,1,0,0,0,.27-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M375.71,253.6l-8.66-2.69a1,1,0,0,0-1.28.67,1,1,0,0,0,.67,1.27l8.66,2.7a1,1,0,0,0,1.28-.67A1,1,0,0,0,375.71,253.6Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M373.73,259.55l-8.56-3a1,1,0,1,0-.67,1.93l8.56,3a1,1,0,0,0,.34.06,1,1,0,0,0,.33-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M371.59,265.45l-8.45-3.28a1,1,0,1,0-.74,1.9l8.45,3.28a1,1,0,0,0,.74-1.9Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M369.29,271.28,361,267.71a1,1,0,0,0-.8,1.88l8.33,3.57a1.19,1.19,0,0,0,.41.08,1,1,0,0,0,.4-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M366.74,277l-8.21-3.85a1,1,0,1,0-.87,1.84l8.21,3.86a1.11,1.11,0,0,0,.43.09,1,1,0,0,0,.44-1.94Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M364,282.65l-8.07-4.14a1,1,0,1,0-.93,1.82l8.07,4.13a1,1,0,0,0,.46.12,1,1,0,0,0,.91-.56A1,1,0,0,0,364,282.65Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M361.08,288.2l-7.92-4.4a1,1,0,1,0-1,1.78l7.92,4.41a1.13,1.13,0,0,0,.5.13,1,1,0,0,0,.49-1.92Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M358,293.66,350.22,289a1,1,0,0,0-1.4.35,1,1,0,0,0,.35,1.4l7.77,4.68a1,1,0,0,0,.52.14,1,1,0,0,0,.53-1.89Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M354.67,299,347.06,294a1,1,0,1,0-1.11,1.71l7.61,4.94a1,1,0,0,0,.55.16,1,1,0,0,0,.56-1.87Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M343.76,299a1,1,0,0,0-1.17,1.68l7.44,5.19a1,1,0,0,0,.58.19,1,1,0,0,0,.84-.44,1,1,0,0,0-.25-1.42Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M340.32,303.88a1,1,0,0,0-1.42.2,1,1,0,0,0,.2,1.43l7.25,5.44a1,1,0,0,0,.61.21,1,1,0,0,0,.82-.41,1,1,0,0,0-.2-1.43Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M336.67,308.58a1,1,0,0,0-1.44.16,1,1,0,0,0,.16,1.43l7.06,5.69a1,1,0,0,0,1.44-.15,1,1,0,0,0-.16-1.44Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M332.87,313.18a1,1,0,0,0-1.34,1.54l6.87,5.93a1,1,0,0,0,.67.25,1,1,0,0,0,.77-.36,1,1,0,0,0-.11-1.44Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M328.94,317.66a1,1,0,0,0-1.39,1.5l6.67,6.15a1,1,0,0,0,.69.28,1,1,0,0,0,.69-1.77Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M324.85,322a1,1,0,0,0-1.44,0,1,1,0,0,0,0,1.44l6.44,6.38a1,1,0,0,0,.72.29,1,1,0,0,0,.72-1.74Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M320.59,326.17a1,1,0,1,0-1.48,1.41l6.23,6.59a1,1,0,0,0,.74.32,1.05,1.05,0,0,0,.7-.28,1,1,0,0,0,0-1.44Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M316.22,330.22a1,1,0,0,0-1.53,1.35l6,6.8a1,1,0,0,0,.76.35,1,1,0,0,0,.68-.26,1,1,0,0,0,.09-1.44Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M311.73,334.15a1,1,0,0,0-1.44-.14,1,1,0,0,0-.13,1.44l5.76,7a1,1,0,0,0,.79.37,1.05,1.05,0,0,0,.65-.23,1,1,0,0,0,.14-1.44Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M307.06,337.86a1,1,0,0,0-1.43-.19,1,1,0,0,0-.19,1.43l5.53,7.2a1,1,0,0,0,.81.39,1,1,0,0,0,.62-.21,1,1,0,0,0,.19-1.43Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M302.29,341.43a1,1,0,0,0-1.42-.24,1,1,0,0,0-.24,1.43l5.28,7.37a1,1,0,1,0,1.66-1.18Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M297.42,344.87a1,1,0,1,0-1.7,1.13l5,7.55a1,1,0,0,0,.85.45,1,1,0,0,0,.57-.17,1,1,0,0,0,.28-1.41Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M292.42,348.11a1,1,0,0,0-1.74,1.08l4.76,7.71a1,1,0,0,0,.87.49,1,1,0,0,0,.54-.15,1,1,0,0,0,.33-1.41Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M287.3,351.17a1,1,0,1,0-1.77,1l4.49,7.87a1,1,0,0,0,.89.52,1,1,0,0,0,.5-.14,1,1,0,0,0,.38-1.39Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M282.09,354.07a1,1,0,0,0-1.37-.43,1,1,0,0,0-.43,1.38l4.22,8a1,1,0,0,0,.91.54,1,1,0,0,0,.47-.11,1,1,0,0,0,.43-1.38Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M276.8,356.82a1,1,0,1,0-1.83.89l3.94,8.17a1,1,0,0,0,.92.57,1,1,0,0,0,.45-.1,1,1,0,0,0,.47-1.36Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M271.39,359.32a1,1,0,1,0-1.86.82l3.66,8.3a1,1,0,0,0,.93.61,1.06,1.06,0,0,0,.42-.09,1,1,0,0,0,.52-1.35Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M265.91,361.66a1,1,0,1,0-1.89.76l3.38,8.42a1,1,0,0,0,.94.64,1,1,0,0,0,.38-.08,1,1,0,0,0,.57-1.32Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M260.37,363.85a1,1,0,0,0-1.92.69l3.09,8.53a1,1,0,0,0,1.92-.69Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M254.73,365.79a1,1,0,0,0-1.94.63l2.79,8.63a1,1,0,0,0,1,.71,1.19,1.19,0,0,0,.32-.05,1,1,0,0,0,.66-1.29Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M249,367.55a1,1,0,1,0-2,.56l2.49,8.72a1,1,0,0,0,1,.74,1.35,1.35,0,0,0,.28,0,1,1,0,0,0,.7-1.27Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M243.3,369.14a1,1,0,0,0-1.24-.74,1,1,0,0,0-.74,1.24l2.19,8.8a1,1,0,0,0,1,.77l.25,0a1,1,0,0,0,.74-1.24Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M237.5,370.52a1,1,0,0,0-2,.42l1.89,8.87a1,1,0,0,0,1,.81.75.75,0,0,0,.21,0,1,1,0,0,0,.78-1.21Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M230.47,370.84a1,1,0,0,0-.83,1.18l1.58,8.93a1,1,0,0,0,1,.85l.17,0a1,1,0,0,0,.83-1.18l-1.58-8.93A1,1,0,0,0,230.47,370.84Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M224.62,371.79a1,1,0,0,0-.87,1.16l1.28,9a1,1,0,0,0,1,.88h.14a1,1,0,0,0,.87-1.15l-1.28-9A1,1,0,0,0,224.62,371.79Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M218.74,372.54a1,1,0,0,0-.91,1.12l1,9a1,1,0,0,0,1,.91h.11a1,1,0,0,0,.91-1.12l-1-9A1,1,0,0,0,218.74,372.54Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M212.83,373a1,1,0,0,0-.94,1.09l.66,9a1,1,0,0,0,1,.95h.07a1,1,0,0,0,1-1.1l-.66-9A1.06,1.06,0,0,0,212.83,373Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M206.92,373.39a1,1,0,0,0-1,1.05l.35,9.06a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1.06l-.35-9.06A1,1,0,0,0,206.92,373.39Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M201,373.53h0a1,1,0,0,0-1,1l.05,9.07a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l-.06-9.07A1,1,0,0,0,201,373.53Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M195.06,373.43a1.07,1.07,0,0,0-1.05,1l-.25,9.07a1,1,0,0,0,1,1.05h0a1,1,0,0,0,1-1l.25-9.07A1,1,0,0,0,195.06,373.43Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M189.14,373.19a1,1,0,0,0-1.08.95l-.56,9.05a1,1,0,0,0,.95,1.09h.07a1,1,0,0,0,1-1l.56-9.05A1,1,0,0,0,189.14,373.19Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M183.23,372.74a1,1,0,0,0-1.12.92l-.86,9a1,1,0,0,0,.92,1.12h.09a1,1,0,0,0,1-.92l.86-9A1,1,0,0,0,183.23,372.74Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M177.34,372a1,1,0,0,0-1.14.88l-1.17,9a1,1,0,0,0,.88,1.15H176a1,1,0,0,0,1-.88l1.17-9A1,1,0,0,0,177.34,372Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M171.48,371.19a1,1,0,0,0-1.17.84L168.83,381a1,1,0,0,0,.84,1.18h.16a1,1,0,0,0,1-.85l1.48-8.95A1,1,0,0,0,171.48,371.19Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M165.64,370.14a1,1,0,0,0-1.2.8l-1.78,8.9a1,1,0,0,0,.8,1.2l.2,0a1,1,0,0,0,1-.82l1.78-8.89A1,1,0,0,0,165.64,370.14Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M159.86,368.84a1,1,0,0,0-1.22.76l-2.09,8.83a1,1,0,0,0,.75,1.22,1,1,0,0,0,.24,0,1,1,0,0,0,1-.79l2.09-8.82A1,1,0,0,0,159.86,368.84Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M154.12,367.39a1,1,0,0,0-1.25.71l-2.39,8.75a1,1,0,0,0,.71,1.25.84.84,0,0,0,.27,0,1,1,0,0,0,1-.75l2.39-8.75A1,1,0,0,0,154.12,367.39Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M148.42,365.77a1,1,0,0,0-1.28.67l-2.69,8.66a1,1,0,0,0,.67,1.28,1.15,1.15,0,0,0,.31,0,1,1,0,0,0,1-.71l2.69-8.66A1,1,0,0,0,148.42,365.77Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M142.81,363.87a1,1,0,0,0-1.3.63l-3,8.56a1,1,0,0,0,.63,1.3,1,1,0,0,0,.33.06,1,1,0,0,0,1-.69l3-8.56A1,1,0,0,0,142.81,363.87Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M137.25,361.82a1,1,0,0,0-1.32.58l-3.28,8.45a1,1,0,0,0,.58,1.32,1,1,0,0,0,.37.07,1,1,0,0,0,1-.65l3.28-8.45A1,1,0,0,0,137.25,361.82Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M131.75,359.61a1,1,0,0,0-1.34.54l-3.56,8.33a1,1,0,0,0,.53,1.34,1,1,0,0,0,.4.09,1,1,0,0,0,.94-.62l3.57-8.34A1,1,0,0,0,131.75,359.61Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M126.35,357.17a1,1,0,0,0-1.35.49l-3.86,8.21a1,1,0,0,0,.49,1.36,1.12,1.12,0,0,0,.44.09,1,1,0,0,0,.92-.58l3.85-8.21A1,1,0,0,0,126.35,357.17Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M121,354.54a1,1,0,0,0-1.37.45l-4.13,8.07a1,1,0,0,0,.44,1.37,1,1,0,0,0,.46.11,1,1,0,0,0,.91-.55l4.14-8.07A1,1,0,0,0,121,354.54Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M115.81,351.77a1,1,0,0,0-1.39.4L110,360.09a1,1,0,0,0,.4,1.39,1,1,0,0,0,.49.13,1,1,0,0,0,.9-.53l4.4-7.92A1,1,0,0,0,115.81,351.77Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M110.67,348.82a1,1,0,0,0-1.4.34l-4.68,7.77a1,1,0,0,0,.88,1.55,1,1,0,0,0,.87-.49l4.68-7.77A1,1,0,0,0,110.67,348.82Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M105.66,345.65a1,1,0,0,0-1.41.3l-4.94,7.61a1,1,0,0,0,.3,1.41,1,1,0,0,0,.56.16,1,1,0,0,0,.85-.46l4.94-7.61A1,1,0,0,0,105.66,345.65Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M100.74,342.34a1,1,0,0,0-1.42.25L94.13,350a1,1,0,0,0,.25,1.42,1,1,0,0,0,.58.18,1,1,0,0,0,.84-.43l5.2-7.44A1,1,0,0,0,100.74,342.34Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M95.92,338.89a1,1,0,0,0-1.43.21l-5.44,7.25a1,1,0,0,0,.81,1.64,1,1,0,0,0,.82-.41l5.44-7.26A1,1,0,0,0,95.92,338.89Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M91.26,335.23a1,1,0,0,0-1.43.16l-5.69,7.06a1,1,0,0,0,.15,1.43,1,1,0,0,0,.64.23,1,1,0,0,0,.8-.38l5.69-7.06A1,1,0,0,0,91.26,335.23Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M86.72,331.43a1,1,0,0,0-1.44.1l-5.93,6.87a1,1,0,0,0,.11,1.44,1,1,0,0,0,1.44-.11l5.92-6.86A1,1,0,0,0,86.72,331.43Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M82.28,327.5a1,1,0,0,0-1.44.05l-6.16,6.67a1,1,0,0,0,.06,1.44,1,1,0,0,0,.69.27,1,1,0,0,0,.75-.33l6.16-6.66A1,1,0,0,0,82.28,327.5Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M78,323.41a1,1,0,0,0-1.44,0l-6.38,6.44a1,1,0,0,0,0,1.45,1,1,0,0,0,.72.29,1,1,0,0,0,.72-.3L78,324.85A1,1,0,0,0,78,323.41Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M73.87,319.15a1,1,0,0,0-1.45,0l-6.59,6.23a1,1,0,0,0,.7,1.76,1,1,0,0,0,.7-.28l6.6-6.23A1,1,0,0,0,73.87,319.15Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M69.87,314.78a1,1,0,0,0-1.44-.09l-6.8,6a1,1,0,0,0,.67,1.78,1,1,0,0,0,.68-.25l6.8-6A1,1,0,0,0,69.87,314.78Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M66,310.29a1,1,0,0,0-1.44-.14l-7,5.77a1,1,0,0,0-.14,1.44,1,1,0,0,0,1.44.14l7-5.77A1,1,0,0,0,66,310.29Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M62.33,305.63a1,1,0,0,0-1.44-.18L53.7,311a1,1,0,0,0,.63,1.83,1.05,1.05,0,0,0,.62-.21l7.19-5.53A1,1,0,0,0,62.33,305.63Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M58.8,300.87a1,1,0,0,0-1.42-.24L50,305.91a1,1,0,0,0,.59,1.85,1,1,0,0,0,.59-.19l7.38-5.28A1,1,0,0,0,58.8,300.87Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M55.42,296a1,1,0,0,0-1.42-.28l-7.55,5a1,1,0,0,0,.57,1.87,1,1,0,0,0,.56-.17l7.55-5A1,1,0,0,0,55.42,296Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M52.22,291a1,1,0,0,0-1.41-.33l-7.71,4.76a1,1,0,0,0,.53,1.89,1,1,0,0,0,.54-.15l7.72-4.76A1,1,0,0,0,52.22,291Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M49.21,285.91a1,1,0,0,0-1.39-.38L40,290a1,1,0,0,0,.5,1.91.94.94,0,0,0,.51-.14l7.87-4.49A1,1,0,0,0,49.21,285.91Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M46.36,280.72a1,1,0,0,0-1.38-.43l-8,4.22a1,1,0,0,0,.48,1.93,1,1,0,0,0,.47-.12l8-4.23A1,1,0,0,0,46.36,280.72Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M43.65,275.44a1,1,0,0,0-1.36-.47l-8.16,3.95a1,1,0,0,0,.44,1.93,1,1,0,0,0,.44-.1l8.17-3.94A1,1,0,0,0,43.65,275.44Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M41.2,270.05a1,1,0,0,0-1.34-.52l-8.3,3.66a1,1,0,0,0-.52,1.35,1,1,0,0,0,.93.6,1,1,0,0,0,.42-.08l8.29-3.67A1,1,0,0,0,41.2,270.05Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M38.91,264.59a1,1,0,0,0-1.33-.57l-8.42,3.38a1,1,0,0,0,.38,2,1,1,0,0,0,.38-.07l8.42-3.38A1,1,0,0,0,38.91,264.59Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M36.76,259.06a1,1,0,0,0-1.3-.61l-8.53,3.09a1,1,0,0,0-.61,1.3,1,1,0,0,0,1,.68.83.83,0,0,0,.34-.07l8.53-3.08A1,1,0,0,0,36.76,259.06Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M34.86,253.45a1,1,0,0,0-1.28-.66L25,255.58a1,1,0,0,0,.32,2,.89.89,0,0,0,.31-.06l8.63-2.79A1,1,0,0,0,34.86,253.45Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M33.15,247.78a1,1,0,0,0-1.26-.7l-8.72,2.49a1,1,0,0,0,.28,2,.86.86,0,0,0,.28,0L32.45,249A1,1,0,0,0,33.15,247.78Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M31.6,242.06a1,1,0,0,0-1.24-.74l-8.8,2.19a1,1,0,0,0,.25,2l.25,0,8.8-2.19A1,1,0,0,0,31.6,242.06Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M30.27,236.29a1,1,0,0,0-1.21-.79l-8.87,1.89a1,1,0,0,0,.21,2,.76.76,0,0,0,.21,0l8.87-1.88A1,1,0,0,0,30.27,236.29Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M28.33,231.65a1,1,0,0,0-.35-2l-8.93,1.58a1,1,0,0,0,.17,2l.18,0Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M27.34,225.77a1,1,0,1,0-.28-2l-9,1.28a1,1,0,0,0,.14,2h.14Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M17.54,220.83l9-1a1,1,0,0,0,.9-1.12,1,1,0,0,0-1.12-.91l-9,1a1,1,0,0,0,.11,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M17,214.59l9-.66a1,1,0,0,0,.94-1.1,1,1,0,0,0-1.09-.94l-9,.66a1,1,0,0,0,.07,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M16.57,208.33l9.07-.36a1,1,0,1,0-.08-2l-9.07.35a1,1,0,0,0,0,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M16.38,202.06h0l9.07-.05a1,1,0,0,0,0-2h0l-9.07.05a1,1,0,0,0,0,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M16.45,195.8l9.07.25h0a1,1,0,0,0,0-2l-9.06-.25a1.07,1.07,0,0,0-1.05,1A1,1,0,0,0,16.45,195.8Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M16.68,189.53l9.05.56h.07a1,1,0,0,0,1-1,1,1,0,0,0-.95-1.08l-9-.56a1,1,0,0,0-1.08.95A1,1,0,0,0,16.68,189.53Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M17.12,183.28l9,.87h.1a1,1,0,0,0,.09-2l-9-.86a1,1,0,1,0-.2,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M17.83,177.05l9,1.17H27a1,1,0,0,0,.13-2l-9-1.17a1,1,0,1,0-.26,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M18.69,170.84l9,1.48h.17a1,1,0,0,0,.16-2L19,168.82a1,1,0,0,0-1.18.85A1,1,0,0,0,18.69,170.84Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M19.76,164.66l8.89,1.78a.75.75,0,0,0,.21,0,1,1,0,0,0,.19-2l-8.89-1.78a1,1,0,1,0-.4,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M21.1,158.53l8.83,2.09.23,0a1,1,0,0,0,1-.79,1,1,0,0,0-.76-1.22l-8.83-2.09a1,1,0,0,0-1.22.75A1,1,0,0,0,21.1,158.53Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M22.61,152.44l8.75,2.4a1.24,1.24,0,0,0,.27,0,1,1,0,0,0,1-.75,1,1,0,0,0-.71-1.25l-8.75-2.39a1,1,0,1,0-.54,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M24.29,146.4,33,149.09a1.12,1.12,0,0,0,.31,0,1,1,0,0,0,1-.72,1,1,0,0,0-.67-1.27l-8.66-2.7a1,1,0,1,0-.61,2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M26.27,140.45l8.56,3a1,1,0,0,0,.34.05,1,1,0,0,0,.33-2l-8.56-3a1,1,0,0,0-.67,1.93Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M28.41,134.55l8.45,3.28a1,1,0,0,0,.37.07,1,1,0,0,0,.37-2l-8.45-3.28a1,1,0,0,0-1.32.58A1,1,0,0,0,28.41,134.55Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M30.71,128.72l8.34,3.57a1,1,0,0,0,.4.08,1,1,0,0,0,.4-2l-8.33-3.57a1,1,0,0,0-1.34.54A1,1,0,0,0,30.71,128.72Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M33.26,123l8.21,3.85a1,1,0,0,0,.44.1,1,1,0,0,0,.43-1.94l-8.21-3.86a1,1,0,0,0-1.35.49A1,1,0,0,0,33.26,123Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M36,117.35l8.07,4.14a1.12,1.12,0,0,0,.47.11,1,1,0,0,0,.46-1.93l-8.07-4.13a1,1,0,0,0-1.37.44A1,1,0,0,0,36,117.35Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M38.92,111.8l7.92,4.4a1,1,0,0,0,.5.13,1,1,0,0,0,.49-1.91L39.91,110a1,1,0,1,0-1,1.79Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M42,106.34,49.78,111a1,1,0,0,0,.53.14,1,1,0,0,0,.52-1.89l-7.77-4.68A1,1,0,0,0,42,106.34Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M45.33,101,52.94,106a1,1,0,1,0,1.11-1.71l-7.61-4.94A1,1,0,0,0,45.33,101Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M56.24,101a1,1,0,0,0,.58.18,1,1,0,0,0,.59-1.86L50,94.13A1,1,0,1,0,48.8,95.8Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M59.68,96.12a1,1,0,0,0,.61.21,1,1,0,0,0,.81-.41,1,1,0,0,0-.2-1.43l-7.25-5.44a1,1,0,1,0-1.23,1.63Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M63.33,91.42a1,1,0,0,0,.64.22,1,1,0,0,0,.64-1.81l-7.06-5.69a1,1,0,1,0-1.28,1.59Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M67.13,86.82a1,1,0,0,0,1.34-1.54L61.6,79.35a1,1,0,0,0-1.33,1.55Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M71.06,82.34a1,1,0,0,0,.69.27,1,1,0,0,0,.75-.33,1,1,0,0,0,0-1.44l-6.67-6.15a1,1,0,1,0-1.38,1.49Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M75.15,78a1,1,0,0,0,.72.29,1,1,0,0,0,.72-1.74l-6.45-6.38a1,1,0,1,0-1.44,1.45Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M79.41,73.83a1,1,0,0,0,.74.32,1.05,1.05,0,0,0,.7-.28,1,1,0,0,0,0-1.45l-6.23-6.59a1,1,0,1,0-1.48,1.4Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M83.78,69.78a1.05,1.05,0,0,0,.77.34,1,1,0,0,0,.67-.25,1,1,0,0,0,.09-1.44l-6-6.8a1,1,0,0,0-1.44-.09A1,1,0,0,0,77.78,63Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M88.27,65.85a1,1,0,0,0,.79.37,1,1,0,0,0,.65-.23,1,1,0,0,0,.13-1.44l-5.76-7a1,1,0,1,0-1.58,1.3Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M92.94,62.14a1,1,0,0,0,.81.4,1.05,1.05,0,0,0,.62-.21,1,1,0,0,0,.19-1.43L89,53.71A1,1,0,0,0,87.41,55Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M97.71,58.57a1,1,0,0,0,.83.42,1,1,0,0,0,.59-.19,1,1,0,0,0,.24-1.42L94.09,50a1,1,0,1,0-1.66,1.18Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M102.58,55.13a1,1,0,0,0,.85.46,1.08,1.08,0,0,0,.57-.17,1,1,0,0,0,.28-1.42l-5-7.55a1,1,0,0,0-1.7,1.13Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M107.58,51.89a1,1,0,0,0,.87.48,1.08,1.08,0,0,0,.54-.15,1,1,0,0,0,.33-1.41l-4.76-7.71a1,1,0,0,0-1.41-.33,1,1,0,0,0-.33,1.4Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M112.7,48.84a1,1,0,0,0,.89.51.93.93,0,0,0,.5-.14,1,1,0,0,0,.38-1.39L110,40a1,1,0,0,0-1.77,1Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M117.91,45.93a1,1,0,0,0,.9.54,1,1,0,0,0,.47-.11,1,1,0,0,0,.43-1.38l-4.22-8a1,1,0,0,0-1.81.95Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M123.2,43.18a1,1,0,0,0,1.36.47,1,1,0,0,0,.47-1.36l-3.94-8.16a1,1,0,1,0-1.84.88Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M128.61,40.68a1,1,0,0,0,.93.61,1,1,0,0,0,.41-.09,1,1,0,0,0,.53-1.34l-3.67-8.3a1,1,0,1,0-1.87.83Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M134.09,38.34A1,1,0,0,0,135,39a1,1,0,0,0,.38-.07,1,1,0,0,0,.57-1.33l-3.38-8.42a1,1,0,0,0-1.89.76Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M139.63,36.15a1,1,0,0,0,1,.67,1.07,1.07,0,0,0,.35-.06,1,1,0,0,0,.61-1.3l-3.09-8.53a1,1,0,0,0-1.92.69Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M145.27,34.21a1,1,0,0,0,1,.7.84.84,0,0,0,.31,0,1,1,0,0,0,.66-1.28L144.42,25a1,1,0,0,0-1.29-.66,1,1,0,0,0-.66,1.29Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M151,32.45a1,1,0,0,0,1,.74.9.9,0,0,0,.28,0,1,1,0,0,0,.7-1.26l-2.49-8.72a1,1,0,0,0-2,.56Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M156.7,30.86a1,1,0,0,0,1,.77,1.07,1.07,0,0,0,.25,0,1,1,0,0,0,.74-1.24l-2.19-8.8a1,1,0,0,0-1.24-.74,1,1,0,0,0-.74,1.24Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M163.5,30.29l.21,0a1,1,0,0,0,.79-1.21l-1.89-8.87a1,1,0,1,0-2,.42l1.88,8.87A1,1,0,0,0,163.5,30.29Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M169.53,29.16a1,1,0,0,0,.83-1.18l-1.58-8.93a1,1,0,0,0-1.18-.83,1,1,0,0,0-.83,1.18l1.58,8.93a1,1,0,0,0,1,.85Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M175.38,28.21a1,1,0,0,0,.87-1.15l-1.28-9a1,1,0,0,0-1.15-.87,1,1,0,0,0-.87,1.15l1.28,9a1,1,0,0,0,1,.88Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M182.17,26.34l-1-9a1,1,0,0,0-2,.22l1,9a1,1,0,0,0,1,.91h.11A1,1,0,0,0,182.17,26.34Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M188.11,25.87l-.66-9a1,1,0,0,0-1.09-.95,1,1,0,0,0-1,1.1l.67,9a1,1,0,0,0,1,.95h.08A1,1,0,0,0,188.11,25.87Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M194.06,25.56l-.35-9.07a1.06,1.06,0,0,0-1.06-1,1,1,0,0,0-1,1.06l.35,9.07a1,1,0,0,0,1,1h0A1,1,0,0,0,194.06,25.56Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M200,16.37a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l.06,9.07a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M206.24,16.51a1,1,0,0,0-1-1.05,1,1,0,0,0-1.05,1L204,25.52a1,1,0,0,0,1,1.05h0a1,1,0,0,0,1-1Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M212.5,16.81a1,1,0,0,0-.95-1.09,1,1,0,0,0-1.08,1l-.56,9.05a1,1,0,0,0,1,1.08h.07a1,1,0,0,0,1-1Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M218.75,17.32a1,1,0,0,0-.92-1.12,1,1,0,0,0-1.11.92l-.86,9a1,1,0,0,0,.91,1.11h.1a1,1,0,0,0,1-.93Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M225,18.09a1,1,0,0,0-.88-1.15,1.06,1.06,0,0,0-1.15.88l-1.17,9a1,1,0,0,0,.88,1.14h.13a1,1,0,0,0,1-.89Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M231.17,19a1,1,0,1,0-2-.34l-1.48,9a1,1,0,0,0,.84,1.17l.17,0a1,1,0,0,0,1-.86Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M237.34,20.16a1,1,0,0,0-2-.4l-1.78,8.89a1,1,0,0,0,.8,1.2.65.65,0,0,0,.2,0,1,1,0,0,0,1-.82Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M243.45,21.57a1,1,0,0,0-2-.47l-2.09,8.83a1,1,0,0,0,.76,1.23.91.91,0,0,0,.23,0,1,1,0,0,0,1-.79Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M249.52,23.15a1,1,0,0,0-.71-1.25,1,1,0,0,0-1.26.71l-2.39,8.75a1,1,0,0,0,.72,1.25.84.84,0,0,0,.27,0,1,1,0,0,0,1-.75Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M255.55,24.9a1,1,0,0,0-.68-1.28,1,1,0,0,0-1.27.67L250.91,33a1,1,0,0,0,.67,1.27,1,1,0,0,0,.3.05,1,1,0,0,0,1-.72Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M261.48,26.94a1,1,0,0,0-1.93-.67l-3,8.56a1,1,0,0,0,.63,1.3,1,1,0,0,0,.34.06,1,1,0,0,0,1-.69Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M267.35,29.15a1,1,0,0,0-.58-1.32,1,1,0,0,0-1.32.58l-3.28,8.45a1,1,0,0,0,.58,1.32.94.94,0,0,0,.37.07,1,1,0,0,0,.95-.65Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M268.25,40.39a.92.92,0,0,0,.4.08,1,1,0,0,0,.94-.62l3.57-8.33a1,1,0,0,0-.54-1.34,1,1,0,0,0-1.34.53l-3.57,8.34A1,1,0,0,0,268.25,40.39Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M273.65,42.83a.94.94,0,0,0,.43.1,1,1,0,0,0,.92-.59l3.86-8.21a1,1,0,1,0-1.85-.87l-3.85,8.21A1,1,0,0,0,273.65,42.83Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M279,45.46a1.11,1.11,0,0,0,.46.11,1,1,0,0,0,.91-.56l4.13-8.07a1,1,0,0,0-1.81-.93l-4.13,8.07A1,1,0,0,0,279,45.46Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M284.19,48.23a1,1,0,0,0,1.39-.4L290,39.91a1,1,0,1,0-1.78-1l-4.41,7.92A1,1,0,0,0,284.19,48.23Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M289.33,51.18a1,1,0,0,0,.53.15,1,1,0,0,0,.87-.5l4.68-7.77A1,1,0,0,0,293.66,42L289,49.78A1,1,0,0,0,289.33,51.18Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M294.34,54.35a1,1,0,0,0,1.41-.3l4.94-7.61A1,1,0,0,0,299,45.33L294,52.94A1,1,0,0,0,294.34,54.35Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M299.26,57.66a1,1,0,0,0,.58.18,1,1,0,0,0,.84-.43L305.87,50a1,1,0,1,0-1.67-1.17L299,56.24A1,1,0,0,0,299.26,57.66Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M304.08,61.1a1,1,0,0,0,.61.21,1,1,0,0,0,.82-.41L311,53.65a1,1,0,0,0-1.63-1.23l-5.44,7.26A1,1,0,0,0,304.08,61.1Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M308.74,64.77a1,1,0,0,0,.64.22,1,1,0,0,0,.79-.38l5.69-7.06a1,1,0,0,0-1.59-1.28l-5.69,7.06A1,1,0,0,0,308.74,64.77Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M313.28,68.57a1,1,0,0,0,1.44-.1l5.93-6.87a1,1,0,1,0-1.55-1.33l-5.92,6.86A1,1,0,0,0,313.28,68.57Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M317.72,72.5a1,1,0,0,0,1.44,0l6.16-6.67a1,1,0,1,0-1.5-1.38l-6.16,6.66A1,1,0,0,0,317.72,72.5Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M322,76.59a1,1,0,0,0,.72.3,1,1,0,0,0,.72-.31l6.38-6.44a1,1,0,1,0-1.45-1.44L322,75.15A1,1,0,0,0,322,76.59Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M326.13,80.85a1,1,0,0,0,.75.32,1,1,0,0,0,.7-.28l6.59-6.23a1,1,0,0,0-1.4-1.48l-6.6,6.23A1,1,0,0,0,326.13,80.85Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M330.13,85.22a1,1,0,0,0,.77.35,1,1,0,0,0,.67-.26l6.8-6a1,1,0,0,0,.09-1.44,1,1,0,0,0-1.44-.09l-6.8,6A1,1,0,0,0,330.13,85.22Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M334,89.71a1,1,0,0,0,.79.37,1.05,1.05,0,0,0,.65-.23l7-5.77a1,1,0,0,0-1.3-1.58l-7,5.77A1,1,0,0,0,334,89.71Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M337.67,94.37a1,1,0,0,0,.81.4,1.06,1.06,0,0,0,.63-.21L346.3,89a1,1,0,0,0-1.25-1.62l-7.19,5.53A1,1,0,0,0,337.67,94.37Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M341.2,99.13a1,1,0,0,0,1.42.24L350,94.09a1,1,0,0,0-1.18-1.66l-7.38,5.28A1,1,0,0,0,341.2,99.13Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M344.58,104a1,1,0,0,0,.85.45,1,1,0,0,0,.57-.17l7.55-5a1,1,0,0,0-1.13-1.7l-7.55,5A1,1,0,0,0,344.58,104Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M347.78,109a1,1,0,0,0,.87.48,1.08,1.08,0,0,0,.54-.15l7.71-4.76a1,1,0,0,0,.34-1.41,1,1,0,0,0-1.41-.33l-7.72,4.76A1,1,0,0,0,347.78,109Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M350.79,114.09a1,1,0,0,0,.88.52,1.06,1.06,0,0,0,.51-.14l7.87-4.49a1,1,0,0,0-1-1.77l-7.87,4.49A1,1,0,0,0,350.79,114.09Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M353.64,119.28a1,1,0,0,0,.91.55,1,1,0,0,0,.47-.12l8-4.22a1,1,0,0,0-.95-1.81l-8,4.23A1,1,0,0,0,353.64,119.28Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M356.35,124.56a1,1,0,0,0,1.36.47l8.16-3.94a1,1,0,0,0,.48-1.37,1,1,0,0,0-1.36-.47l-8.17,3.94A1,1,0,0,0,356.35,124.56Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M358.8,130a1,1,0,0,0,.93.61,1,1,0,0,0,.41-.09l8.3-3.66a1,1,0,0,0-.83-1.87l-8.29,3.67A1,1,0,0,0,358.8,130Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M361.09,135.41a1,1,0,0,0,1,.64,1,1,0,0,0,.38-.07l8.42-3.38a1,1,0,0,0-.76-1.89l-8.42,3.38A1,1,0,0,0,361.09,135.41Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M363.24,140.94a1,1,0,0,0,1.3.61l8.53-3.09a1,1,0,1,0-.69-1.91l-8.53,3.08A1,1,0,0,0,363.24,140.94Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M365.14,146.55a1,1,0,0,0,1,.71,1.12,1.12,0,0,0,.31,0l8.63-2.79a1,1,0,0,0,.66-1.29,1,1,0,0,0-1.29-.65l-8.63,2.79A1,1,0,0,0,365.14,146.55Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M366.85,152.22a1,1,0,0,0,1,.74,1,1,0,0,0,.28,0l8.72-2.49a1,1,0,0,0-.56-2L367.55,151A1,1,0,0,0,366.85,152.22Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M368.4,157.94a1,1,0,0,0,1,.77l.25,0,8.8-2.19a1,1,0,0,0,.74-1.23,1,1,0,0,0-1.24-.75l-8.8,2.19A1,1,0,0,0,368.4,157.94Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M369.73,163.71a1,1,0,0,0,1,.81.75.75,0,0,0,.21,0l8.87-1.89a1,1,0,1,0-.42-2l-8.87,1.88A1,1,0,0,0,369.73,163.71Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M371.67,168.35a1,1,0,0,0,.17,2l.18,0,8.93-1.58a1,1,0,0,0,.83-1.18,1,1,0,0,0-1.18-.83Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M372.66,174.23a1,1,0,0,0,.14,2h.14l9-1.28a1,1,0,1,0-.28-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M373.44,180.14a1,1,0,0,0,.11,2h.11l9-1a1,1,0,0,0-.22-2Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M374,186.07a1,1,0,0,0-.94,1.1,1,1,0,0,0,1,.94h.08l9-.66a1,1,0,0,0,.95-1.09,1.07,1.07,0,0,0-1.1-1Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M374.36,192a1,1,0,0,0-1,1.05,1,1,0,0,0,1,1h0l9.07-.35a1,1,0,0,0,1-1.06,1,1,0,0,0-1.05-1Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M383.62,197.94h0l-9.07.05a1,1,0,1,0,0,2h0l9.07-.05a1,1,0,0,0,0-2Z"
          className="Badge__SecondFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="CIRCLE_OUTLINE">
        <path
          d="M200,357.53c-86.86,0-157.53-70.67-157.53-157.53S113.14,42.47,200,42.47,357.53,113.14,357.53,200,286.86,357.53,200,357.53Zm0-313.14C114.2,44.39,44.39,114.2,44.39,200S114.2,355.61,200,355.61,355.61,285.8,355.61,200,285.8,44.39,200,44.39Z"
          className="Badge__SecondFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="SHINY">
        <g className="SHINY_TOP">
          <polygon
            points="202.38 98.47 201.05 150.18 203.72 150.18 202.38 98.47"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="219.12 109.73 211.43 150.18 214.12 150.18 219.12 109.73"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="232.5 114.9 221.12 150.18 223.85 150.18 232.5 114.9"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="245.78 121.72 230.91 150.18 233.86 150.18 245.78 121.72"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="260.35 125.8 242.18 150.18 245.17 150.18 260.35 125.8"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="271.14 134.64 256.04 150.18 258.92 150.18 271.14 134.64"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="281.81 142.91 272.27 150.18 274.86 150.18 281.81 142.91"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="185.57 109.73 193.09 150.18 190.46 150.18 185.57 109.73"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="172.5 114.9 183.62 150.18 180.94 150.18 172.5 114.9"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="159.5 121.72 174.04 150.18 171.16 150.18 159.5 121.72"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="145.26 125.8 163.04 150.18 160.1 150.18 145.26 125.8"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="134.71 134.64 149.47 150.18 146.66 150.18 134.71 134.64"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="124.28 142.91 133.6 150.18 131.08 150.18 124.28 142.91"
            className="Badge__SecondFillColor"
          />
        </g>
        <g className="SHINY_BOTTOM">
          <polygon
            points="202.38 301.53 201.05 250.44 203.72 250.44 202.38 301.53"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="219.12 290.42 211.43 250.44 214.12 250.44 219.12 290.42"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="232.5 285.3 221.12 250.44 223.85 250.44 232.5 285.3"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="245.78 278.56 230.91 250.44 233.86 250.44 245.78 278.56"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="260.35 274.53 242.18 250.44 245.17 250.44 260.35 274.53"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="271.14 265.79 256.04 250.44 258.92 250.44 271.14 265.79"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="281.81 257.62 272.27 250.44 274.86 250.44 281.81 257.62"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="185.57 290.42 193.09 250.44 190.46 250.44 185.57 290.42"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="172.5 285.3 183.62 250.44 180.94 250.44 172.5 285.3"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="159.5 278.56 174.04 250.44 171.16 250.44 159.5 278.56"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="145.26 274.53 163.04 250.44 160.1 250.44 145.26 274.53"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="134.71 265.79 149.47 250.44 146.66 250.44 134.71 265.79"
            className="Badge__SecondFillColor"
          />
          <polygon
            points="124.28 257.62 133.6 250.44 131.08 250.44 124.28 257.62"
            className="Badge__SecondFillColor"
          />
        </g>
        <g className="SHINY_TOP_LINE">
          <path
            d="M109,156.51s11.38-.47,28.44-.81c8.54-.18,18.5-.32,29.16-.43,5.34,0,10.84-.11,16.44-.12l17-.05,17,.05c5.6,0,11.11.09,16.44.12,10.67.1,20.62.25,29.16.43,17.06.33,28.44.81,28.44.81s-11.38.47-28.44.8c-8.54.19-18.49.34-29.16.44-5.33,0-10.84.12-16.44.12l-17,0-17,0c-5.6,0-11.1-.08-16.44-.13-10.66-.1-20.62-.24-29.16-.43C120.36,157,109,156.51,109,156.51Z"
            className="Badge__SecondFillColor"
          />
        </g>
        <g className="SHINY_BOTTOM_LINE">
          <path
            d="M109,244.19s11.38.46,28.44.79c8.54.18,18.5.33,29.16.42,5.34,0,10.84.12,16.44.12l17,.06,17-.05c5.6,0,11.11-.09,16.44-.12,10.67-.1,20.62-.25,29.16-.43,17.06-.33,28.44-.79,28.44-.79s-11.38-.47-28.44-.81c-8.54-.17-18.49-.32-29.16-.42-5.33,0-10.84-.11-16.44-.12l-17,0-17,0c-5.6,0-11.1.08-16.44.11-10.66.1-20.62.25-29.16.43C120.36,243.72,109,244.19,109,244.19Z"
            className="Badge__SecondFillColor"
          />
        </g>
      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x="50%"
          y="50%"
          dy=".6ex"
          textAnchor="middle"
          fontFamily="Miriam Libre"
          fontSize="3.5em"
          fontWeight="700"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          {line2.toUpperCase()}
        </text>

        <text
          x=""
          y=""
          dy=".6ex"
          textAnchor="middle"
          fontFamily="Teko"
          fontSize="2.1em"
          fontWeight="300"
          letterSpacing="0.08em"
          className="Badge__ThirdFillColor"
        >
          <textPath xlinkHref="#RadialBadge_2__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".65ex"
          textAnchor="middle"
          fontFamily="Teko"
          fontSize="2.1em"
          fontWeight="300"
          letterSpacing="0.08em"
          className="Badge__ThirdFillColor"
        >
          <textPath xlinkHref="#RadialBadge_2__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

      {/* ------------------------------------------ */}

    </svg>
  );
}

export default Badge;

import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  /* eslint-disable jsx-a11y/accessible-emoji */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_3 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_3__TopCircle"
          d="M 60 210 A 50 50 0 0 1 340 210"
        />

        <path
          id="RadialBadge_3__BottomCircle"
          d="M 70 210 A 50 50 0 0 0 330 210"
        />
      </defs>

      {/* ------------------------------------------ */}

      <path
        d="M397.68,158.2c-1.43-6.83-8.5-12.1-10.62-18.65s.46-15-2.35-21.33-10.85-10-14.3-16-2.68-14.8-6.75-20.38S351,74.29,346.35,69.15s-5.67-13.9-10.82-18.54-14-4.75-19.56-8.83-8.41-12.42-14.42-15.9-14.63-1.74-21-4.57-10.8-10.39-17.42-12.54-14.64,1.35-21.46-.08S229,.78,222,.07s-14,4.38-21,4.38-14.2-5.1-21-4.38-12.94,7.19-19.66,8.62-14.91-2-21.46.08-11.14,9.73-17.42,12.54-15,1.12-21,4.57-8.83,11.83-14.42,15.9S71.66,46,66.52,50.61,60.33,64,55.7,69.15,42.46,76.23,38.38,81.83s-3.27,14.38-6.74,20.38-11.47,9.66-14.3,16-.2,14.72-2.35,21.34S5.8,151.37,4.35,158.2s2.9,14.4,2.17,21.35S0,193,0,200s5.82,13.63,6.52,20.45S2.94,235.09,4.35,241.8,12.86,253.9,15,260.45s-.46,15,2.35,21.34,10.84,10,14.3,16,2.67,14.79,6.74,20.38,12.68,7.55,17.31,12.69,5.67,13.9,10.82,18.54,14,4.75,19.56,8.82,8.41,12.43,14.42,15.91,14.63,1.74,21,4.57,10.79,10.39,17.42,12.54,14.64-1.35,21.46.09,12.71,7.89,19.66,8.61,14-4.38,21-4.38,14.2,5.1,21,4.38,12.94-7.19,19.66-8.61,14.92,2,21.46-.09,11.12-9.73,17.41-12.54,15-1.12,21-4.57,8.83-11.83,14.41-15.9,14.43-4.2,19.57-8.83,6.18-13.4,10.81-18.54,13.24-7.08,17.32-12.68,3.27-14.37,6.75-20.39,11.46-9.65,14.29-16,.2-14.72,2.36-21.34,9.19-11.82,10.62-18.65-2.89-14.4-2.17-21.35S402,207,402,200s-5.82-13.63-6.53-20.45S399.11,164.91,397.68,158.2Z"
        className="Badge__FirstFillColor"
      />
      {/*
      <path
          d="M201,370.48C107,370.48,30.54,294,30.54,200S107,29.52,201,29.52,371.5,106,371.5,200,295,370.48,201,370.48ZM201,31.6c-92.85,0-168.4,75.55-168.4,168.4S108.17,368.4,201,368.4s168.4-75.55,168.4-168.4S293.87,31.6,201,31.6Z"
          className="Badge__ThirdFillColor"
      />
*/}
      <path
        d="M201,381.33C101,381.33,19.69,300,19.69,200S101,18.67,201,18.67,382.35,100,382.35,200,301,381.33,201,381.33Zm0-360.45C102.25,20.88,21.9,101.23,21.9,200S102.25,379.12,201,379.12,380.14,298.77,380.14,200,299.79,20.88,201,20.88Z"
        className="Badge__ThirdFillColor"
      />

      {/* ------------------------------------------ */}

      <g id="TEETH">
        <path
          d="M379.43,204.08l-8.81-.24a.92.92,0,0,0-1,1,1,1,0,0,0,1,1l8.81.25h0a1,1,0,0,0,0-2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M379.21,210.17l-8.8-.54a1,1,0,0,0-.12,2l8.8.54h.06a1,1,0,0,0,.06-2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M378.78,216.25l-8.78-.84a1,1,0,0,0-1.08.89,1,1,0,0,0,.89,1.09l8.78.84h.1a1,1,0,0,0,1-.9A1,1,0,0,0,378.78,216.25Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M378.1,222.31l-8.75-1.14a1,1,0,0,0-1.11.85,1,1,0,0,0,.86,1.12l8.74,1.14H378a1,1,0,0,0,.13-2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M377.25,228.34l-8.7-1.43a1,1,0,0,0-1.14.82,1,1,0,0,0,.82,1.14l8.7,1.43.16,0a1,1,0,0,0,1-.83A1,1,0,0,0,377.25,228.34Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M376.21,234.35l-8.64-1.73a1,1,0,1,0-.39,1.94l8.64,1.74.2,0a1,1,0,0,0,.19-2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M374.91,240.31l-8.58-2a1,1,0,0,0-1.19.73,1,1,0,0,0,.73,1.2l8.58,2a.9.9,0,0,0,.23,0,1,1,0,0,0,.23-1.95Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M373.44,246.23l-8.5-2.33a1,1,0,1,0-.52,1.91l8.5,2.33a1.09,1.09,0,0,0,.26,0,1,1,0,0,0,.26-1.94Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M371.81,252.1l-8.42-2.62a1,1,0,0,0-1.24.65,1,1,0,0,0,.65,1.25l8.42,2.61a1,1,0,0,0,.29,0,1,1,0,0,0,.3-1.94Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M369.89,257.89,361.57,255a1,1,0,1,0-.66,1.87l8.32,2.91a1,1,0,0,0,.33.05,1,1,0,0,0,.94-.66A1,1,0,0,0,369.89,257.89Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M367.81,263.61l-8.22-3.19a1,1,0,0,0-.72,1.86l8.22,3.19a1.13,1.13,0,0,0,.36.06,1,1,0,0,0,.92-.63A1,1,0,0,0,367.81,263.61Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M365.57,269.28l-8.1-3.47a1,1,0,0,0-.79,1.83l8.11,3.47a1,1,0,0,0,.39.08,1,1,0,0,0,.39-1.91Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M363.09,274.85l-8-3.74a1,1,0,0,0-.85,1.79l8,3.75a1,1,0,0,0,.42.09,1,1,0,0,0,.89-.57A1,1,0,0,0,363.09,274.85Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M360.42,280.33l-7.85-4a1,1,0,0,0-1.33.43,1,1,0,0,0,.43,1.33l7.85,4a1,1,0,0,0,.9-1.77Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M357.6,285.73l-7.71-4.28a1,1,0,1,0-1,1.74l7.7,4.28a1,1,0,0,0,.48.12,1,1,0,0,0,.87-.51A1,1,0,0,0,357.6,285.73Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M354.59,291,347,286.49a1,1,0,1,0-1,1.7l7.55,4.55a1.06,1.06,0,0,0,.51.14,1,1,0,0,0,.85-.48A1,1,0,0,0,354.59,291Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M351.36,296.2l-7.4-4.79a1,1,0,0,0-1.08,1.66l7.4,4.8a1,1,0,0,0,.54.16,1,1,0,0,0,.54-1.83Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M340.76,296.23a1,1,0,0,0-1.38.25,1,1,0,0,0,.24,1.38l7.23,5.05a1,1,0,0,0,1.38-.25,1,1,0,0,0-.25-1.38Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M337.42,301a1,1,0,0,0-1.19,1.59l7,5.29a1,1,0,0,0,1.38-.2,1,1,0,0,0-.19-1.39Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M333.86,305.54a1,1,0,0,0-1.39.16,1,1,0,0,0,.15,1.39l6.86,5.53a1.07,1.07,0,0,0,.62.22,1,1,0,0,0,.78-.37,1,1,0,0,0-.15-1.4Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M330.17,310a1,1,0,1,0-1.3,1.5l6.68,5.76a1,1,0,0,0,.64.24,1,1,0,0,0,.76-.34,1,1,0,0,0-.11-1.4Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M326.35,314.37a1,1,0,0,0-1.35,1.45l6.48,6a1,1,0,0,0,1.4-.06,1,1,0,0,0-.06-1.4Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M322.38,318.58A1,1,0,1,0,321,320l6.27,6.19a1,1,0,0,0,.7.29,1,1,0,0,0,.69-1.7Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M318.24,322.64a1,1,0,0,0-1.45,1.37l6.06,6.4a1,1,0,0,0,.72.31,1,1,0,0,0,.68-.27,1,1,0,0,0,0-1.4Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M314,326.58a1,1,0,1,0-1.48,1.31l5.83,6.61a1,1,0,0,0,.74.34,1,1,0,0,0,.66-.25,1,1,0,0,0,.09-1.4Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M309.62,330.4a1,1,0,1,0-1.53,1.26l5.61,6.8a1,1,0,0,0,.76.36,1,1,0,0,0,.77-1.62Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M305.09,334a1,1,0,0,0-1.58,1.21l5.37,7a1,1,0,0,0,.79.39,1,1,0,0,0,.6-.21,1,1,0,0,0,.19-1.39Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M300.45,337.47a1,1,0,1,0-1.62,1.16l5.13,7.16a1,1,0,0,0,1.62-1.15Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M295.71,340.81a1,1,0,0,0-1.37-.28,1,1,0,0,0-.28,1.38l4.88,7.34a1,1,0,0,0,.83.44,1,1,0,0,0,.82-1.54Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M290.85,344a1,1,0,0,0-1.36-.33,1,1,0,0,0-.33,1.37l4.63,7.5a1,1,0,0,0,.85.47.94.94,0,0,0,.52-.15,1,1,0,0,0,.32-1.36Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M285.87,346.94a1,1,0,0,0-1.35-.38,1,1,0,0,0-.37,1.36l4.37,7.65a1,1,0,0,0,1.36.37,1,1,0,0,0,.36-1.35Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M280.82,349.76a1,1,0,0,0-1.76.92l4.11,7.8a1,1,0,0,0,1.34.42,1,1,0,0,0,.41-1.34Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M275.67,352.43a1,1,0,1,0-1.78.86l3.84,7.94a1,1,0,0,0,.89.56,1,1,0,0,0,.43-.1,1,1,0,0,0,.46-1.32Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M270.41,354.86a1,1,0,1,0-1.81.8l3.56,8.06a1,1,0,0,0,.91.59,1,1,0,0,0,.4-.08,1,1,0,0,0,.51-1.31Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M265.09,357.13a1,1,0,0,0-1.29-.55,1,1,0,0,0-.55,1.29l3.28,8.18a1,1,0,0,0,.92.63,1.21,1.21,0,0,0,.37-.07,1,1,0,0,0,.55-1.3Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M259.7,359.26a1,1,0,0,0-1.87.68l3,8.29a1,1,0,0,0,.94.65,1,1,0,0,0,.33-.06,1,1,0,0,0,.6-1.27Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M254.22,361.15a1,1,0,0,0-1.25-.64,1,1,0,0,0-.64,1.25l2.72,8.39a1,1,0,0,0,.94.69,1.18,1.18,0,0,0,.31,0,1,1,0,0,0,.63-1.25Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M248.69,362.85a1,1,0,0,0-1.23-.68,1,1,0,0,0-.68,1.23l2.42,8.48a1,1,0,0,0,1,.72,1.36,1.36,0,0,0,.27,0,1,1,0,0,0,.68-1.23Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M243.11,364.41a1,1,0,0,0-1.21-.72,1,1,0,0,0-.72,1.2l2.13,8.55a1,1,0,0,0,1,.75l.24,0a1,1,0,0,0,.72-1.2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M237.47,365.74a1,1,0,0,0-1.18-.76,1,1,0,0,0-.76,1.18l1.83,8.62a1,1,0,0,0,1,.79l.21,0a1,1,0,0,0,.76-1.17Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M230.63,366.06a1,1,0,0,0-.8,1.15l1.54,8.68a1,1,0,0,0,1,.82l.18,0a1,1,0,0,0,.8-1.15l-1.54-8.68A1,1,0,0,0,230.63,366.06Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M225,367a1,1,0,0,0-.84,1.12l1.24,8.73a1,1,0,0,0,1,.85h.14a1,1,0,0,0,.84-1.12l-1.24-8.73A1,1,0,0,0,225,367Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M219.24,367.71a1,1,0,0,0-.88,1.09l.94,8.76a1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.09l-.94-8.76A1,1,0,0,0,219.24,367.71Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M213.49,368.19a1,1,0,0,0-.91,1.07l.64,8.79a1,1,0,0,0,1,.92h.07a1,1,0,0,0,.92-1.06l-.64-8.79A1,1,0,0,0,213.49,368.19Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M207.74,368.53a1,1,0,0,0-1,1l.35,8.81a1,1,0,0,0,1,.95h0a1,1,0,0,0,1-1l-.35-8.81A1,1,0,0,0,207.74,368.53Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M202,368.68h0a1,1,0,0,0-1,1l0,8.82a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l-.05-8.82A1,1,0,0,0,202,368.68Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M196.22,368.58a1,1,0,0,0-1,1l-.25,8.81a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.24-8.81A1,1,0,0,0,196.22,368.58Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M190.46,368.34a1,1,0,0,0-1.05.93l-.54,8.8a1,1,0,0,0,.93,1.05h.06a1,1,0,0,0,1-.93l.54-8.8A1,1,0,0,0,190.46,368.34Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M184.72,367.9a1,1,0,0,0-1.09.89l-.84,8.78a1,1,0,0,0,.9,1.08h.09a1,1,0,0,0,1-.9l.84-8.78A1,1,0,0,0,184.72,367.9Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M179,367.22a1,1,0,0,0-1.12.86l-1.14,8.74a1,1,0,0,0,.86,1.11h.13a1,1,0,0,0,1-.86l1.14-8.74A1,1,0,0,0,179,367.22Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M173.3,366.39a1,1,0,0,0-1.14.82l-1.44,8.7a1,1,0,0,0,.82,1.14h.16a1,1,0,0,0,1-.83l1.44-8.7A1,1,0,0,0,173.3,366.39Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M167.63,365.38a1,1,0,0,0-1.17.78l-1.74,8.64a1,1,0,0,0,.78,1.17l.2,0a1,1,0,0,0,1-.8l1.73-8.64A1,1,0,0,0,167.63,365.38Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M162,364.12a1,1,0,0,0-1.2.73l-2,8.58a1,1,0,0,0,.74,1.19.91.91,0,0,0,.23,0,1,1,0,0,0,1-.76l2-8.58A1,1,0,0,0,162,364.12Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M156.43,362.7a1,1,0,0,0-1.22.69l-2.33,8.51a1,1,0,0,0,.7,1.21.78.78,0,0,0,.26,0,1,1,0,0,0,1-.73l2.32-8.5A1,1,0,0,0,156.43,362.7Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M150.89,361.13a1,1,0,0,0-1.25.65L147,370.2a1,1,0,0,0,.65,1.24,1,1,0,0,0,.3,0,1,1,0,0,0,.94-.69l2.62-8.42A1,1,0,0,0,150.89,361.13Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M145.43,359.28a1,1,0,0,0-1.26.61l-2.91,8.32a1,1,0,0,0,.61,1.27,1,1,0,0,0,.33,0,1,1,0,0,0,.94-.66l2.9-8.32A1,1,0,0,0,145.43,359.28Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M140,357.29a1,1,0,0,0-1.28.56l-3.19,8.22a1,1,0,0,0,.56,1.28.92.92,0,0,0,.36.07,1,1,0,0,0,.93-.63l3.18-8.22A1,1,0,0,0,140,357.29Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M134.69,355.14a1,1,0,0,0-1.31.52l-3.47,8.11a1,1,0,0,0,.52,1.3,1.1,1.1,0,0,0,.4.08,1,1,0,0,0,.91-.6l3.47-8.11A1,1,0,0,0,134.69,355.14Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M129.44,352.77a1,1,0,0,0-1.32.48l-3.75,8a1,1,0,0,0,.48,1.31.9.9,0,0,0,.42.1,1,1,0,0,0,.9-.57l3.74-8A1,1,0,0,0,129.44,352.77Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M124.27,350.22a1,1,0,0,0-1.33.43l-4,7.84a1,1,0,0,0,.43,1.34,1.06,1.06,0,0,0,.45.11,1,1,0,0,0,.89-.54l4-7.85A1,1,0,0,0,124.27,350.22Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M119.18,347.52a1,1,0,0,0-1.35.39l-4.28,7.7a1,1,0,0,0,.39,1.35,1,1,0,0,0,.48.12,1,1,0,0,0,.87-.51l4.28-7.7A1,1,0,0,0,119.18,347.52Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M114.19,344.65a1,1,0,0,0-1.36.34l-4.55,7.55a1,1,0,0,0,.34,1.36,1,1,0,0,0,.51.15,1,1,0,0,0,.85-.48l4.55-7.56A1,1,0,0,0,114.19,344.65Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M109.32,341.57a1,1,0,0,0-1.37.29l-4.8,7.4a1,1,0,0,0,1.66,1.08l4.8-7.4A1,1,0,0,0,109.32,341.57Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M104.54,338.36a1,1,0,0,0-1.38.24l-5,7.23a1,1,0,0,0,.25,1.38,1,1,0,0,0,1.38-.25l5.05-7.22A1,1,0,0,0,104.54,338.36Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M99.85,335a1,1,0,0,0-1.38.19l-5.29,7.05a1,1,0,0,0,.19,1.39,1,1,0,0,0,.6.2,1,1,0,0,0,.79-.39l5.29-7.05A1,1,0,0,0,99.85,335Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M95.33,331.45a1,1,0,0,0-1.4.15l-5.53,6.86a1,1,0,0,0,.15,1.4,1.07,1.07,0,0,0,.62.22,1,1,0,0,0,.78-.37l5.53-6.87A1,1,0,0,0,95.33,331.45Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M90.91,327.75a1,1,0,0,0-1.4.1l-5.76,6.68a1,1,0,0,0,.1,1.39,1,1,0,0,0,1.4-.1L91,329.15A1,1,0,0,0,90.91,327.75Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M86.6,323.93a1,1,0,0,0-1.4.05l-6,6.48a1,1,0,0,0,.06,1.4,1,1,0,0,0,.67.26,1,1,0,0,0,.73-.31l6-6.48A1,1,0,0,0,86.6,323.93Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M82.43,320A1,1,0,0,0,81,320l-6.19,6.27a1,1,0,0,0,0,1.4,1,1,0,0,0,.7.29,1,1,0,0,0,.71-.3l6.19-6.27A1,1,0,0,0,82.43,320Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M78.42,315.81a1,1,0,0,0-1.4,0l-6.41,6.06a1,1,0,0,0,0,1.4,1,1,0,0,0,.72.31,1,1,0,0,0,.68-.27l6.41-6A1,1,0,0,0,78.42,315.81Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M74.53,311.56a1,1,0,0,0-1.4-.08l-6.61,5.83a1,1,0,0,0-.09,1.4,1,1,0,0,0,.75.34,1,1,0,0,0,.65-.25L74.44,313A1,1,0,0,0,74.53,311.56Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M70.76,307.21a1,1,0,0,0-1.4-.14l-6.8,5.6a1,1,0,0,0-.13,1.4,1,1,0,0,0,.76.36,1,1,0,0,0,.63-.22l6.81-5.61A1,1,0,0,0,70.76,307.21Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M67.2,302.67a1,1,0,0,0-1.39-.18l-7,5.37a1,1,0,0,0-.18,1.4,1,1,0,0,0,.79.38,1,1,0,0,0,.6-.2l7-5.38A1,1,0,0,0,67.2,302.67Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M63.78,298a1,1,0,0,0-1.39-.23l-7.16,5.13a1,1,0,0,0-.23,1.39,1,1,0,0,0,.8.41,1,1,0,0,0,.58-.18l7.17-5.13A1,1,0,0,0,63.78,298Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M60.49,293.32a1,1,0,0,0-1.38-.28l-7.34,4.88a1,1,0,0,0-.27,1.38,1,1,0,0,0,.82.44.94.94,0,0,0,.55-.17l7.34-4.88A1,1,0,0,0,60.49,293.32Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M57.38,288.47a1,1,0,0,0-1.37-.33l-7.5,4.63a1,1,0,0,0,.52,1.84,1,1,0,0,0,.52-.15l7.5-4.63A1,1,0,0,0,57.38,288.47Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M54.46,283.5a1,1,0,0,0-1.36-.37l-7.65,4.37a1,1,0,0,0,.49,1.86,1.05,1.05,0,0,0,.49-.13l7.66-4.38A1,1,0,0,0,54.46,283.5Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M51.68,278.46a1,1,0,0,0-1.34-.42l-7.8,4.11a1,1,0,0,0-.42,1.34A1,1,0,0,0,43,284a1,1,0,0,0,.46-.12l7.8-4.1A1,1,0,0,0,51.68,278.46Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M49.05,273.33a1,1,0,0,0-1.32-.46l-7.94,3.84a1,1,0,0,0,.43,1.88.91.91,0,0,0,.43-.1l7.94-3.84A1,1,0,0,0,49.05,273.33Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M46.67,268.09a1,1,0,0,0-1.31-.51l-8.06,3.56a1,1,0,0,0,.4,1.9,1.14,1.14,0,0,0,.4-.08l8.06-3.56A1,1,0,0,0,46.67,268.09Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M44.44,262.78a1,1,0,0,0-1.29-.55L35,265.51a1,1,0,0,0-.55,1.29,1,1,0,0,0,.92.62,1,1,0,0,0,.37-.07l8.18-3.28A1,1,0,0,0,44.44,262.78Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M42.35,257.41a1,1,0,0,0-1.27-.6l-8.29,3a1,1,0,0,0,.34,1.93,1,1,0,0,0,.34-.06l8.29-3A1,1,0,0,0,42.35,257.41Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M40.51,252a1,1,0,0,0-1.25-.64L30.87,254a1,1,0,0,0,.31,1.93.79.79,0,0,0,.3-.05l8.39-2.71A1,1,0,0,0,40.51,252Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M38.85,246.44a1,1,0,0,0-1.23-.68l-8.48,2.42a1,1,0,0,0,.28,1.95,1.26,1.26,0,0,0,.27,0l8.47-2.42A1,1,0,0,0,38.85,246.44Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M37.33,240.88a1,1,0,0,0-1.2-.72l-8.55,2.13a1,1,0,0,0,.24,2,1,1,0,0,0,.24,0l8.55-2.13A1,1,0,0,0,37.33,240.88Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M36,235.27a1,1,0,0,0-1.18-.76l-8.62,1.83a1,1,0,0,0,.21,2,.68.68,0,0,0,.2,0l8.63-1.83A1,1,0,0,0,36,235.27Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M34.16,230.76a1,1,0,0,0-.35-1.95l-8.68,1.54a1,1,0,0,0,.18,2l.17,0Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M33.2,225.05a1,1,0,1,0-.28-2l-8.73,1.24a1,1,0,0,0,.14,2h.14Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M23.67,220.25l8.76-.94a1,1,0,0,0-.21-2l-8.76.95a1,1,0,0,0,.1,2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M23.12,214.18l8.79-.64a1,1,0,0,0,.92-1.07,1,1,0,0,0-1.06-.91L23,212.2a1,1,0,0,0,.07,2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M22.73,208.1l8.81-.35a1,1,0,0,0-.08-2l-8.81.35a1,1,0,0,0,0,2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M22.54,202h0l8.82,0a1,1,0,0,0,0-2h0l-8.82.05a1,1,0,0,0,0,2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M22.61,195.92l8.81.24h0a1,1,0,0,0,0-2l-8.81-.25a1,1,0,0,0-1,1A1,1,0,0,0,22.61,195.92Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M22.83,189.83l8.8.54h.06a1,1,0,0,0,.06-2L23,187.85a1,1,0,0,0-1.06.93A1,1,0,0,0,22.83,189.83Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M23.26,183.75l8.78.84h.09a1,1,0,0,0,1-.89,1,1,0,0,0-.89-1.09l-8.78-.84a1,1,0,0,0-.19,2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M24,177.69l8.74,1.14h.13a1,1,0,0,0,.12-2l-8.74-1.14a1,1,0,0,0-1.11.86A1,1,0,0,0,24,177.69Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M24.79,171.66l8.7,1.43.16,0a1,1,0,0,0,.16-2l-8.7-1.44a1,1,0,0,0-1.14.81A1,1,0,0,0,24.79,171.66Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M25.83,165.65l8.64,1.73a.68.68,0,0,0,.2,0,1,1,0,0,0,.19-2l-8.64-1.74a1,1,0,1,0-.39,2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M27.13,159.69l8.58,2a.91.91,0,0,0,.23,0,1,1,0,0,0,1-.76,1,1,0,0,0-.73-1.2l-8.58-2a1,1,0,0,0-.46,1.93Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M28.6,153.77l8.5,2.33a.84.84,0,0,0,.27,0,1,1,0,0,0,.26-1.95l-8.51-2.33a1,1,0,0,0-1.22.7A1,1,0,0,0,28.6,153.77Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M30.23,147.9l8.42,2.62a1,1,0,0,0,.3,0,1,1,0,0,0,.94-.69,1,1,0,0,0-.65-1.25L30.82,146a1,1,0,1,0-.59,1.89Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M32.15,142.11,40.48,145a.87.87,0,0,0,.32.06,1,1,0,0,0,.94-.67,1,1,0,0,0-.61-1.26l-8.32-2.91a1,1,0,1,0-.66,1.87Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M34.23,136.39l8.22,3.19a1.13,1.13,0,0,0,.36.06,1,1,0,0,0,.92-.63,1,1,0,0,0-.56-1.29L35,134.53a1,1,0,0,0-.72,1.86Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M36.47,130.72l8.11,3.47a1,1,0,0,0,.39.08,1,1,0,0,0,.39-1.91l-8.11-3.47a1,1,0,0,0-.78,1.83Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M39,125.15l8,3.74a.92.92,0,0,0,.42.1,1,1,0,0,0,.43-1.89l-8-3.75a1,1,0,0,0-.85,1.8Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M41.62,119.67l7.85,4a.91.91,0,0,0,.45.11,1,1,0,0,0,.88-.54,1,1,0,0,0-.43-1.33l-7.84-4a1,1,0,1,0-.91,1.77Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M44.45,114.27l7.7,4.28a1,1,0,0,0,.48.12,1,1,0,0,0,.48-1.86l-7.7-4.28a1,1,0,0,0-1,1.74Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M47.46,109,55,113.51a.94.94,0,0,0,.51.14,1,1,0,0,0,.51-1.84l-7.55-4.55a1,1,0,0,0-1,1.7Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M50.68,103.8l7.4,4.79a1,1,0,0,0,.54.16,1,1,0,0,0,.54-1.82l-7.4-4.8a1,1,0,0,0-1.37.29A1,1,0,0,0,50.68,103.8Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M61.28,103.77a1,1,0,0,0,.57.18,1,1,0,0,0,.57-1.81l-7.23-5a1,1,0,1,0-1.13,1.63Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M64.62,99a1,1,0,0,0,.6.2,1,1,0,0,0,.6-1.78l-7-5.3a1,1,0,1,0-1.19,1.59Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M68.18,94.46a1,1,0,0,0,.62.22,1,1,0,0,0,.62-1.77l-6.86-5.53a1,1,0,1,0-1.25,1.55Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M71.87,90a1,1,0,0,0,.65.24,1,1,0,0,0,.75-.34,1,1,0,0,0-.1-1.4l-6.68-5.76a1,1,0,0,0-1.29,1.5Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M75.69,85.63a1,1,0,0,0,.67.26,1,1,0,0,0,.73-.31,1,1,0,0,0,0-1.41l-6.48-6a1,1,0,1,0-1.34,1.46Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M79.67,81.41a1,1,0,0,0,.69.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-6.27-6.19a1,1,0,1,0-1.39,1.41Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M83.8,77.36A1,1,0,0,0,85.25,76l-6.06-6.41A1,1,0,0,0,77.75,71Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M88.06,73.42a1,1,0,1,0,1.48-1.31L83.71,65.5a1,1,0,1,0-1.49,1.31Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M92.42,69.61a1,1,0,0,0,.77.36,1,1,0,0,0,.62-.23,1,1,0,0,0,.14-1.4l-5.61-6.8a1,1,0,0,0-1.53,1.26Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M97,66a1,1,0,0,0,.79.39,1,1,0,0,0,.6-.21,1,1,0,0,0,.19-1.39l-5.37-7A1,1,0,0,0,91.58,59Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M101.59,62.53a1,1,0,0,0,.81.41,1,1,0,0,0,.58-.18,1,1,0,0,0,.23-1.39l-5.13-7.16A1,1,0,0,0,96.69,54a1,1,0,0,0-.23,1.38Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M106.33,59.19a1,1,0,0,0,.83.44.94.94,0,0,0,.55-.17,1,1,0,0,0,.27-1.37l-4.88-7.34a1,1,0,0,0-1.38-.28,1,1,0,0,0-.27,1.38Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M111.19,56a1,1,0,0,0,1.36.33,1,1,0,0,0,.33-1.37l-4.63-7.5a1,1,0,0,0-1.69,1Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M116.17,53.07a1,1,0,0,0,1.35.37,1,1,0,0,0,.37-1.36l-4.37-7.65a1,1,0,1,0-1.72,1Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M121.22,50.24a1,1,0,0,0,.88.53.92.92,0,0,0,.46-.11,1,1,0,0,0,.42-1.34l-4.11-7.8a1,1,0,1,0-1.75.92Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M126.37,47.57a1,1,0,0,0,.89.56,1,1,0,0,0,.43-.1,1,1,0,0,0,.46-1.32l-3.83-7.94a1,1,0,0,0-1.33-.46,1,1,0,0,0-.46,1.32Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M131.63,45.14a1,1,0,0,0,.91.59,1,1,0,0,0,.4-.08,1,1,0,0,0,.5-1.31l-3.56-8.06a1,1,0,0,0-1.82.8Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M137,42.87a1,1,0,0,0,.92.62.94.94,0,0,0,.37-.07,1,1,0,0,0,.55-1.29L135.51,34a1,1,0,0,0-1.84.73Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M142.34,40.74a1,1,0,0,0,.93.65,1,1,0,0,0,.34-.06,1,1,0,0,0,.6-1.27l-3-8.29a1,1,0,0,0-1.87.68Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M147.82,38.85a1,1,0,0,0,.94.69,1.12,1.12,0,0,0,.31,0,1,1,0,0,0,.64-1.25L147,29.85a1,1,0,1,0-1.88.61Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M153.35,37.14a1,1,0,0,0,1,.72,1.24,1.24,0,0,0,.27,0,1,1,0,0,0,.68-1.23l-2.42-8.48a1,1,0,1,0-1.91.55Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M158.94,35.59a1,1,0,0,0,1,.75.66.66,0,0,0,.24,0,1,1,0,0,0,.72-1.2l-2.13-8.55a1,1,0,0,0-1.92.48Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M165.54,35l.21,0a1,1,0,0,0,.76-1.18l-1.83-8.62a1,1,0,0,0-1.94.41l1.83,8.63A1,1,0,0,0,165.54,35Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M171.41,33.94a1,1,0,0,0,.8-1.15l-1.54-8.68a1,1,0,0,0-1.95.35l1.54,8.68a1,1,0,0,0,1,.82Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M177.09,33a1,1,0,0,0,.84-1.12l-1.24-8.73a1,1,0,0,0-1.12-.84,1,1,0,0,0-.84,1.12L176,32.18a1,1,0,0,0,1,.85Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M183.69,31.2l-.95-8.76a1,1,0,1,0-2,.21l.94,8.76a1,1,0,0,0,1,.89h.11A1,1,0,0,0,183.69,31.2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M189.46,30.75,188.82,22a1,1,0,0,0-1.06-.92,1,1,0,0,0-.92,1.06l.65,8.79a1,1,0,0,0,1,.92h.08A1,1,0,0,0,189.46,30.75Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M195.25,30.44l-.35-8.81a1,1,0,0,0-1-.95,1,1,0,0,0-1,1l.35,8.81a1,1,0,0,0,1,.95h0A1,1,0,0,0,195.25,30.44Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M201,21.51a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l.05,8.82a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M207.09,21.65a1,1,0,0,0-1-1,1,1,0,0,0-1,1l-.25,8.81a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M213.17,21.94a1,1,0,1,0-2-.13l-.54,8.8a1,1,0,0,0,.93,1.05h.06a1,1,0,0,0,1-.93Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M219.25,22.43a1,1,0,0,0-.89-1.08,1,1,0,0,0-1.09.89L216.43,31a1,1,0,0,0,.89,1.08h.1a1,1,0,0,0,1-.89Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M225.3,23.18a1,1,0,0,0-.86-1.11,1,1,0,0,0-1.11.86l-1.14,8.74a1,1,0,0,0,.85,1.11h.13a1,1,0,0,0,1-.87Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M231.32,24.09a1,1,0,0,0-.81-1.14,1,1,0,0,0-1.14.82l-1.44,8.7a1,1,0,0,0,.81,1.14h.17a1,1,0,0,0,1-.83Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M237.32,25.2a1,1,0,0,0-1.95-.39l-1.73,8.64a1,1,0,0,0,.78,1.17l.19,0a1,1,0,0,0,1-.8Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M243.26,26.57a1,1,0,0,0-.74-1.19,1,1,0,0,0-1.19.73l-2,8.58a1,1,0,0,0,.73,1.19l.23,0a1,1,0,0,0,1-.76Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M249.16,28.1a1,1,0,0,0-1.92-.52l-2.32,8.5a1,1,0,0,0,.69,1.22,1.26,1.26,0,0,0,.27,0,1,1,0,0,0,1-.73Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M255,29.8a1,1,0,0,0-.65-1.24,1,1,0,0,0-1.24.65l-2.62,8.42a1,1,0,0,0,.66,1.24.93.93,0,0,0,.29.05,1,1,0,0,0,1-.7Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M260.78,31.79a1,1,0,1,0-1.87-.66L256,39.45a1,1,0,0,0,.61,1.27,1,1,0,0,0,.33.05,1,1,0,0,0,.93-.66Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M266.48,33.93a1,1,0,0,0-1.84-.72l-3.19,8.22a1,1,0,0,0,.56,1.28.92.92,0,0,0,.36.07,1,1,0,0,0,.92-.63Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M267.36,44.86a1,1,0,0,0,.39.08,1,1,0,0,0,.91-.6l3.47-8.11a1,1,0,0,0-.52-1.3,1,1,0,0,0-1.3.52l-3.47,8.11A1,1,0,0,0,267.36,44.86Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M272.6,47.23a1.08,1.08,0,0,0,.43.1,1,1,0,0,0,.89-.58l3.75-8a1,1,0,0,0-1.8-.85l-3.74,8A1,1,0,0,0,272.6,47.23Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M277.77,49.78a.91.91,0,0,0,.45.11,1,1,0,0,0,.88-.54l4-7.84a1,1,0,0,0-1.77-.91l-4,7.85A1,1,0,0,0,277.77,49.78Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M282.86,52.48a1,1,0,0,0,.48.12,1,1,0,0,0,.86-.51l4.29-7.7a1,1,0,1,0-1.73-1l-4.29,7.7A1,1,0,0,0,282.86,52.48Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M287.85,55.35a1,1,0,0,0,.51.14,1,1,0,0,0,.85-.48l4.55-7.55a1,1,0,1,0-1.7-1L287.51,54A1,1,0,0,0,287.85,55.35Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M292.72,58.43a1,1,0,0,0,.54.16,1,1,0,0,0,.83-.45l4.8-7.4a1,1,0,1,0-1.66-1.08l-4.8,7.4A1,1,0,0,0,292.72,58.43Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M297.5,61.64a.91.91,0,0,0,.56.18,1,1,0,0,0,.82-.42l5.05-7.23A1,1,0,1,0,302.3,53l-5.05,7.22A1,1,0,0,0,297.5,61.64Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M302.19,65a.92.92,0,0,0,.59.2,1,1,0,0,0,.79-.39l5.3-7.06a1,1,0,0,0-.2-1.38,1,1,0,0,0-1.39.19L302,63.61A1,1,0,0,0,302.19,65Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M306.72,68.55a1,1,0,0,0,1.39-.15l5.53-6.86a1,1,0,1,0-1.55-1.25l-5.53,6.87A1,1,0,0,0,306.72,68.55Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M311.13,72.25a1,1,0,0,0,.65.24,1,1,0,0,0,.75-.34l5.76-6.68a1,1,0,0,0-1.5-1.29L311,70.85A1,1,0,0,0,311.13,72.25Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M315.44,76.07a1,1,0,0,0,.68.27,1,1,0,0,0,.73-.32l6-6.48a1,1,0,0,0-1.46-1.34l-6,6.47A1,1,0,0,0,315.44,76.07Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M319.62,80.05a1,1,0,0,0,.69.28A1,1,0,0,0,321,80l6.19-6.27a1,1,0,0,0,0-1.4,1,1,0,0,0-1.41,0l-6.19,6.27A1,1,0,0,0,319.62,80.05Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M323.62,84.19a1,1,0,0,0,.72.31,1,1,0,0,0,.68-.27l6.41-6.06a1,1,0,1,0-1.36-1.44l-6.41,6A1,1,0,0,0,323.62,84.19Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M327.51,88.44a1,1,0,0,0,.75.33,1,1,0,0,0,.65-.25l6.61-5.83a1,1,0,0,0-1.31-1.49L327.6,87A1,1,0,0,0,327.51,88.44Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M331.28,92.79a1,1,0,0,0,.77.37,1,1,0,0,0,.63-.23l6.8-5.61a1,1,0,0,0-1.26-1.53l-6.8,5.61A1,1,0,0,0,331.28,92.79Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M334.84,97.33a1,1,0,0,0,.79.38,1,1,0,0,0,.6-.2l7-5.37A1,1,0,0,0,342,90.56l-7,5.37A1,1,0,0,0,334.84,97.33Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M338.26,102a1,1,0,0,0,.81.41,1,1,0,0,0,.58-.18l7.17-5.13a1,1,0,0,0,.22-1.39,1,1,0,0,0-1.38-.23l-7.17,5.13A1,1,0,0,0,338.26,102Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M341.56,106.68a1,1,0,0,0,.82.45,1,1,0,0,0,.55-.17l7.34-4.88a1,1,0,1,0-1.1-1.65l-7.34,4.88A1,1,0,0,0,341.56,106.68Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M344.67,111.53a1,1,0,0,0,1.36.33l7.5-4.63a1,1,0,0,0-1-1.69l-7.5,4.63A1,1,0,0,0,344.67,111.53Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M347.58,116.5a1,1,0,0,0,1.36.37l7.65-4.37a1,1,0,0,0-1-1.73L348,115.15A1,1,0,0,0,347.58,116.5Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M350.36,121.54a1,1,0,0,0,.88.53,1,1,0,0,0,.46-.11l7.8-4.11a1,1,0,1,0-.92-1.75l-7.8,4.1A1,1,0,0,0,350.36,121.54Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M353,126.67a1,1,0,0,0,.89.56.91.91,0,0,0,.43-.1l7.94-3.83a1,1,0,0,0-.86-1.79l-7.94,3.84A1,1,0,0,0,353,126.67Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M355.37,131.92a1,1,0,0,0,.91.59,1,1,0,0,0,.4-.09l8.06-3.56a1,1,0,0,0,.51-1.31,1,1,0,0,0-1.31-.5l-8.06,3.56A1,1,0,0,0,355.37,131.92Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M357.6,137.22a1,1,0,0,0,.92.62.94.94,0,0,0,.37-.07l8.18-3.28a1,1,0,0,0-.73-1.84l-8.19,3.28A1,1,0,0,0,357.6,137.22Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M359.69,142.59a1,1,0,0,0,.93.66,1,1,0,0,0,.34-.06l8.29-3a1,1,0,0,0,.59-1.27,1,1,0,0,0-1.27-.6l-8.29,3A1,1,0,0,0,359.69,142.59Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M361.53,148.05a1,1,0,0,0,1,.68,1,1,0,0,0,.3,0l8.39-2.72a1,1,0,0,0,.64-1.25,1,1,0,0,0-1.25-.63l-8.39,2.71A1,1,0,0,0,361.53,148.05Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M363.2,153.56a1,1,0,0,0,.95.72.84.84,0,0,0,.27,0l8.48-2.42a1,1,0,0,0-.55-1.91l-8.47,2.42A1,1,0,0,0,363.2,153.56Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M364.71,159.12a1,1,0,0,0,1,.75l.24,0,8.55-2.13a1,1,0,1,0-.48-1.92l-8.55,2.13A1,1,0,0,0,364.71,159.12Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M366,164.73a1,1,0,0,0,1,.78.75.75,0,0,0,.21,0l8.62-1.83a1,1,0,0,0-.41-1.94l-8.63,1.83A1,1,0,0,0,366,164.73Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M367.88,169.24a1,1,0,0,0,.17,2l.18,0,8.68-1.54a1,1,0,0,0-.35-2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M368.84,175a1,1,0,0,0,.14,2h.14l8.73-1.24a1,1,0,0,0-.28-2Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M369.61,180.69a1,1,0,0,0-.88,1.1,1,1,0,0,0,1,.88h.11l8.76-.95a1,1,0,0,0,.88-1.09,1,1,0,0,0-1.09-.88Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M370.13,186.46a1,1,0,0,0,.07,2h.07l8.8-.64a1,1,0,0,0,.91-1.06,1,1,0,0,0-1.06-.92Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M370.5,192.25a1,1,0,0,0,0,2h0l8.81-.35a1,1,0,0,0,.95-1,1,1,0,0,0-1-.95Z"
          className="Badge__ThirdFillColor"
        />
        <path
          d="M379.5,198h0l-8.82,0a1,1,0,0,0,0,2h0l8.82-.05a1,1,0,0,0,0-2Z"
          className="Badge__ThirdFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x="50%"
          y="50%"
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Leckerli One"
          fontSize="6.5em"
          letterSpacing="0em"
          transform="translate(-5,-2)"
          className="Badge__ThirdFillColor"
        >
          {line2}
        </text>

        <text
          x="50%"
          y="50%"
          dy=".6ex"
          textAnchor="middle"
          fontFamily="Miriam Libre"
          fontSize="1.4em"
          fontWeight="700"
          letterSpacing="0.05em"
          transform="translate(40,-49)"
          className="Badge__SecondFillColor"
        >
          {line1.toUpperCase()}
        </text>
        {/**/}
        <text
          x="50%"
          y="51%"
          dy=".6ex"
          textAnchor="middle"
          fontFamily="Miriam Libre"
          fontSize="1.2em"
          fontWeight="700"
          letterSpacing="0.05em"
          transform="translate(0,60)"
          className="Badge__ThirdFillColor"
        >
          {line3.toUpperCase()} {line4.toUpperCase()}
        </text>
        {/**/}
        <text
          x=""
          y=""
          dy=".6ex"
          textAnchor="middle"
          fontFamily="Abel"
          fontSize="2em"
          letterSpacing="0.05em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_3__TopCircle" startOffset="50%">
            😛 😛 😛 😛 😛
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".6ex"
          textAnchor="middle"
          fontFamily="Abel"
          fontSize="2em"
          letterSpacing="0.05em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_3__BottomCircle" startOffset="50%">
            😛 😛 😛 😛 😛
          </textPath>
        </text>

      </g>

      {/* ------------------------------------------ */}

    </svg>
  );
}

export default Badge;

import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_14 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_14__TopCircle"
          d="M 52 200 A 50 50 0 0 1 348 200"
        />

        <path
          id="RadialBadge_14__BottomCircle"
          d="M 60 200 A 50 50 0 0 0 340 200"
        />
      </defs>

      {/* ------------------------------------------ */}

      <g>
        <rect width="400" height="400" opacity="0" />
        <g>
          <circle
            cx="200"
            cy="200"
            r="185.31"
            transform="translate(-82.84 200) rotate(-45)"
            className="Badge__FirstFillColor"
          />
          <circle
            cx="200"
            cy="200"
            r="118.73"
            className="Badge__SecondFillColor"
          />
          <circle
            cx="200"
            cy="200"
            r="107.64"
            fill="none"
            className="Badge__FirstStrokeColor"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <rect
            y="142.67"
            width="400"
            height="114.66"
            className="Badge__FirstFillColor"
          />
          <circle
            cx="200"
            cy="200"
            r="172.34"
            fill="none"
            className="Badge__SecondStrokeColor"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <polygon
            points="200 105.94 204.21 114.47 213.63 115.84 206.81 122.48 208.42 131.85 200 127.43 191.58 131.85 193.19 122.48 186.37 115.84 195.79 114.47 200 105.94"
            className="Badge__FirstFillColor"
          />
          <polygon
            points="170 115.07 172.54 120.22 178.23 121.05 174.12 125.06 175.09 130.72 170 128.05 164.91 130.72 165.88 125.06 161.77 121.05 167.46 120.22 170 115.07"
            className="Badge__FirstFillColor"
          />
          <polygon
            points="230 115.07 232.54 120.22 238.23 121.05 234.12 125.06 235.09 130.72 230 128.05 224.91 130.72 225.88 125.06 221.77 121.05 227.46 120.22 230 115.07"
            className="Badge__FirstFillColor"
          />
          <polygon
            points="170 267.07 172.54 272.22 178.23 273.05 174.12 277.06 175.09 282.72 170 280.05 164.91 282.72 165.88 277.06 161.77 273.05 167.46 272.22 170 267.07"
            className="Badge__FirstFillColor"
          />
          <polygon
            points="230 267.07 232.54 272.22 238.23 273.05 234.12 277.06 235.09 282.72 230 280.05 224.91 282.72 225.88 277.06 221.77 273.05 227.46 272.22 230 267.07"
            className="Badge__FirstFillColor"
          />
          <polygon
            points="200 265.94 204.21 274.47 213.63 275.84 206.81 282.48 208.42 291.85 200 287.43 191.58 291.85 193.19 282.48 186.37 275.84 195.79 274.47 200 265.94"
            className="Badge__FirstFillColor"
          />
        </g>
      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x="50%"
          y="48%"
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Anton"
          fontSize="4em"
          letterSpacing="0.4em"
          className="Badge__SecondFillColor"
        >
          {line2.toUpperCase()}
        </text>

        <text
          x=""
          y=""
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Abel"
          fontSize="1.8em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_14__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".79ex"
          textAnchor="middle"
          fontFamily="Abel"
          fontSize="1.8em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_14__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

    </svg>
  );
}

export default Badge;

import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_5 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_5__TopCircle"
          d="M 60 200 A 50 50 0 0 1 340 200"
        />

        <path
          id="RadialBadge_5__BottomCircle"
          d="M 60 200 A 50 50 0 0 0 340 200"
        />
      </defs>

      {/* ------------------------------------------ */}

      <circle cx="200" cy="200" r="200" className="Badge__FirstFillColor" />

      {/* ------------------------------------------ */}

      <g className="OUTLINE">
        <path
          d="M265.17,22.1a27.23,27.23,0,0,1,7.46,13,179.62,179.62,0,0,1,24.13,13c-1.93-11.39-11.42-17-11.52-17.1l.07-.12A187.14,187.14,0,0,0,265.17,22.1Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M233.36,13.49A27.14,27.14,0,0,1,242.93,25a178,178,0,0,1,26,8.53c-3.88-10.88-14.2-14.8-14.3-14.83l0-.13A186.13,186.13,0,0,0,233.36,13.49Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M200.61,10.55a27.21,27.21,0,0,1,11.32,9.68l0,0A178.9,178.9,0,0,1,239,24.11C233.29,14.05,222.43,12,222.32,12l0-.09A192.37,192.37,0,0,0,200.61,10.55Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M120.47,38.23l-.09.19A179.25,179.25,0,0,1,146,28.12c-9.38-5.58-19.22-2.7-20.5-2.29a190.83,190.83,0,0,0-19.36,9.66A27.17,27.17,0,0,1,120.47,38.23Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M149.75,26.92l-.06.08a178.85,178.85,0,0,1,26.9-5.64c-8.81-7.57-19.79-5.79-19.91-5.77v0a185.7,185.7,0,0,0-20.75,6.16A27.1,27.1,0,0,1,149.75,26.92Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M167.76,13.3a27.15,27.15,0,0,1,12.79,7.56l-.05,0A182,182,0,0,1,200,19.84c2.66,0,5.31.07,7.94.19-7.36-9-18.47-9.13-18.59-9.13v-.05A189.16,189.16,0,0,0,167.76,13.3Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M93.61,54.46l-.09.31A181.07,181.07,0,0,1,117,40.11c-9.21-3.53-17.61-.19-20.07,1A191.35,191.35,0,0,0,79.13,54.22,27.16,27.16,0,0,1,93.61,54.46Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M104.47,363.55a27.17,27.17,0,0,1-5-14.12h0a182.4,182.4,0,0,1-21.5-17c-.17,11.65,8.28,18.93,8.36,19l0,0A190.83,190.83,0,0,0,104.47,363.55Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M375,242.89a179.2,179.2,0,0,1-8.54,26c10.64-4,14.49-14.08,14.53-14.19l.37.14a190.12,190.12,0,0,0,5.24-22A27.33,27.33,0,0,1,375,242.89Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M378.05,135.27A27.3,27.3,0,0,1,373,149.59a179.79,179.79,0,0,1,5.64,26.78c7.26-8.76,5.53-19.47,5.51-19.58l.34-.06A188.27,188.27,0,0,0,378.05,135.27Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M364.14,105.49a27.16,27.16,0,0,1-2.58,14.87,178.66,178.66,0,0,1,10.22,25.34c5.65-9.89,2.08-20.16,2-20.26l.28-.1A189.64,189.64,0,0,0,364.14,105.49Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M388.79,189.45h.36a188.13,188.13,0,0,0-2.57-22.32,27.28,27.28,0,0,1-7.5,13.29A180.83,180.83,0,0,1,380.15,200c0,2.61-.07,5.19-.18,7.77C388.64,200.41,388.79,189.56,388.79,189.45Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M324.59,70a181.78,181.78,0,0,1,18.25,20.36c2.05-11.33-4.91-19.86-5-20l.11-.09a191.78,191.78,0,0,0-15.9-15.07A27.3,27.3,0,0,1,324.59,70Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M345.31,78.59a27.16,27.16,0,0,1-.05,15,181.12,181.12,0,0,1,14.46,23.17c3.92-10.76-1.42-20.3-1.47-20.4l.18-.1A190.89,190.89,0,0,0,345.31,78.59Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M379.75,211.84a178.94,178.94,0,0,1-3.85,27.08c9.79-5.75,11.82-16.38,11.83-16.49l.37.06A189,189,0,0,0,389.44,200,27.23,27.23,0,0,1,379.75,211.84Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M134.5,377.78a27.29,27.29,0,0,1-7.54-13.1,179.14,179.14,0,0,1-24.14-13c1.9,11.43,11.43,17.11,11.53,17.16l-.07.12A188.91,188.91,0,0,0,134.5,377.78Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M364.83,272.66a179.92,179.92,0,0,1-13,24.15c11.2-2,16.77-11.36,16.83-11.46l.31.18a188.48,188.48,0,0,0,9-20.69A27.34,27.34,0,0,1,364.83,272.66Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M349.63,300.22a181.12,181.12,0,0,1-17,21.53c11.43,0,18.56-8.28,18.63-8.37l.24.2a188.58,188.58,0,0,0,12.39-18.7A27.27,27.27,0,0,1,349.63,300.22Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M25,156.75l.09.19a179.82,179.82,0,0,1,8.66-26.26c-9.82,3.38-14.06,12-14.92,14A187.38,187.38,0,0,0,13.6,166,27.15,27.15,0,0,1,25,156.75Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M20.75,219.14l.12.13a180.38,180.38,0,0,1-1-19.27c0-2.79.07-5.56.2-8.33-8.26,6.7-9.14,16.56-9.23,18.38a189.53,189.53,0,0,0,2.42,21.76A27.15,27.15,0,0,1,20.75,219.14Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M20.13,187.76l.12.19a179.78,179.78,0,0,1,3.94-27.36c-9.1,5-11.76,14.32-12.24,16.37a188.36,188.36,0,0,0-1.39,21.9A27.07,27.07,0,0,1,20.13,187.76Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M35.12,127l.06.26a179.38,179.38,0,0,1,13.13-24.41c-9.91,1.54-15.56,8.82-17,11a190,190,0,0,0-8.94,20.33A27.23,27.23,0,0,1,35.12,127Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M21.6,263.78a27.16,27.16,0,0,1,5.21-13.84l.1.07a178.06,178.06,0,0,1-5.6-27c-7.63,8.82-5.85,19.85-5.83,20h0A188.7,188.7,0,0,0,21.6,263.78Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M75,329.73h.12A180.57,180.57,0,0,1,56.8,309.19c-2.08,10.79,4.07,19.1,4.85,20.1a191.51,191.51,0,0,0,15.77,15A27.11,27.11,0,0,1,75,329.73Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M35.4,293.72a27.12,27.12,0,0,1,2.73-14.5l.12.06A178.93,178.93,0,0,1,28,253.73c-6,10-2.29,20.52-2.25,20.63h0A188.46,188.46,0,0,0,35.4,293.72Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M329.89,324.71A182.26,182.26,0,0,1,309.48,343c11.26,2,19.72-4.93,19.81-5l.17.21A190.62,190.62,0,0,0,344.81,322,27.24,27.24,0,0,1,329.89,324.71Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M166.48,386.49a27.35,27.35,0,0,1-9.76-11.6,179.66,179.66,0,0,1-26-8.6c3.89,10.83,14.19,14.74,14.29,14.78l-.07.2A186.47,186.47,0,0,0,166.48,386.49Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M49.09,85.59a192.34,192.34,0,0,0-12.54,18.7,27.29,27.29,0,0,1,13.72-4.74l0,.33A181.5,181.5,0,0,1,67.51,78.06C58,77.84,51.29,83.44,49.09,85.59Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M219.22,379.13a179.63,179.63,0,0,1-19.23,1c-2.73,0-5.46-.07-8.17-.19,7.37,8.77,18.31,8.93,18.42,8.93v.27a188.59,188.59,0,0,0,22.21-2.51A27.27,27.27,0,0,1,219.22,379.13Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M250.09,373.06a179,179,0,0,1-26.82,5.6c8.76,7.31,19.52,5.57,19.63,5.55l.06.33a188.68,188.68,0,0,0,21.49-6.38A27.38,27.38,0,0,1,250.09,373.06Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M54.51,306.13A180.73,180.73,0,0,1,40,282.72c-3.89,10.23.68,19.45,1.3,20.6a187.76,187.76,0,0,0,12.9,17.5,27.13,27.13,0,0,1,.18-14.73Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M279.38,361.69A178,178,0,0,1,254,371.89c9.89,5.65,20.16,2.08,20.27,2l.11.3a190.31,190.31,0,0,0,20-10A27.29,27.29,0,0,1,279.38,361.69Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M199.41,389.45a27.4,27.4,0,0,1-11.64-9.71,180.66,180.66,0,0,1-27.11-3.92c5.73,9.95,16.49,12,16.6,12l0,.24A191.3,191.3,0,0,0,199.41,389.45Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M70,75.11l0,.36A183.21,183.21,0,0,1,90.64,56.93c-9.32-1.85-16.8,2.45-19.37,4.21A189,189,0,0,0,55.72,77.36,27.18,27.18,0,0,1,70,75.11Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M306.23,345.4A181,181,0,0,1,283,359.86c10.73,3.86,20.24-1.45,20.33-1.51l.15.25a190.41,190.41,0,0,0,17.9-13.27A27.27,27.27,0,0,1,306.23,345.4Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M313.27,48.4l.09-.1a189.64,189.64,0,0,0-18.28-12.11,27.23,27.23,0,0,1,5.05,14.12,180.59,180.59,0,0,1,21.51,16.93C321.72,55.69,313.36,48.47,313.27,48.4Z"
          className="Badge__SecondFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="INNER_OUTLINE">
        <path
          d="M200,304.1A104.1,104.1,0,1,1,304.1,200,104.22,104.22,0,0,1,200,304.1Zm0-205.32A101.22,101.22,0,1,0,301.22,200,101.34,101.34,0,0,0,200,98.78Z"
          className="Badge__ThirdFillColor"
        />
      </g>

      {/* ------------------------------------------ */}

      <g className="Icon">
        <path
          d="M256.29,163.23l-15.64,15.64a1.24,1.24,0,0,1-1.75,0h0a1.23,1.23,0,0,1,0-1.74l15.64-15.64-2.35-2.36-15.64,15.65a1.24,1.24,0,0,1-1.75,0h0a1.24,1.24,0,0,1,0-1.75l15.64-15.64L248.09,155l-15.64,15.64a1.23,1.23,0,0,1-1.74,0h0a1.24,1.24,0,0,1,0-1.75l15.64-15.64L244,150.94l-15.64,15.64a1.23,1.23,0,0,1-1.75,0h0a1.23,1.23,0,0,1,0-1.74l15.64-15.64-2.3-2.3-19.62,19.62a13.18,13.18,0,0,0,0,18.63h0a13.19,13.19,0,0,0,18.64,0l19.62-19.62Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M154.11,251.38h0a5.9,5.9,0,0,1,.16-8.5l65-61,4.31,4.31-61,65A5.89,5.89,0,0,1,154.11,251.38Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M184.86,203.41l1.73-1.73,3.44,3.68,7.28-6.83-3.78-3.79h0l-40.39-40.38a6.87,6.87,0,0,0-10.2,9.17l31.72,39.33A6.87,6.87,0,0,0,184.86,203.41Z"
          className="Badge__SecondFillColor"
        />
        <path
          d="M207,208.17l-7.15,7.63,33,35.34a6,6,0,1,0,8.54-8.54Z"
          className="Badge__SecondFillColor"
        />
      </g>

      <g className="STARS">
        <polygon
          points="202.9 134.52 200 128.65 197.1 134.52 190.62 135.47 195.31 140.03 194.21 146.49 200 143.44 205.8 146.49 204.69 140.03 209.38 135.47 202.9 134.52"
          className="Badge__ThirdFillColor"
        />
        <polygon
          points="202.9 259.38 200 253.51 197.1 259.38 190.62 260.33 195.31 264.89 194.21 271.35 200 268.31 205.8 271.35 204.69 264.89 209.38 260.33 202.9 259.38"
          className="Badge__ThirdFillColor"
        />
        <polygon
          points="270.13 201.76 267.24 195.88 264.33 201.76 257.85 202.7 262.54 207.26 261.44 213.72 267.24 210.68 273.04 213.72 271.92 207.26 276.61 202.7 270.13 201.76"
          className="Badge__ThirdFillColor"
        />
        <polygon
          points="135.67 201.76 132.77 195.88 129.87 201.76 123.39 202.7 128.08 207.26 126.97 213.72 132.77 210.68 138.57 213.72 137.46 207.26 142.15 202.7 135.67 201.76"
          className="Badge__ThirdFillColor"
        />
      </g>

      <rect
        x="46"
        y="198.5"
        width="50"
        height="3"
        className="Badge__ThirdFillColor"
      />
      <rect
        x="304"
        y="198.5"
        width="50"
        height="3"
        className="Badge__ThirdFillColor"
      />

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x=""
          y=""
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Merriweather"
          fontSize="2em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_5__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".79ex"
          textAnchor="middle"
          fontFamily="Abel"
          fontSize="2.1em"
          letterSpacing="0.1em"
          className="Badge__SecondFillColor"
        >
          <textPath xlinkHref="#RadialBadge_5__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

    </svg>
  );
}

export default Badge;

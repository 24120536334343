import Badge from './Badge';

export default {
  id: 'simple',
  title: 'Simple',
  isDefault: true,
  premium: false,
  animated: true,
  colors: 3,
  BadgeComponentStateless: Badge
};

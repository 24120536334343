import './styles.css';
import React from 'react';


function Badge({ className, line1, line2, line3, line4 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      width="100%"
      height="100%"
      className={`RadialBadge_12 Badge ${className}`}
    >

      {/* ------------------------------------------ */}

      <defs>
        <path
          id="RadialBadge_12__TopCircle"
          d="M 85 200 A 50 50 0 0 1 315 200"
        />

        <path
          id="RadialBadge_12__BottomCircle"
          d="M 85 200 A 50 50 0 0 0 315 200"
        />
      </defs>

      {/* ------------------------------------------ */}

      <g>
        <circle
          cx="200"
          cy="200"
          r="200"
          transform="translate(-82.84 200) rotate(-45)"
          className="Badge__FirstFillColor"
        />
        <g className="DOTS">
          <path
            d="M192.2,16.89h18.15A13.13,13.13,0,0,1,223.45,30h0a13.14,13.14,0,0,1-13.1,13.1H192.2A13.14,13.14,0,0,1,179.1,30h0a13.13,13.13,0,0,1,13.1-13.1Zm18.15,6.18H192.2A6.95,6.95,0,0,0,185.29,30h0a6.93,6.93,0,0,0,6.91,6.91h18.15A6.93,6.93,0,0,0,217.26,30h0a6.95,6.95,0,0,0-6.91-6.92Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M239.86,21.11l17.53,4.7a13.13,13.13,0,0,1,9.26,16h0a13.13,13.13,0,0,1-16,9.26l-17.53-4.7a13.13,13.13,0,0,1-9.26-16h0a13.13,13.13,0,0,1,16-9.26Zm15.93,10.67-17.53-4.7A6.94,6.94,0,0,0,229.79,32h0a7,7,0,0,0,4.89,8.47l17.53,4.7a6.94,6.94,0,0,0,8.47-4.89h0a6.94,6.94,0,0,0-4.89-8.47Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M284.8,37.52l15.72,9.07a13.13,13.13,0,0,1,4.79,17.9h0a13.12,13.12,0,0,1-17.89,4.79l-15.71-9.07a13.12,13.12,0,0,1-4.8-17.89h0a13.12,13.12,0,0,1,17.89-4.8ZM297.43,52l-15.72-9.07a6.94,6.94,0,0,0-9.44,2.53h0a6.94,6.94,0,0,0,2.53,9.44l15.71,9.08A6.95,6.95,0,0,0,300,61.4h0A6.95,6.95,0,0,0,297.43,52Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M324,65,336.8,77.84a13.13,13.13,0,0,1,0,18.52h0a13.12,13.12,0,0,1-18.53,0L305.44,83.53a13.13,13.13,0,0,1,0-18.52h0A13.12,13.12,0,0,1,324,65Zm8.45,17.2L319.59,69.38a6.94,6.94,0,0,0-9.77,0h0a6.93,6.93,0,0,0,0,9.78L322.65,92a6.94,6.94,0,0,0,9.77,0h0a6.93,6.93,0,0,0,0-9.78Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M354.68,101.69l9.07,15.72A13.12,13.12,0,0,1,359,135.3h0a13.12,13.12,0,0,1-17.89-4.79L332,114.79a13.13,13.13,0,0,1,4.8-17.89h0a13.12,13.12,0,0,1,17.89,4.79Zm3.72,18.81-9.07-15.72a7,7,0,0,0-9.45-2.53h0a7,7,0,0,0-2.53,9.45l9.07,15.71a6.95,6.95,0,0,0,9.45,2.53h0a6.94,6.94,0,0,0,2.53-9.44Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M374.86,145.08l4.69,17.52a13.14,13.14,0,0,1-9.26,16.05h0a13.13,13.13,0,0,1-16-9.26l-4.7-17.53a13.14,13.14,0,0,1,9.26-16.05h0a13.13,13.13,0,0,1,16.05,9.27Zm-1.28,19.13-4.7-17.53a6.94,6.94,0,0,0-8.47-4.89h0a6.95,6.95,0,0,0-4.88,8.47l4.69,17.52a6.94,6.94,0,0,0,8.47,4.89h0a6.93,6.93,0,0,0,4.89-8.46Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M383.11,192.2v18.15a13.13,13.13,0,0,1-13.1,13.1h0a13.14,13.14,0,0,1-13.1-13.1V192.2A13.14,13.14,0,0,1,370,179.1h0a13.13,13.13,0,0,1,13.1,13.1Zm-6.18,18.15V192.2a6.95,6.95,0,0,0-6.92-6.91h0a6.93,6.93,0,0,0-6.91,6.91v18.15a6.93,6.93,0,0,0,6.91,6.91h0a6.95,6.95,0,0,0,6.92-6.91Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M378.89,239.86l-4.7,17.53a13.13,13.13,0,0,1-16,9.26h0a13.13,13.13,0,0,1-9.26-16l4.7-17.53a13.13,13.13,0,0,1,16-9.26h0a13.13,13.13,0,0,1,9.26,16Zm-10.67,15.93,4.7-17.53a6.94,6.94,0,0,0-4.89-8.47h0a7,7,0,0,0-8.47,4.89l-4.7,17.53a6.94,6.94,0,0,0,4.89,8.47h0a6.94,6.94,0,0,0,8.47-4.89Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M362.48,284.8l-9.08,15.72a13.12,13.12,0,0,1-17.89,4.79h0a13.12,13.12,0,0,1-4.79-17.89l9.07-15.71a13.12,13.12,0,0,1,17.89-4.8h0a13.12,13.12,0,0,1,4.8,17.89Zm-14.43,12.63,9.07-15.72a6.94,6.94,0,0,0-2.53-9.44h0a6.94,6.94,0,0,0-9.44,2.53l-9.08,15.71A6.95,6.95,0,0,0,338.6,300h0a6.95,6.95,0,0,0,9.45-2.53Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M335,324,322.16,336.8a13.13,13.13,0,0,1-18.52,0h0a13.12,13.12,0,0,1,0-18.53l12.83-12.83a13.13,13.13,0,0,1,18.52,0h0A13.12,13.12,0,0,1,335,324Zm-17.2,8.46,12.83-12.84a6.94,6.94,0,0,0,0-9.77h0a6.93,6.93,0,0,0-9.78,0L308,322.65a7,7,0,0,0,0,9.78h0a7,7,0,0,0,9.78,0Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M298.31,354.68l-15.72,9.07A13.12,13.12,0,0,1,264.7,359h0a13.12,13.12,0,0,1,4.79-17.89L285.21,332a13.13,13.13,0,0,1,17.89,4.8h0a13.12,13.12,0,0,1-4.79,17.89ZM279.5,358.4l15.72-9.07a7,7,0,0,0,2.53-9.45h0a7,7,0,0,0-9.45-2.53l-15.71,9.07a6.95,6.95,0,0,0-2.53,9.45h0a6.94,6.94,0,0,0,9.44,2.53Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M254.92,374.86l-17.52,4.69a13.14,13.14,0,0,1-16.05-9.26h0a13.13,13.13,0,0,1,9.26-16l17.53-4.7a13.14,13.14,0,0,1,16.05,9.26h0a13.13,13.13,0,0,1-9.27,16.05Zm-19.13-1.28,17.53-4.7a6.94,6.94,0,0,0,4.89-8.47h0a6.95,6.95,0,0,0-8.47-4.88l-17.52,4.69a6.94,6.94,0,0,0-4.89,8.47h0a6.93,6.93,0,0,0,8.46,4.89Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M207.8,383.11H189.65a13.13,13.13,0,0,1-13.1-13.1h0a13.14,13.14,0,0,1,13.1-13.1H207.8A13.14,13.14,0,0,1,220.9,370h0a13.13,13.13,0,0,1-13.1,13.1Zm-18.15-6.18H207.8a6.95,6.95,0,0,0,6.91-6.92h0a6.93,6.93,0,0,0-6.91-6.91H189.65a6.93,6.93,0,0,0-6.91,6.91h0a6.95,6.95,0,0,0,6.91,6.92Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M160.14,378.89l-17.53-4.69a13.15,13.15,0,0,1-9.26-16.05h0a13.13,13.13,0,0,1,16-9.26l17.53,4.7a13.13,13.13,0,0,1,9.26,16h0a13.13,13.13,0,0,1-16,9.26Zm-15.93-10.67,17.53,4.7A6.93,6.93,0,0,0,170.2,368h0a7,7,0,0,0-4.89-8.47l-17.53-4.7a6.94,6.94,0,0,0-8.47,4.89h0a6.94,6.94,0,0,0,4.89,8.47Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M115.2,362.48,99.48,353.4a13.12,13.12,0,0,1-4.79-17.89h0a13.12,13.12,0,0,1,17.89-4.79l15.71,9.07a13.14,13.14,0,0,1,4.8,17.89h0a13.12,13.12,0,0,1-17.89,4.8Zm-12.63-14.43,15.72,9.07a6.94,6.94,0,0,0,9.44-2.53h0a6.94,6.94,0,0,0-2.53-9.44l-15.71-9.08A6.95,6.95,0,0,0,100,338.6h0a6.95,6.95,0,0,0,2.53,9.45Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M76,335,63.2,322.16a13.13,13.13,0,0,1,0-18.52h0a13.12,13.12,0,0,1,18.53,0l12.83,12.83a13.13,13.13,0,0,1,0,18.52h0A13.12,13.12,0,0,1,76,335Zm-8.45-17.2,12.83,12.83a6.94,6.94,0,0,0,9.77,0h0a6.93,6.93,0,0,0,0-9.78L77.35,308a6.94,6.94,0,0,0-9.77,0h0a6.93,6.93,0,0,0,0,9.78Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M45.32,298.31l-9.07-15.72A13.12,13.12,0,0,1,41,264.7h0a13.12,13.12,0,0,1,17.89,4.79L68,285.21a13.13,13.13,0,0,1-4.8,17.89h0a13.12,13.12,0,0,1-17.89-4.79ZM41.6,279.5l9.07,15.72a7,7,0,0,0,9.45,2.53h0a7,7,0,0,0,2.53-9.45l-9.07-15.71a6.95,6.95,0,0,0-9.45-2.53h0a6.94,6.94,0,0,0-2.53,9.44Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M25.14,254.92,20.45,237.4a13.14,13.14,0,0,1,9.26-16.05h0a13.13,13.13,0,0,1,16,9.26l4.7,17.53a13.14,13.14,0,0,1-9.26,16.05h0a13.13,13.13,0,0,1-16-9.27Zm1.28-19.13,4.7,17.53a6.94,6.94,0,0,0,8.47,4.89h0a6.95,6.95,0,0,0,4.88-8.47l-4.69-17.52a6.94,6.94,0,0,0-8.47-4.89h0a6.93,6.93,0,0,0-4.89,8.46Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M16.89,207.8V189.65A13.13,13.13,0,0,1,30,176.55h0a13.14,13.14,0,0,1,13.1,13.1V207.8A13.14,13.14,0,0,1,30,220.9h0a13.13,13.13,0,0,1-13.1-13.1Zm6.18-18.15V207.8A6.95,6.95,0,0,0,30,214.71h0a6.93,6.93,0,0,0,6.91-6.91V189.65A6.93,6.93,0,0,0,30,182.74h0a6.95,6.95,0,0,0-6.92,6.91Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M21.11,160.14l4.69-17.53a13.14,13.14,0,0,1,16.05-9.26h0a13.13,13.13,0,0,1,9.26,16l-4.7,17.53a13.13,13.13,0,0,1-16,9.26h0a13.13,13.13,0,0,1-9.26-16Zm10.67-15.93-4.7,17.53A6.94,6.94,0,0,0,32,170.21h0a7,7,0,0,0,8.47-4.89l4.7-17.53a6.94,6.94,0,0,0-4.89-8.47h0a6.94,6.94,0,0,0-8.47,4.89Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M37.52,115.2l9.07-15.72a13.13,13.13,0,0,1,17.9-4.79h0a13.12,13.12,0,0,1,4.79,17.89l-9.07,15.71a13.12,13.12,0,0,1-17.89,4.8h0a13.12,13.12,0,0,1-4.8-17.89ZM52,102.57l-9.07,15.72a6.94,6.94,0,0,0,2.53,9.44h0a6.94,6.94,0,0,0,9.44-2.53l9.08-15.71A6.95,6.95,0,0,0,61.4,100h0A6.94,6.94,0,0,0,52,102.57Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M65,76,77.84,63.2a13.13,13.13,0,0,1,18.52,0h0a13.12,13.12,0,0,1,0,18.53L83.53,94.56a13.13,13.13,0,0,1-18.52,0h0A13.12,13.12,0,0,1,65,76Zm17.2-8.46L69.38,80.41a6.94,6.94,0,0,0,0,9.77h0a6.93,6.93,0,0,0,9.78,0L92,77.35a7,7,0,0,0,0-9.78h0a7,7,0,0,0-9.78,0Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M101.69,45.32l15.72-9.07A13.12,13.12,0,0,1,135.3,41h0a13.12,13.12,0,0,1-4.79,17.89L114.79,68a13.13,13.13,0,0,1-17.89-4.8h0a13.12,13.12,0,0,1,4.79-17.89ZM120.5,41.6l-15.72,9.07a7,7,0,0,0-2.53,9.45h0a7,7,0,0,0,9.45,2.53l15.71-9.07a6.95,6.95,0,0,0,2.53-9.45h0a6.94,6.94,0,0,0-9.44-2.53Z"
            className="Badge__SecondFillColor"
          />
          <path
            d="M145.08,25.14l17.52-4.69a13.14,13.14,0,0,1,16.05,9.26h0a13.13,13.13,0,0,1-9.27,16l-17.52,4.7a13.14,13.14,0,0,1-16.05-9.26h0a13.13,13.13,0,0,1,9.27-16Zm19.13,1.28-17.53,4.7a6.94,6.94,0,0,0-4.89,8.47h0a6.95,6.95,0,0,0,8.47,4.88l17.52-4.69a6.94,6.94,0,0,0,4.89-8.47h0a6.93,6.93,0,0,0-8.46-4.89Z"
            className="Badge__SecondFillColor"
          />
          <g>
            <path
              d="M206.81,36.18a6.19,6.19,0,1,1,1.8-12.24l24,3.52a6.19,6.19,0,1,1-1.79,12.24Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M207.26,33.12a3.09,3.09,0,1,1,.9-6.12l24,3.52a3.09,3.09,0,0,1-.9,6.12Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M124,54.72a6.19,6.19,0,0,1-4.57-11.5l22.57-9a6.18,6.18,0,1,1,4.57,11.49Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M122.85,51.84a3.09,3.09,0,1,1-2.29-5.74l22.57-9a3.09,3.09,0,0,1,2.29,5.75Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M164.18,40a6.19,6.19,0,0,1-1.43-12.29l24.11-2.82a6.19,6.19,0,1,1,1.44,12.29Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M163.82,36.92a3.09,3.09,0,1,1-.72-6.14L187.22,28a3.09,3.09,0,0,1,.72,6.14Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M287.81,61.53a6.19,6.19,0,0,1,7.68-9.7l19,15.06a6.18,6.18,0,1,1-7.67,9.7Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M289.73,59.11a3.09,3.09,0,1,1,3.84-4.85l19.05,15.05a3.1,3.1,0,0,1-3.84,4.86Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M320.66,89a6.19,6.19,0,1,1,9.92-7.39l14.5,19.48a6.19,6.19,0,0,1-9.92,7.39Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M323.14,87.13a3.09,3.09,0,1,1,5-3.69l14.5,19.47a3.09,3.09,0,1,1-5,3.7Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M345.28,124a6.19,6.19,0,0,1,11.5-4.57l9,22.57a6.18,6.18,0,0,1-11.49,4.57Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M348.16,122.85a3.09,3.09,0,1,1,5.74-2.29l9,22.57a3.09,3.09,0,0,1-5.75,2.29Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M43.52,151a6.19,6.19,0,0,1-11.36-4.9l9.62-22.3a6.19,6.19,0,1,1,11.36,4.9Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M40.68,149.79A3.09,3.09,0,1,1,35,147.34l9.62-22.29a3.09,3.09,0,1,1,5.68,2.45Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M61.53,112.19a6.19,6.19,0,1,1-9.7-7.68l15.06-19a6.18,6.18,0,1,1,9.7,7.67Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M59.11,110.27a3.09,3.09,0,1,1-4.85-3.84L69.31,87.38a3.1,3.1,0,0,1,4.86,3.84Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M89,79.34a6.19,6.19,0,1,1-7.39-9.92l19.48-14.5a6.19,6.19,0,0,1,7.39,9.92Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M87.13,76.86a3.09,3.09,0,1,1-3.69-5l19.47-14.5a3.09,3.09,0,1,1,3.7,5Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M356.48,249a6.19,6.19,0,1,1,11.36,4.9l-9.62,22.3a6.19,6.19,0,0,1-11.36-4.9Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M359.32,250.21a3.09,3.09,0,1,1,5.68,2.45L355.38,275a3.09,3.09,0,1,1-5.68-2.45Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M338.47,287.81a6.19,6.19,0,0,1,9.7,7.68l-15.06,19a6.18,6.18,0,1,1-9.7-7.67Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M340.89,289.73a3.09,3.09,0,1,1,4.85,3.84l-15.06,19.05a3.09,3.09,0,0,1-4.85-3.84Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M311,320.66a6.19,6.19,0,1,1,7.39,9.92l-19.48,14.5a6.19,6.19,0,0,1-7.39-9.92Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M312.87,323.14a3.09,3.09,0,1,1,3.69,5l-19.48,14.5a3.09,3.09,0,0,1-3.69-5Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M276,345.28a6.19,6.19,0,0,1,4.57,11.5l-22.57,9a6.18,6.18,0,0,1-4.57-11.49Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M277.15,348.16a3.09,3.09,0,1,1,2.28,5.74l-22.56,9a3.09,3.09,0,1,1-2.29-5.75Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M235.82,360a6.19,6.19,0,0,1,1.43,12.29l-24.11,2.82a6.19,6.19,0,1,1-1.44-12.29Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M236.18,363.08a3.09,3.09,0,0,1,.72,6.14L212.78,372a3.09,3.09,0,1,1-.72-6.14Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M193.18,363.82a6.19,6.19,0,1,1-1.79,12.24l-24-3.52a6.19,6.19,0,1,1,1.79-12.24Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M192.74,366.88a3.09,3.09,0,1,1-.9,6.12l-24-3.52a3.09,3.09,0,0,1,.9-6.12Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M151,356.48a6.19,6.19,0,0,1-4.9,11.36l-22.3-9.62a6.19,6.19,0,1,1,4.9-11.36Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M149.79,359.32a3.09,3.09,0,1,1-2.45,5.68l-22.29-9.62a3.09,3.09,0,1,1,2.45-5.68Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M112.19,338.47a6.19,6.19,0,1,1-7.68,9.7l-19-15.06a6.18,6.18,0,1,1,7.67-9.7Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M110.27,340.89a3.09,3.09,0,1,1-3.84,4.85L87.38,330.69a3.1,3.1,0,0,1,3.84-4.86Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M79.34,311a6.19,6.19,0,1,1-9.92,7.39l-14.5-19.48a6.19,6.19,0,0,1,9.92-7.39Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M76.86,312.87a3.09,3.09,0,1,1-5,3.69L57.4,297.09a3.09,3.09,0,0,1,5-3.7Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M54.72,276a6.19,6.19,0,0,1-11.5,4.57l-9-22.57a6.18,6.18,0,0,1,11.49-4.57Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M51.84,277.15a3.09,3.09,0,1,1-5.74,2.29l-9-22.57a3.09,3.09,0,0,1,5.75-2.29Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M40,235.82a6.19,6.19,0,1,1-12.29,1.43l-2.82-24.11a6.19,6.19,0,1,1,12.29-1.44Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M36.92,236.18a3.09,3.09,0,0,1-6.14.72L28,212.78a3.09,3.09,0,0,1,6.14-.72Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M36.18,193.19a6.19,6.19,0,0,1-12.24-1.8l3.52-24a6.19,6.19,0,1,1,12.24,1.79Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M33.12,192.74a3.09,3.09,0,0,1-6.12-.9l3.52-24a3.09,3.09,0,0,1,6.12.9Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M360,164.18a6.19,6.19,0,1,1,12.29-1.43l2.82,24.11a6.19,6.19,0,1,1-12.29,1.44Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M363.08,163.82a3.09,3.09,0,1,1,6.14-.72L372,187.22a3.09,3.09,0,0,1-6.14.72Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M363.82,206.81a6.19,6.19,0,0,1,12.24,1.8l-3.52,24a6.19,6.19,0,1,1-12.24-1.79Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M366.88,207.26a3.09,3.09,0,0,1,6.12.9l-3.52,24a3.09,3.09,0,0,1-6.12-.9Z"
              className="Badge__SecondFillColor"
            />
          </g>
          <g>
            <path
              d="M249,43.52a6.19,6.19,0,1,1,4.9-11.36l22.3,9.62a6.19,6.19,0,0,1-4.9,11.36Z"
              className="Badge__FirstFillColor"
            />
            <path
              d="M250.21,40.68A3.09,3.09,0,1,1,252.66,35L275,44.62a3.09,3.09,0,1,1-2.45,5.68Z"
              className="Badge__SecondFillColor"
            />
          </g>
        </g>
        <g>
          <path
            d="M243.46,164.35s-5.85-1.12-6.78-9.21l9.35-6.71s-6.61-2.15-12.56,1.61c-4.84,3.06-4.87,9.83-4.87,9.83s-1,5.59-7.25,11.66-46.44,47.15-49.89,50.36-8.16,8.73-16.77,12l-.48.1A9.3,9.3,0,1,0,166,243.7a34.49,34.49,0,0,1,8.41-12.63c6.86-6.77,54.5-55.31,56-56.62s5.62-2.82,8.28-3a15.85,15.85,0,0,0,10-3.75c3.05-2.74,3.76-5.79,3.82-9.52Zm-85,84.55a6.25,6.25,0,1,1,4.31-7.71A6.24,6.24,0,0,1,158.44,248.9Z"
            className="Badge__ThirdFillColor"
          />
          <path
            d="M178.65,171.53c-6.29-6.07-7.25-11.66-7.25-11.66s0-6.77-4.87-9.83c-5.95-3.76-12.56-1.61-12.56-1.61l9.35,6.71c-.93,8.09-6.78,9.21-6.78,9.21l-9.09-6.16c.06,3.73.77,6.78,3.82,9.52a15.85,15.85,0,0,0,10,3.75c2.66.17,6.77,1.68,8.28,3,.8.69,14.37,14.46,28,28.34,2-2,4-4.06,6-6.07C192.69,185.63,181.7,174.48,178.65,171.53Zm67.14,62.41-.48-.1c-8.61-3.22-13.36-8.77-16.77-12-1.47-1.37-9.61-9.62-18.95-19.1l-6,6.06c10.13,10.28,19.27,19.54,22,22.22A34.49,34.49,0,0,1,234,243.7a9.29,9.29,0,1,0,11.78-9.76Zm3.48,10.65a6.25,6.25,0,1,1-4.31-7.72A6.24,6.24,0,0,1,249.27,244.59Z"
            className="Badge__ThirdFillColor"
          />
        </g>
        <g>
          <polygon
            points="140 185.19 143.37 194.72 153.46 194.98 145.45 201.12 148.32 210.81 140 205.08 131.68 210.81 134.55 201.12 126.54 194.98 136.63 194.72 140 185.19"
            className="Badge__SecondFillColor"
            fillRule="evenodd"
          />
          <polygon
            points="260 185.19 263.37 194.72 273.46 194.98 265.45 201.12 268.32 210.81 260 205.08 251.68 210.81 254.55 201.12 246.54 194.98 256.63 194.72 260 185.19"
            className="Badge__SecondFillColor"
            fillRule="evenodd"
          />
          <polygon
            points="200 125.19 203.37 134.72 213.46 134.98 205.45 141.12 208.32 150.81 200 145.08 191.68 150.81 194.55 141.12 186.54 134.98 196.63 134.72 200 125.19"
            className="Badge__SecondFillColor"
            fillRule="evenodd"
          />
          <polygon
            points="200 235.19 203.37 244.72 213.46 244.98 205.45 251.12 208.32 260.81 200 255.08 191.68 260.81 194.55 251.12 186.54 244.98 196.63 244.72 200 235.19"
            className="Badge__SecondFillColor"
            fillRule="evenodd"
          />
        </g>
      </g>

      {/* ------------------------------------------ */}

      <g className="TEXT">

        <text
          x=""
          y=""
          dy=".7ex"
          textAnchor="middle"
          fontFamily="Bitter"
          fontSize="1.8em"
          fontWeight="700"
          letterSpacing="0.09em"
          className="Badge__ThirdFillColor"
        >
          <textPath xlinkHref="#RadialBadge_12__TopCircle" startOffset="50%">
            {line1.toUpperCase()} {line2.toUpperCase()}
          </textPath>
        </text>

        <text
          x=""
          y=""
          dy=".79ex"
          textAnchor="middle"
          fontFamily="Anton"
          fontSize="1.6em"
          letterSpacing="0.1em"
          className="Badge__ThirdFillColor"
        >
          <textPath xlinkHref="#RadialBadge_12__BottomCircle" startOffset="50%">
            {line3.toUpperCase()} {line4.toUpperCase()}
          </textPath>
        </text>

      </g>

    </svg>
  );
}

export default Badge;
